<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center header-msg">
        <h1>
          <img src="../assets/logo.png" alt="" v-if="isUp()" />
          <strong class="strokeme" v-if="isUp()">{{ appName }}</strong>
          <strong class="strokeme2" v-if="!isUp()"
            >{{ appName }} is not availble right now.</strong
          >
        </h1>
        <span v-if="!isUp()">We're moving to the new version. Please be patient until we're sorting out everything.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      status:
        this?.$store?.getters?.appStatus ?? process?.env?.VUE_APP_STATUS ?? 'up'
    }
  },
  methods: {
    isUp() {
      return this.status === 'up'
    }
  },
  computed: {
    appName() {
      return this?.$store?.getters?.appName ?? 'default'
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? '0.0.0'
    },
    appEnv() {
      return (
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
      )
    }
  }
}
</script>

<style>
@font-face {
  font-family: Calibri;
  src: url('../assets/calibri-regular.ttf');
}
</style>

<style scoped>
.container-lg {
  background-color: rgba(250, 189, 189, 0.35) !important;
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
  min-height: 40vw !important;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  color: #f3f3f3;
  top: 12vw !important;
}
.header-msg img {
  height: 100px !important;
  margin: 0 -10px 18px 0;
}
.header-msg h1 {
  text-transform: capitalize;
  font-size: 100px !important;
  line-height: 4.5rem !important;
  letter-spacing: -0.1rem !important;
  margin: 0;
}
.header-msg h1 .strokeme2 {
  text-transform: uppercase;
  font-size: 50px !important;
  line-height: 4.5rem !important;
  letter-spacing: 0 !important;
  margin: 0;
}
.strokeme {
  /* color: #62a7d4; */
  color: #aae0fa;
  text-shadow: -1px -1px 0 #4dcbf4, 1px -1px 0 #4dcbf4, -1px 1px 0 #4dcbf4,
    1px 1px 0 #4dcbf4;
}
.strokeme2 {
  color: #ff0055;
  text-shadow: -1px -1px 0 #f4584d, 1px -1px 0 #f4584d, -1px 1px 0 #f4584d,
    1px 1px 0 #f4584d;
}
</style>
