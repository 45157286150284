<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Receipt</label></div>
    <div class="col-sm-7">
      <ul v-if="uploading" class="upload-list">
        <li style="color: #666">Please wait...</li>
      </ul>
      <b-form-file
        v-if="!uploadedFile && !uploading"
        drop-placeholder="Drop file here..."
        ref="file"
        class="txt"
        @change="uploadBill"
        :disabled="uploading"
      ></b-form-file>
      <ul v-if="uploadedFile && !uploading" class="upload-list">
        <li :title="fileName">
          <b-link
            ><b-icon
              @click="removeUploadedFile()"
              icon="x-square-fill"
              variant="danger"
              title="Remove"
            ></b-icon></b-link
          >&nbsp;{{ getUploadedFileName() }}
        </li>
      </ul>
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'
export default {
  name: 'AttachmentSelector',
  props: ['formData'],
  data() {
    return {
      uploading: false,
      fileName: null,
      uploadedFile: this.formData.file
    }
  },
  watch: {
    uploadedFile(val) {
      this.formData.file = val
    }
  },
  methods: {
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await Services.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        this.fileName = ev.target.files[0].name
        this.uploadedFile = data.fileName
      }
      this.uploading = false
    },
    async removeUploadedFile() {
      this.uploading = true
      const { status } = await Services.RemoveExpenseBill(this.uploadedFile)
      if (status === 200) {
        this.uploadedFile = null
        this.fileName = null
      }
      this.uploading = false
    },
    getUploadedFileName() {
      if (!this.uploadedFile) {
        return
      }
      let result = this.fileName
      if (result.length > 30) {
        let startStr = result.substr(0, 15)
        let endStr = result.substr(result.length - 10)
        result = `${startStr} ... ${endStr}`
      }
      return result
    }
  }
}
</script>

<style>
.form-file-text {
  line-height: 20px !important;
}
</style>

<style scoped>
ul.upload-list {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 40px;
}
ul.upload-list li {
  font-size: 10pt;
  font-weight: 600;
}
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.file-txt {
  color: #666;
  font-weight: bold !important;
  font-size: 8pt !important;
}
</style>
