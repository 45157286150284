<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-7 text-left"></div>
      <div class="col-sm-3 text-right">
        <b-form-select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-if="projectType === 'prj'"
          v-model="project"
          :options="projects"
          :disabled="!projects.length"
        ></b-form-select>
      </div>
      <div class="col-sm-2 text-left">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="selectProject"
          :disabled="!projects.length"
          >Get Reports</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!selectedProject">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any reports to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <!-- <div class="row" v-if="listItems.length">Stats</div> -->
    <div class="row" v-if="selectedProject">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="selectedProject">
      <div class="col-sm-12 lizard-detail-tbl">
        <div class="row">
          <div class="col-sm-12 lizard-detail-tbl-footer">
            <div class="row">
              <div class="col-sm-12 lizard-detail-tbl-footer-info">
                People cost: £{{
                  parseFloat(selectedProject.teamCosts).toFixed(2) || '0.00'
                }}<br />
                Total fees:
                <span v-if="selectedProject.totalFees"
                  >£{{
                    parseFloat(selectedProject.totalFees).toFixed(2) || '0.00'
                  }}</span
                ><span v-else>£0.00</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 lizard-detail-tbl-footer-info-2">
                Total Cost: £{{
                  parseFloat(selectedProject.totalCosts).toFixed(2) || '0.00'
                }}
              </div>
              <div class="col-sm-6 lizard-detail-tbl-footer-info-2">
                Budget: £{{
                  parseFloat(selectedProject.budget).toFixed(2) || '0.00'
                }}
              </div>
            </div>
            <div class="row">
              <div
                class="col-sm-12 lizard-detail-tbl-footer-info-3 lizard-text-on-track"
                v-if="isOnTrack && selectedProject.budgetPercent"
              >
                ✅ ON TRACK: To date, {{ selectedProject.budgetPercent }}% of
                total budget spent. Project started
                {{ selectedProject.durationStarted }} ago,
                <span v-if="selectedProject.perRemainsDeadline"
                  >{{ selectedProject.deadlineRemains }} days ({{
                    selectedProject.perRemainsDeadline
                  }}%) remaining until the end of the project.</span
                >
                <span v-if="!selectedProject.perRemainsDeadline"
                  >project deadline has been already passed.</span
                >
              </div>
              <div
                class="col-sm-12 lizard-detail-tbl-footer-info-3 lizard-text-over-spent"
                v-if="!isOnTrack && selectedProject.budgetPercent"
              >
                🚨 OVERSPENT: To date, {{ selectedProject.budgetPercent }}% of
                total budget spent. Project started
                {{ selectedProject.durationStarted }} ago,
                <span v-if="selectedProject.perRemainsDeadline"
                  >{{ selectedProject.deadlineRemains }} days ({{
                    selectedProject.perRemainsDeadline
                  }}%) remaining until the end of the project.</span
                >
                <span v-if="!selectedProject.perRemainsDeadline"
                  >project deadline has been already passed.</span
                >
              </div>
              <div
                class="col-sm-12 lizard-detail-tbl-footer-info-3 lizard-text-over-spent"
                v-if="!selectedProject.budgetPercent"
              >
                🚨 Incomplete Data. Please make sure that the budget and total
                costs values for the project are set to be able to generate the
                report here.<br />Project started
                {{ selectedProject.durationStarted }} ago,
                <span v-if="selectedProject.perRemainsDeadline"
                  >{{ selectedProject.deadlineRemains }} days ({{
                    selectedProject.perRemainsDeadline
                  }}%) remaining until the end of the project.</span
                >
                <span v-if="!selectedProject.perRemainsDeadline"
                  >project deadline has been already passed.</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 lizard-detail-tbl-footer-info-4">
                {{ selectedProject.createdDate }}
              </div>
              <div class="col-sm-6 lizard-detail-tbl-footer-info-5">
                {{ parseDate(selectedProject.endDate) }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 lizard-detail-tbl-chart">
                <div id="chart"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 lizard-detail-tbl-header">
            {{ selectedProjectName || 'N/A' }} ({{
              selectedProject.projectCode
            }})
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 lizard-detail-tbl-info">
            Client Name: {{ selectedProject.clientName || 'N/A' }}<br />
            Partner: {{ selectedProject.partner || 'N/A' }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <b-table
              responsive
              striped
              bordered
              outlined
              fixed
              small
              hover
              caption-top
              class="lizard-activity-list"
              variant="secondary"
              v-if="selectedProject.team.length"
              :items="selectedProject.team"
              :fields="listFields"
            >
              <template #table-caption>
                <div class="txt">Team Members</div>
              </template>
              <template #cell(index)="data">
                <span v-if="data.item.name !== 'Total'">
                  {{ data.index + 1 }}
                </span>
              </template>
              <template #cell(dayRate1)="data">
                £<input
                  type="number"
                  name="surveyFees"
                  class="lizard-hourly-rate-input"
                  :id="data.item.id"
                  :value="data.item.surveyFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(dayRate2)="data">
                £<input
                  type="number"
                  name="teamDinnerFees"
                  class="lizard-hourly-rate-input"
                  :id="data.item.id"
                  :value="data.item.teamDinnerFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(dayRate3)="data">
                £<input
                  type="number"
                  name="vouchersFees"
                  class="lizard-hourly-rate-input"
                  :id="data.item.id"
                  :value="data.item.vouchersFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(dayRate4)="data">
                £<input
                  type="number"
                  name="otherFees"
                  class="lizard-hourly-rate-input"
                  :id="data.item.id"
                  :value="data.item.otherFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(hourlyRate)="data">
                £{{ parseFloat(data.item.hourlyRate).toFixed(2) || '0.00' }}
              </template>
              <template #cell(dayRate5)="data">
                £{{ parseFloat(data.item.dayRate).toFixed(2) || '0.00' }}
              </template>
              <template #cell(cost)="data">
                £{{ parseFloat(data.item.cost).toFixed(2) || '0.00' }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="selectedProject">
      <div class="col-sm-12 lizard-detail-tbl">
        <div class="row">
          <div class="col-sm-12">
            <b-table
              responsive
              striped
              bordered
              outlined
              fixed
              small
              hover
              caption-top
              class="lizard-activity-list"
              variant="secondary"
              :items="randomdata"
              :fields="otherExpensesFields"
            >
              <template #table-caption>
                <div class="txt">Expense Categories Costs</div>
              </template>
              <template #cell(surveys)="data">
                £{{ parseFloat(data.item.surveys).toFixed(2) || '0.00' }}
              </template>
              <template #cell(voucheres)="data">
                £{{ parseFloat(data.item.voucheres).toFixed(2) || '0.00' }}
              </template>
              <template #cell(expertcalls)="data">
                £{{ parseFloat(data.item.expertcalls).toFixed(2) || '0.00' }}
              </template>
              <template #dbreports(surveys)="data">
                £{{ parseFloat(data.item.dbreports).toFixed(2) || '0.00' }}
              </template>
              <template #cell(miscellaneous)="data">
                £{{ parseFloat(data.item.miscellaneous).toFixed(2) || '0.00' }}
              </template>
              <template #cell(total)="data">
                £{{ parseFloat(data.item.total).toFixed(2) || '0.00' }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'
import ApexCharts from 'apexcharts'

export default {
  name: 'ManagersProjects',
  components: {},
  data() {
    return {
      isOnTrack: true,
      projects: [],
      bdprojects: [],
      pickerOptions: {
        firstDayOfWeek: 1
      },
      cardIsLoading: true,
      project: 'def',
      projectType: 'prj',
      formData: {
        project: 'def',
        dates: null
      },
      selectedProject: null,
      selectedProjectName: 'N/A',
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'billableDays',
          label: 'Billable Days',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'ratePerDay',
          label: 'Rate/Day',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'totalHours',
          label: 'Total Hours',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'billableHours',
          label: 'Billable Hours',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'ratePerHour',
          label: 'Rate/Hour',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'peopleCosts',
          label: 'People Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'transporationCosts',
          label: 'Transporation Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'mealCosts',
          label: 'Meal Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          //@TODO add it to project costs
          key: 'telecomCosts',
          label: 'Telecom Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          //@TODO add it to project costs
          key: 'miscellaneousCosts',
          label: 'Miscellaneous Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'totalCosts',
          label: 'Total Costs',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        }
        // {
        //   key: 'billableDays',
        //   label: 'Billable Days',
        //   sortable: true,
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'actualHoursSpent',
        //   label: 'Hours',
        //   sortable: true,
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'billableHoursSpent',
        //   label: 'Billable Hours',
        //   sortable: true,
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // // {
        // //   key: 'hourlyRate',
        // //   label: 'Expert Calls Fees',
        // //   thClass: 'lizard-activity-list-duration',
        // //   tdClass: 'lizard-activity-list-duration'
        // // },
        // {
        //   key: 'dayRate1',
        //   label: 'Survey Fees',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'dayRate2',
        //   label: 'Team Dinner Fees',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'dayRate3',
        //   label: 'Vouchers Fees',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'dayRate4',
        //   label: 'Other Fees',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'dayRate5',
        //   label: 'Cost per day',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // },
        // {
        //   key: 'cost',
        //   label: 'Total cost',
        //   thClass: 'lizard-activity-list-duration',
        //   tdClass: 'lizard-activity-list-duration'
        // }
      ],
      otherExpensesFields: [
        {
          key: 'surveys',
          label: 'Survey Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'voucheres',
          label: 'Vouchers Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'expertcalls',
          label: 'Expert calls Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'dbreports',
          label: 'Database Reports Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'miscellaneous',
          label: 'Miscellaneous Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'total',
          label: 'Total Expences',
          sortable: false,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        }
      ],
      randomdata: [{
        surveys: 5,
        voucheres: 10,
        expertcalls: 8,
        dbreports: 15,
        miscellaneous: 2,
        total: 40
      }],
      stats: {
        manDays: { text: 'Billable Days', value: 0 },
        hours: { text: 'Total Hours', value: 0 },
        days: { text: 'Calendar Days', value: 0 },
        adays: { text: 'Actual Days', value: 0 }
      },
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Project: 'projectCode',
        Date: 'date',
        Hours: 'hours',
        User: 'user',
        Notes: 'notes'
      },
      exportData: []
    }
  },
  async mounted() {
    const Projects = await Services.GetProjects()
    this.projects = Projects.data.map((itm) => {
      return {
        value: `${itm.code}$${itm.name}`,
        text: `${itm.code}: ${itm.name}`
      }
    })
    this.projects.unshift({
      value: 'def',
      text: 'Select a Project...'
    })
    const BDProjects = await Services.GetBdProjects()
    this.bdprojects = BDProjects.data
  },
  methods: {
    async selectProject() {
      if (this.project === 'def') {
        return
      }
      const project = this.project.split('$')
      const projectCode = project[0]
      this.selectedProjectName = project[1]
      this.cardIsLoading = true
      const ProjectInfo = await Services.GetProjectsInfo(projectCode)
      this.selectedProject = ProjectInfo.data
      this.selectedProject.team = this.selectedProject.team.map((member) => {
        member.hourlyRate = member.hourlyRate || 0
        member.dayRate = member.dayRate || member.hourlyRate * 8 || 0
        member.cost = member.cost || member.hourlyRate * member.hoursSpent || 0
        member.billableDays = member.billableHoursSpent / 8
        return member
      })
      if (this.selectedProject.endDate) {
        this.selectedProject.deadlineRemains = this.$moment(
          this.selectedProject.endDate
        ).diff(this.$moment(), 'days')
        this.selectedProject.deadlineRemains =
          this.selectedProject.deadlineRemains < 1
            ? 0
            : this.selectedProject.deadlineRemains
        const projectDuration = this.$moment(this.selectedProject.endDate).diff(
          this.selectedProject.createdDate,
          'days'
        )
        this.selectedProject.perRemainsDeadline = Math.ceil(
          (this.selectedProject.deadlineRemains * 100) / projectDuration
        )
        this.selectedProject.perStarted = Math.ceil(
          ((projectDuration - this.selectedProject.deadlineRemains) * 100) /
            projectDuration
        )
        // if (!this.selectedProject.deadlineRemains) {
        //   this.selectedProject.deadlineRemains =
        //     'this project deadline has been already passed.'
        // } else {
        //   this.selectedProject.deadlineRemains += ` days remained until the project end date ${this.parseDate(
        //     this.selectedProject.endDate
        //   )}`
        // }
      }
      this.selectedProject.durationStarted = this.$moment(
        this.selectedProject.createdDate
      ).fromNow(true)
      this.selectedProject.createdDate = this.parseDate(
        this.selectedProject.createdDate
      )
      this.selectedProject.budget = this.selectedProject.budget || 0
      this.selectedProject.totalCosts =
        (this.selectedProject.teamCosts || 0) +
        (this.selectedProject.totalFees || 0)
      this.selectedProject.budgetPercent = Math.ceil(
        (this.selectedProject.totalCosts * 100) / this.selectedProject.budget
      )
      setTimeout(() => {
        this.cardIsLoading = false
        this.renderChart(this.selectedProject)
        this.selectedProject.team.push({
          name: 'Total',
          actualHoursSpent: '',
          billableHoursSpent: '',
          hourlyRate: '',
          dayRate: '',
          cost: '',
          billableDays: ''
        })
      }, 500)
      console.log(Object.keys(this.selectedProject.team[0]))
    },
    parseDate(dt) {
      if (!dt) {
        return this.$moment().format('MMMM, Do YYYY')
      }
      return this.$moment(dt).format('MMMM, Do YYYY')
    },
    resetProjectsSelector() {
      this.formData.project = 'def'
      this.formData.dates = null
      this.listItems = []
    },
    async getReports() {
      const project = this.formData.project
      let dates = this.formData.dates
      if (!project) {
        return
      }
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      const Result = await Services.GetClientProjectsReports({
        code: project,
        from: this.$moment(dates[0]).format('YYYY-MM-DD'),
        to: this.$moment(dates[1]).format('YYYY-MM-DD')
      })
      if (Result.status !== 200) {
        return
      }
      this.listItems = Result.data.map((itm) => {
        itm.date = this.$moment(itm.date).format('YYYY-MM-DD')
        return itm
      })
    },
    CalcManDays() {
      this.ResetStats()
      const list = this.listItems
      const days = {}
      list.forEach((itm) => {
        days[itm.date] = days[itm.date] || 0
        days[itm.date] += itm.hours
      })
      this.stats.days.value += Object.keys(days).length
      const hours = Object.values(days)
      for (let i = 0; i < hours.length; i++) {
        if (hours[i] >= 8) {
          this.stats.manDays.value++
        } else {
          this.stats.manDays.value += hours[i] / 8
        }
        this.stats.hours.value += hours[i]
      }
      this.stats.adays.value = parseFloat(this.stats.hours.value / 8).toFixed(1)
      this.stats.manDays.value = parseFloat(this.stats.manDays.value).toFixed(2)
      this.stats = Object.values(this.stats)
    },
    ResetStats() {
      for (let key in this.stats) {
        this.stats[key].value = 0
      }
    },
    generateExcel() {
      const project = this.formData.project
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`
      this.exportName = `${project}-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        itm.user = `${itm.user.name}`
        return itm
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    },
    renderChart({ budgetPercent, deadlineRemains, perStarted }) {
      document.querySelector('#chart').innerHTML = ''
      this.isOnTrack = budgetPercent <= perStarted
      const color = !this.isOnTrack ? '#660000' : '#006600'
      const options = {
        chart: {
          type: 'rangeBar',
          height: 120,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
            rangeBarGroupRows: true
          }
        },
        colors: [color],
        fill: {
          type: 'solid'
        },
        xaxis: {
          type: 'numeric',
          max: 100,
          title: {
            text: 'Budget spent (%)'
          }
        },
        yaxis: {
          show: false
        },
        legend: {
          position: 'right'
        },
        tooltip: {
          custom: function({ seriesIndex }) {
            if (seriesIndex) {
              return deadlineRemains
            } else {
              return `AS OF THIS DATE, WE HAVE SPENT ${budgetPercent}% OF THIS BUDGET.`
            }
          }
        },
        series: [
          {
            name: 'Budget used',
            data: [
              {
                x: 'percent',
                y: [0, budgetPercent]
              }
            ]
          }
        ]
      }
      const chart = new ApexCharts(document.querySelector('#chart'), options)
      chart.render()
    }
  }
}
</script>

<style>
.lizard-activity-list th {
  font-size: 8pt !important;
  color: #666;
  font-weight: bold !important;
}
</style>

<style scoped>
.lizard-detail-tbl {
  background-color: #eee;
  border-radius: 5px;
  padding: 20px 30px;
}

.lizard-detail-tbl-header {
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 60px !important;
  background-color: #999;
  font-size: 16pt;
  border-radius: 3px 3px 0 0;
  color: #fff;
  font-weight: bold;
  text-shadow: #666 1px 1px;
}

.lizard-detail-tbl-info {
  margin: 0;
  padding: 0 30px;
  text-align: left;
  line-height: 30px !important;
  background-color: #ccc;
  font-size: 11pt;
  border-radius: 0 0 3px 3px;
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
}

.lizard-detail-tbl-footer {
  margin: 0;
  padding: 0 30px;
  line-height: 30px !important;
  font-weight: 600;
}

.lizard-detail-tbl-footer-info {
  text-align: left;
  background-color: #ccc;
  font-size: 10pt;
  color: #333;
}

.lizard-detail-tbl-footer-info-2 {
  text-align: center;
  background-color: #666;
  font-size: 10pt;
  color: #fff;
}

.lizard-detail-tbl-footer-info-3 {
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  font-size: 10pt;
  color: #fff;
}

.lizard-detail-tbl-footer-info-4 {
  padding: 0 30px;
  text-align: left;
  background-color: #fff;
  font-size: 10pt;
  color: #999;
}

.lizard-detail-tbl-footer-info-5 {
  padding: 0 30px;
  text-align: right;
  background-color: #fff;
  font-size: 10pt;
  color: #999;
}

.lizard-detail-tbl-chart {
  margin: 0;
  padding: 0;
  text-align: left;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

#chart {
  margin: 0 !important;
  padding: 0 !important;
}

.lizard-text-on-track {
  color: #009900;
}

.lizard-text-over-spent {
  color: #cc0000;
}

.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
</style>
