<template>
  <div class="row">
    <div class="col-lg">
      <div class="row" v-if="showHelpRow && TimeLogHistory">
        <div class="col-sm-3"></div>
        <div class="col-sm-7 text-center lizard-help-list">
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3 lizard-help-list no-activity-calday">
              no activity
            </div>
            <div class="col-sm-3 lizard-help-list half-activity-calday">
              half activity
            </div>
            <div class="col-sm-3 lizard-help-list full-activity-calday">
              full activity
            </div>
            <div class="col-sm-2"></div>
          </div>
        </div>
        <div class="col-sm-2"></div>
      </div>
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-2"><label for="" class="h6">Date</label></div>
        <div class="col-sm-7">
          <el-date-picker
            v-model="formData.dates"
            type="date"
            placeholder="Pick a day"
            class="lizard-form-date"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptionsWithHistory"
            v-if="!dateRangeSelected && TimeLogHistory"
          ></el-date-picker>
          <el-date-picker
            v-model="formData.dates"
            type="date"
            placeholder="Pick a day"
            class="lizard-form-date"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            v-if="!dateRangeSelected && !TimeLogHistory"
          ></el-date-picker>
          <el-date-picker
            v-model="formData.dates"
            type="daterange"
            range-separator=""
            start-placeholder="From"
            end-placeholder="To"
            class="form-control lizard-form-datepicker"
            size="small"
            value-format="yyyy-MM-dd"
            :picker-options="holidayPickerOptions"
            @change="holidayPickerDateChanged"
            v-if="dateRangeSelected"
          >
          </el-date-picker>
          <b-link
            v-if="!dateRangeSelected && TimeLogHistory"
            class="lizard-help-icon"
            @click="showHelp()"
            ><b-icon
              icon="info-square-fill"
              variant="secondary"
              title="Help"
            ></b-icon
          ></b-link>
        </div>
        <div class="col-sm-2"></div>
      </div>
      <div class="row" v-if="showHolidayOpts">
        <div class="col-lg"><HolidaySelector :formData="formData" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Services from "../../services/index.vue";
import HolidaySelector from "./HolidaySelector.vue";

let records = {};
const GetRecords = async function() {
  if (!Object.keys(records).length) {
    const { status, data } = await Services.GetTimeLogs();
    if (status === 200) {
      data.forEach((itm) => {
        let dt = itm.date.split("T")[0];
        records[dt] = records[dt] || 0;
        records[dt] += itm.hours;
      });
    }
  }
  return records;
};

export default {
  name: "DateSelector",
  props: {
    formData: Object,
    dateRangeSelected: Boolean,
    TimeLogHistory: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
  },
  components: { HolidaySelector },
  data() {
    return {
      showHelpRow: false,
      showHolidayOpts: false,
      showTimeLogHistory: true,
      holidayPickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(dt) {
          return [0, 6].indexOf(Vue.moment(dt).day()) > -1;
        },
      },
      pickerOptionsWithHistory: {
        firstDayOfWeek: 1,
        cellClassName(dt) {
          let customClass = "no-activity-day";
          const isHoliday = [0, 6].indexOf(Vue.moment(dt).day()) > -1;
          const afterToday = Vue.moment(dt).diff(Vue.moment()) >= 0;
          const formatedDate = String(Vue.moment(dt).format("YYYY-MM-DD"));
          if (Object.keys(records).includes(formatedDate)) {
            let hours = parseFloat(records[formatedDate]);
            customClass =
              hours >= 8 ? "full-activity-day" : "half-activity-day";
          }
          return isHoliday
            ? "holiday-activity-day"
            : afterToday
            ? ""
            : customClass;
        },
        disabledDate(dt) {
          // const isHoliday = [0, 6].indexOf(Vue.moment(dt).day()) > -1;
          return Vue.moment(dt).diff(Vue.moment()) > 0;
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        cellClassName() {
          return "";
        },
        disabledDate(dt) {
          return Vue.moment(dt).diff(Vue.moment()) > 0;
        },
      },
    };
  },
  async mounted() {
    await GetRecords();
  },
  methods: {
    showHelp() {
      this.showHelpRow = !this.showHelpRow;
    },
    holidayPickerDateChanged(dt) {
      if (dt && dt.length === 2 && dt[0] === dt[1]) {
        this.showHolidayOpts = true;
      } else {
        this.showHolidayOpts = false;
        this.formData.holiday = "full";
      }
    },
  },
};
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
