export default function(Vuex) {
  return new Vuex.Store({
    state: {
      packageName: process.env.PACKAGE_NAME,
      packageVersion: process.env.PACKAGE_VERSION,
      appEnv: process.env.APP_ENV,
      appStatus: process.env.APP_STATUS,
      showAdminTabs: process.env.VUE_APP_SHOW_ADMIN_TABS,
      showCostExplTab: process.env.VUE_APP_SHOW_COSTEXPL_TAB,
      showAd: process.env.VUE_APP_SHOW_AD
    },
    getters: {
      appName: (state) => {
        return state.packageName
      },
      appVersion: (state) => {
        return state.packageVersion
      },
      appEnv: (state) => {
        return state.appEnv
      },
      appStatus: (state) => {
        return state.appStatus
      },
      showAdminTabs: (state) => {
        return state.showAdminTabs == 'true'
      },
      showCostExplTab: (state) => {
        return state.showCostExplTab == 'true'
      },
      showAd: (state) => {
        return state.showAd == 'true'
      }
    }
  })
}
