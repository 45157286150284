<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-3 text-right">
        <b-form-radio-group
          v-model="formData.expenseProjType"
          :options="expenseProjTypeOptions"
          @change="changeProjectsType"
          name="radio-inline"
          buttons
          button-variant="outline-info"
          size="sm"
          class="lizard-form-radio-btn"
        ></b-form-radio-group>
      </div>
      <div class="col-sm-3 text-left">
        <select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-if="projectType === 'prj'"
          v-model="formData.project"
          @change="changeProject"
          :disabled="!projects.length"
        >
          <option value="def" selected>Select a Project...</option>
          <option v-for="prj in projects" :key="prj.code" :value="prj.code"
            >{{ prj.code }}: {{ prj.name }}</option
          >
        </select>
        <select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-if="projectType === 'bd'"
          v-model="formData.project"
          @change="changeProject"
          :disabled="!projects.length"
        >
          <option value="def" selected>Select a BD Project...</option>
          <option
            v-for="prj in bdprojects"
            :key="prj.code"
            :value="prj.partner + prj.name + prj.clientName"
            >{{ prj.partner }} - {{ prj.name }}: {{ prj.clientName }}</option
          >
        </select>
      </div>
      <div class="col-sm-4 text-left">
        <el-date-picker
          v-model="formData.dates"
          type="daterange"
          range-separator=""
          start-placeholder="From"
          end-placeholder="To"
          class="form-control lizard-form-datepicker"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :disabled="!projects.length"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-2 text-left">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getReports()"
          :disabled="!projects.length"
          >Get Reports</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="showListItemsFilters">
      <div class="col-sm-12">
        <span class="exportCaption"
          ><span v-if="formData.project === 'def'">All</span
          ><span v-if="formData.project !== 'def'">{{ formData.project }}</span>
          Expenses Reports between {{ formData.dates[0] }} to
          {{ formData.dates[1] }}
          <b-form-radio-group
            v-model="formData.expenseProjPaid"
            :options="expenseProjPaidOptions"
            @change="filterByPaidStatus"
            name="radio-inline"
            buttons
            button-variant="outline-secondary"
            size="sm"
            class="lizard-form-radio-btn-2"
          ></b-form-radio-group
        ></span>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="listItems"
          :fields="listFields"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(category)="data">
            {{ categoryLabel(data.item.category) }}
          </template>
          <template #cell(amount)="data">
            {{ calcAmount(data.item) }}
          </template>
          <template #cell(isPaid)="data">
            <el-checkbox
              v-model="data.item.isPaid"
              @change="changePayStatus(data.item)"
            ></el-checkbox>
          </template>
          <template #cell(paidDate)="data">
            <el-date-picker
              v-model="data.item.paidDate"
              type="date"
              placeholder="Pick a day"
              class="lizard-form-date"
              size="small"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              :disabled="!data.item.isPaid"
              @change="changePayStatus(data.item, true)"
            ></el-date-picker>
          </template>
          <template #cell(attachmentObjectKey)="data">
            <ul class="lizard-attachment-list">
              <li v-if="data.item.attachmentObjectKey">
                <b-link @click="downloadReceipt(data.item.attachmentObjectKey)"
                  ><b-icon
                    icon="file-earmark-arrow-down"
                    variant="info"
                  ></b-icon>
                  Download</b-link
                >
              </li>
              <li v-if="data.item.attachmentObjectKey">
                <b-link
                  class="lizard-remove-link"
                  @click="removeBill(data.item)"
                  >replace</b-link
                >
              </li>
              <li v-if="!data.item.attachmentObjectKey">
                <b-form-file
                  drop-placeholder="Drop file here..."
                  size="sm"
                  ref="file"
                  :itmId="data.item.id"
                  @change="uploadBill"
                  :disabled="uploading"
                ></b-form-file>
              </li>
            </ul>
          </template>
          <template #cell(name)="data">
            {{ data.item.user.name }}
          </template>
          <template #cell(show_details)="row">
            <b-button
              @click="row.toggleDetails"
              size="sm"
              variant="outline-info"
              class="lizard-form-radio-btn-2"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
            </b-button>
          </template>
          <template #row-details="row">
            <b-table striped tableVariant='light' stacked :items="exportRowData(row.item)"></b-table>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-9"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'ExpenseReportsByProject',
  components: {},
  data() {
    return {
      projects: [],
      bdprojects: [],
      cachedData: {
        listItems: []
      },
      showProjectsSelector: true,
      pickerOptions: {
        firstDayOfWeek: 1
      },
      expenseProjTypeOptions: [
        { text: 'Project', value: 'prj' },
        { text: 'BD Project', value: 'bd' }
      ],
      projectType: 'prj',
      showListItemsFilters: false,
      expenseProjPaidOptions: [
        { text: 'All', value: 'all' },
        { text: 'Paid', value: 'paid' },
        { text: 'Not Paid', value: 'npaid' }
      ],
      formData: {
        expenseProjType: 'prj',
        expenseProjPaid: 'all',
        project: 'def',
        dates: null
      },
      listFields: [
        // {
        //   key: 'index',
        //   label: 'No',
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        // {
        //   key: 'category',
        //   label: 'Category',
        //   sortable: true,
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        {
          key: 'amount',
          label: 'Amount'
        },
        // {
        //   key: 'description',
        //   label: 'Description',
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        // {
        //   key: 'approvalManager',
        //   label: 'Manager',
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        {
          key: 'isPaid',
          label: 'Paid',
          sortable: true
        },
        {
          key: 'paidDate',
          label: 'Paid Date',
          sortable: true
        },
        {
          key: 'attachmentObjectKey',
          label: 'Receipt'
        },
        {
          key: 'show_details',
          label: ''
        }
      ],
      listItems: [],
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        Amount: 'amount',
        'GBP Amount (£)': 'gbpamount',
        Manager: 'approvalManager',
        Category: 'category',
        Project: 'projectCode',
        Description: 'description',
        'Expense Date': 'date',
        'Paid Date': 'paidDate'
      },
      exportData: [],
      categories: {},
      gbpRates: { eur: 0, usd: 0 }
    }
  },
  async mounted() {
    this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
    await this.GetCategories()
    await this.changeProjectsType()
  },
  methods: {
    async GetCategories() {
      const { status, data } = await Services.GetExpenseCategories()
      if (status === 200) {
        data.forEach(({ id, category }) => {
          this.categories[id] = category
        })
      }
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency?.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount({ currency, amount }, onlyRate = false, currencyConvert = false) {
      let toGBP = ''
      if (currency !== 'GBP' && currencyConvert) {
        if (!onlyRate) {
          toGBP = ' (≈ '
        }
        let gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        if (!isFinite(gbpAmount)) {
          this.gbpRates = this.$cookies.get('__gbp-rates')
          gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        }
        toGBP += `£${parseFloat(gbpAmount).toFixed(2)}`
        if (!onlyRate) {
          toGBP += ')'
        }
      }
      return onlyRate && toGBP.length
        ? toGBP
        : `${this.currencySymbol(currency)}${parseFloat(amount).toFixed(
            2
          )}${toGBP}`
    },
    async changePayStatus(itm, changeDate = false) {
      const paidStatus = itm.isPaid || false
      if (!changeDate) {
        itm.isPaid = !paidStatus
      }
      if (itm.isPaid) {
        itm.paidDate = itm.paidDate || this.$moment().format('YYYY-MM-DD')
        itm.paidDate = this.$moment(itm.paidDate)
          .add(1, 'day')
          .toISOString()
      } else {
        itm.paidDate = null
      }
      await Services.UpdateExpenseAdmin(itm.id, itm)
    },
    async changeProject() {
      if (this.formData.dates) {
        this.getReports()
      }
    },
    filterByPaidStatus() {
      const type = this.formData.expenseProjPaid
      if (type === 'all') {
        this.listItems = this.cachedData.listItems
      } else {
        this.listItems = this.cachedData.listItems
        this.listItems = this.listItems.filter((itm) => {
          if (type === 'paid') {
            return itm.isPaid
          } else {
            return !itm.isPaid
          }
        })
      }
    },
    fomatDate(dt) {
      if (!dt) {
        return 'N/A'
      }
      return this.$moment(dt).format('YYYY-MM-DD')
    },
    exportRowData(data) {
      return [{
        'Project:': data.projectCode,
        'Date:': this.fomatDate(data.date),
        'Category:': this.categories[data.category],
        'Amount:': this.calcAmount(data),
        'Manager:': data.approvalManager,
        'Description:': data.description
      }]
    },
    async changeProjectsType() {
      this.projectType = this.formData.expenseProjType
      if (this.projectType === 'prj') {
        if (!this.projects.length) {
          const { data, status } = await Services.GetProjects()
          if (status === 200) {
            this.projects = data
          }
        }
      } else {
        if (!this.bdprojects.length) {
          const { data, status } = await Services.GetBdProjects()
          if (status === 200) {
            this.bdprojects = data
          }
        }
      }
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      let { status, data } = await Services.DownloadExpenseBill(fileName)
      if (status === 200) {
        window.open(data)
      }
    },
    async removeBill(itm) {
      itm.attachmentObjectKey = null
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await Services.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        const itmId = parseInt(ev.target.attributes['itmId'].value)
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.id) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachmentObjectKey = data.fileName
        await Services.UpdateExpense(itmId, itm)
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    resetProjectsSelector() {
      this.formData.project = 'def'
      this.formData.dates = null
      this.listItems = []
    },
    async getReports() {
      this.formData.expenseProjPaid = 'all'
      const project = this.formData.project
      let dates = this.formData.dates
      if (!project || project == 'def') {
        return
      }
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      const { data, status } = await Services.GetExpensesByProject({
        code: project,
        from: this.$moment(dates[0]).format('YYYY-MM-DD'),
        to: this.$moment(dates[1]).format('YYYY-MM-DD')
      })
      if (status !== 200) {
        this.showListItemsFilters = false
        return
      }
      const listItems = []
      data.forEach((itm) => {
        itm._showDetails = false
        listItems.push(itm)
      })
      this.showListItemsFilters = listItems.length
      this.listItems = listItems
      this.cachedData.listItems = listItems
    },
    generateExcel() {
      const project = this.formData.project
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`
      this.exportName = `${project}-report-${dt}.${this.exportType}`
      this.exportData = []
      this.listItems.forEach((itm, idx) => {
        this.exportData.push({
          no: idx + 1,
          name: itm.user.name,
          email: itm.user.email,
          approvalManager: itm.approvalManager,
          amount: this.calcAmount(itm),
          category: this.categoryLabel(itm.category),
          gbpamount: String(this.calcAmount(itm, true, true)).substr(1),
          projectCode: itm.projectCode,
          description: itm.description,
          date: this.fomatDate(itm.date),
          paidDate: itm.paidDate ? this.fomatDate(itm.paidDate) : 'NOT PAID'
        })
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style>
.lizard-form-radio-btn label {
  line-height: 28px !important;
  padding: 0 10px !important;
}
.lizard-form-radio-btn-2 {
  float: right;
  display: inline;
}
.lizard-form-radio-btn-2 label {
  line-height: 18px !important;
  padding: 0 5px !important;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
.lizard-attachment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-attachment-list li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-remove-link {
  color: #c00;
  font-variant: small-caps;
  font-size: 10pt;
  font-weight: bold;
}
.lizard-remove-link:hover {
  color: #600;
  text-decoration: underline;
}
</style>
