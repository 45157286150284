<script>
import Vue from 'vue'
import axios from 'axios'

const exchangeCredentials = [
  {
    baseUrl: 'https://v6.exchangerate-api.com/v6/',
    params: {
      app_id: 'f8b0147c597480e0f285a9dd',
      base: 'EUR'
    }
  },
  {
    baseUrl: 'https://openexchangerates.org',
    params: {
      app_id: '2ad683372a4b4ab7b9586173d873c5d4',
      base: 'EUR',
      symbols: 'GBP,USD'
    }
  },
  {
    baseUrl: 'https://data.fixer.io',
    params: {
      access_key: '38927115a665ccf8b68f805ad080f4eb',
      base: 'EUR',
      symbols: 'GBP,USD'
    }
  }
]

const RequestDefaults = function(baseUrl) {
  return axios.create({
    baseURL: baseUrl,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const Services = function() {
  this.Request = RequestDefaults(process.env.VUE_APP_API_BASE_URL)
  const {
    Request,
    SetApiToken,
    SetCustomHeader,
    GetEURBaseRates,
    CheckServiceHealth,
    GetProjects,
    GetBdProjects,
    GetProjectsInfo,
    GetUsers,
    GetCandesicProjects,
    UpdateUsers,
    UpdateProjects,
    AddTimelog,
    AddHoliday,
    GetTimeLogs,
    GetCurrentUserTimeLogs,
    GetUserTimelogs,
    GetHolidays,
    RemoveTimelog,
    RemoveHoliday,
    GetClientProjectsReports,
    UploadExpenseBill,
    RemoveExpenseBill,
    DownloadExpenseBill,
    directDownload,
    GetExpenseCategories,
    AddExpense,
    GetExpenses,
    UpdateExpense,
    UpdateExpenseAdmin,
    RemoveExpense,
    GetExpensesByProject,
    GetUserRates,
    AddNewUserRate,
    UpdateNewUserRate,
    GetStaffTimelog
  } = this
  return {
    Request,
    SetApiToken,
    SetCustomHeader,
    GetEURBaseRates,
    CheckServiceHealth,
    GetProjects,
    GetBdProjects,
    GetProjectsInfo,
    GetUsers,
    GetCandesicProjects,
    UpdateUsers,
    UpdateProjects,
    AddTimelog,
    AddHoliday,
    GetTimeLogs,
    GetCurrentUserTimeLogs,
    GetUserTimelogs,
    GetHolidays,
    RemoveTimelog,
    RemoveHoliday,
    GetClientProjectsReports,
    UploadExpenseBill,
    RemoveExpenseBill,
    DownloadExpenseBill,
    directDownload,
    GetExpenseCategories,
    AddExpense,
    GetExpenses,
    UpdateExpense,
    UpdateExpenseAdmin,
    RemoveExpense,
    GetExpensesByProject,
    GetUserRates,
    AddNewUserRate,
    UpdateNewUserRate,
    GetStaffTimelog
  }
}

Services.prototype.SetApiToken = function() {
  this.Request.defaults.headers['Authorization'] =
    'Bearer ' + Vue.$cookies.get('__api-token')
}

Services.prototype.SetCustomHeader = function(key, value) {
  this.Request.defaults.headers[key] = value
}

Services.prototype.CheckServiceHealth = async function() {
  return await this.Request({
    url: '/health'
  })
}

Services.prototype.GetEURBaseRates = function(credentialsId = 0) {
  const credentials = exchangeCredentials[credentialsId]
  if (typeof credentials === 'undefined') {
    throw new Error('exchange rate service is down')
  }
  console.log('fetching exchange rates from: %s', credentials.baseUrl)
  const Request = RequestDefaults(credentials.baseUrl)
  return new Promise((resolve) => {
    Request({
      url: `/${credentials.params.app_id}/latest/${credentials.params.base}`,
      method: 'GET'
    })
      .then((result) => resolve(result))
      .catch((err) => {
        console.log('failed:', err)
        // credentialsId++
        // return this.GetEURBaseRates(credentialsId)
      })
  })
}

Services.prototype.GetProjects = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/monday/projects'
  })
}

Services.prototype.GetBdProjects = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/monday/bds'
  })
}

Services.prototype.GetProjectsInfo = async function(code) {
  this.SetApiToken()
  return await this.Request({
    url: `/project/${code}/costs`
  })
}

Services.prototype.GetUsers = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/user/'
  })
}

Services.prototype.GetCandesicProjects = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/project/'
  })
}

Services.prototype.GetUserTimelogs = async function({ id, from, to }) {
  this.SetApiToken()
  return await this.Request({
    url: `/user/${id}/timelogs/${from}/${to}`
  })
}

Services.prototype.UpdateUsers = async function(data) {
  this.SetApiToken()
  return await this.Request({
    url: '/user/',
    method: 'put',
    data
  })
}

Services.prototype.UpdateProjects = async function(data) {
  this.SetApiToken()
  return await this.Request({
    url: '/project/',
    method: 'put',
    data
  })
}

Services.prototype.GetTimeLogs = async function(bydate = false, dates) {
  this.SetApiToken()
  return await this.Request({
    url: '/timelog' + (bydate ? `/${dates?.from}/${dates?.to}` : '')
  })
}

Services.prototype.GetCurrentUserTimeLogs = async function(
  bydate = false,
  dates
) {
  this.SetApiToken()
  return await this.Request({
    url:
      '/timelog/current-user' + (bydate ? `/${dates?.from}/${dates?.to}` : '')
  })
}

Services.prototype.GetHolidays = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/holiday'
  })
}

Services.prototype.AddTimelog = async function(data = {}) {
  this.SetApiToken()
  let { type, dates, duration, notes, project } = data
  const TypeEnums = {
    project: 1,
    'business development': 2,
    admin: 3,
    training: 4
  }
  return await this.Request({
    url: '/timelog',
    method: 'post',
    data: {
      Type: TypeEnums[type],
      Date: dates[0],
      Hours: duration,
      Notes: notes || null,
      ProjectCode: project
    }
  })
}

Services.prototype.RemoveTimelog = async function(id = null) {
  if (!id) {
    return
  }
  this.SetApiToken()
  return await this.Request({
    url: '/timelog/' + id,
    method: 'delete'
  })
}

Services.prototype.AddHoliday = async function(data = {}) {
  this.SetApiToken()
  let { dates, holiday, notes } = data
  return await this.Request({
    url: '/holiday',
    method: 'post',
    data: {
      FromDate: dates[0],
      ToDate: dates[1],
      IsFullDay: holiday === 'full',
      Notes: notes || null
    }
  })
}

Services.prototype.RemoveHoliday = async function(id = null) {
  if (!id) {
    return
  }
  this.SetApiToken()
  return await this.Request({
    url: '/holiday/' + id,
    method: 'delete'
  })
}

Services.prototype.AddExpense = async function(data = {}) {
  this.SetApiToken()
  let {
    AttachmentObjectKey,
    Description,
    ProjectCode,
    category,
    amount,
    currency,
    approvalmanager,
    date
  } = data
  return await this.Request({
    url: '/expense',
    method: 'post',
    data: {
      AttachmentObjectKey,
      Description,
      ProjectCode,
      amount,
      category,
      currency,
      approvalmanager,
      date
    }
  })
}

Services.prototype.GetExpenses = async function(bydate = false, dates) {
  this.SetApiToken()
  return await this.Request({
    url: '/expense' + (bydate ? `/${dates?.from}/${dates?.to}` : '')
  })
}
Services.prototype.GetExpensesByProject = async function({ code, from, to }) {
  this.SetApiToken()
  return await this.Request({
    url: `/expense/projects/${code}/reports/clients-projects/${from}/${to}`
  })
}

Services.prototype.UpdateExpense = async function(id = null, data) {
  if (!id) {
    return
  }
  this.SetApiToken()
  return await this.Request({
    url: '/expense/' + id,
    method: 'put',
    data
  })
}

Services.prototype.UpdateExpenseAdmin = async function(id = null, data) {
  if (!id) {
    return
  }
  this.SetApiToken()
  return await this.Request({
    url: '/expense/' + id + '/admin',
    method: 'put',
    data
  })
}

Services.prototype.RemoveExpense = async function(id = null) {
  if (!id) {
    return
  }
  this.SetApiToken()
  return await this.Request({
    url: '/expense/' + id,
    method: 'delete'
  })
}

Services.prototype.GetClientProjectsReports = async function({
  code,
  from,
  to
}) {
  this.SetApiToken()
  return await this.Request(
    `/timelog/projects/${code}/reports/clients-projects/${from}/${to}`
  )
}

Services.prototype.UploadExpenseBill = async function(file) {
  this.SetApiToken()
  this.SetCustomHeader('mimeType', 'multipart/form-data')
  const formData = new FormData()
  formData.append('file', file, '_' + encodeURI(file.name.toLowerCase()))
  return await this.Request({
    url: '/expense/upload',
    method: 'post',
    data: formData
  })
}

Services.prototype.RemoveExpenseBill = async function(fileName) {
  this.SetApiToken()
  return await this.Request({
    url: `/expense/file/${fileName}/remove`,
    method: 'delete',
    data: {}
  })
}

Services.prototype.DownloadExpenseBill = async function(fileName) {
  this.SetApiToken()
  return await this.Request({
    url: `/expense/file/${fileName}/url`,
    method: 'get',
    data: {}
  })
}

Services.prototype.GetExpenseCategories = async function() {
  this.SetApiToken()
  return await this.Request({
    url: '/expense/categories',
    method: 'get',
    data: {}
  })
}

Services.prototype.GetUserRates = async function({
  userId = null,
  startDate = null,
  endDate = null
}) {
  this.SetApiToken()
  let url = `/user/${userId ? userId + '/' : ''}rates`
  if (startDate && endDate) {
    url += `/${startDate}/${endDate}`
  }
  return await this.Request({
    url
  })
}

Services.prototype.AddNewUserRate = async function(userId, data = {}) {
  this.SetApiToken()
  return await this.Request({
    url: `/user/${userId}/rates`,
    method: 'post',
    data
  })
}

Services.prototype.UpdateNewUserRate = async function(
  userId,
  rateId,
  data = {}
) {
  this.SetApiToken()
  data.id = rateId
  return await this.Request({
    url: `/user/${userId}/rates`,
    method: 'put',
    data
  })
}

Services.prototype.GetStaffTimelog = async function(
  startDate,
  endDate,
) {
  this.SetApiToken()
  return await this.Request(`/timelog/staff/from/${startDate}/to/${endDate}`)
}

export default new Services()
</script>
