<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-5 text-left">
        <select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-if="projectType === 'prj'"
          v-model="formData.project"
          :disabled="!projects.length"
        >
          <option value="def" selected>Select a Project...</option>
          <option v-for="prj in projects" :key="prj.code" :value="prj.code"
            >{{ prj.code }}: {{ prj.name }}</option
          >
        </select>
      </div>
      <div class="col-sm-5 text-left">
        <el-date-picker
          v-model="formData.dates"
          type="daterange"
          range-separator=""
          start-placeholder="From"
          end-placeholder="To"
          class="form-control lizard-form-datepicker"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :disabled="!projects.length"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-2 text-left">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getReports()"
          :disabled="!projects.length"
          >Get Reports</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <Stats :stats="stats" />
    </div>
    <div class="row" v-if="listItems.length">
      <ProjectsReportsSummary :parentItems="listItems" />
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(user)="data">
            {{ data.value.name }} ({{ data.value.email }})
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-9"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "../../services/index.vue";
import Stats from "./Stats.vue";
import ProjectsReportsSummary from "./ProjectsReportsSummary.vue";

export default {
  name: "ProjetcReportsByClient",
  components: {
    Stats,
    ProjectsReportsSummary
  },
  data() {
    return {
      projects: [],
      bdprojects: [],
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      projectType: "prj",
      formData: {
        project: "def",
        dates: null,
      },
      listFields: [
        {
          key: "index",
          label: "No",
          thClass: "lizard-activity-list-index",
          tdClass: "lizard-activity-list-index",
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          thClass: "lizard-activity-list-date",
          tdClass: "lizard-activity-list-date",
        },
        {
          key: "user",
          label: "User",
          sortable: true,
          thClass: "lizard-activity-list-notes",
          tdClass: "lizard-activity-list-notes",
        },
        {
          key: "hours",
          label: "Hours",
          thClass: "lizard-activity-list-duration",
          tdClass: "lizard-activity-list-duration",
        },
      ],
      listItems: [],
      stats: {
        manDays: { text: "Billable Days", value: 0 },
        hours: { text: "Total Hours", value: 0 },
        days: { text: "Calendar Days", value: 0 },
        adays: { text: "Actual Days", value: 0 },
      },
      exportExcel: true,
      exportType: "xls",
      exportName: "data.xls",
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: "no",
        Project: "projectCode",
        Date: "date",
        Hours: "hours",
        User: "user",
        Notes: "notes",
      },
      exportData: [],
    };
  },
  async mounted() {
    const Projects = await Services.GetProjects();
    const BDProjects = await Services.GetBdProjects();
    this.projects = Projects.data;
    this.bdprojects = BDProjects.data;
  },
  watch: {
    listItems() {
      this.CalcManDays();
    },
  },
  methods: {
    resetProjectsSelector() {
      this.formData.project = "def";
      this.formData.dates = null;
      this.listItems = [];
    },
    async getReports() {
      const project = this.formData.project;
      let dates = this.formData.dates;
      if (!project) {
        return;
      }
      if (!dates) {
        dates = ["2019-01-01", this.$moment().format("YYYY-MM-DD")];
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format("YYYY-MM-DD");
      }
      const Result = await Services.GetClientProjectsReports({
        code: project,
        from: this.$moment(dates[0]).format("YYYY-MM-DD"),
        to: this.$moment(dates[1]).format("YYYY-MM-DD"),
      });
      if (Result.status !== 200) {
        return;
      }
      this.listItems = Result.data.map((itm) => {
        itm.date = this.$moment(itm.date).format("YYYY-MM-DD");
        return itm;
      });
    },
    CalcManDays() {
      this.ResetStats();
      const list = this.listItems;
      const days = {};
      list.forEach((itm) => {
        days[itm.date] = days[itm.date] || 0;
        days[itm.date] += itm.hours;
      });
      this.stats.days.value += Object.keys(days).length;
      const hours = Object.values(days);
      for (let i = 0; i < hours.length; i++) {
        if (hours[i] >= 8) {
          this.stats.manDays.value++;
        } else {
          this.stats.manDays.value += hours[i] / 8;
        }
        this.stats.hours.value += hours[i];
      }
      this.stats.adays.value = parseFloat(this.stats.hours.value / 8).toFixed(
        1
      );
      this.stats.manDays.value = parseFloat(this.stats.manDays.value).toFixed(
        2
      );
    },
    ResetStats() {
      for (let key in this.stats) {
        this.stats[key].value = 0;
      }
    },
    generateExcel() {
      const project = this.formData.project;
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`;
      this.exportName = `${project}-report-${dt}.${this.exportType}`;
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1;
        itm.user = `${itm.user.name}`;
        return itm;
      });
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`;
      this.exportData = [];
    },
  },
};
</script>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
</style>
