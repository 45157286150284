<template>
  <div>
    <div v-if="!loaded">
      <b-badge variant="warning">Please wait...</b-badge>
    </div>
    <div v-else>
      <b-table :fields="data.tbl.fields" :items="data.tbl.items"></b-table>
    </div>
  </div>
</template>

<style scoped>
.lizard-column-fin {
  font-size: 10pt;
}
</style>

<script>
import Services from '../../../services/index.vue'

export default {
  name: 'PorjectCosts',
  props: ['selected'],
  data() {
    return {
      loaded: false,
      data: {
        tbl: {
          fields: [
            {
              key: 'year',
              label: 'Year',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'quarter',
              label: 'Quarter',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'month',
              label: 'Month',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectStartDate',
              label: 'Project Start Date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectEndDate',
              label: 'Project End Date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'activeProjectsDates',
              label: 'No. of Active Project Dates',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectCode',
              label: 'Project Code',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'expenceType',
              label: 'Expense Type',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'expenceDate',
              label: 'Expense Date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'totalExpence',
              label: 'Total Expense',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            }
          ],
          items: []
        }
      }
    }
  },
  mounted() {
    this.Load()
  },
  // watch: {
  //   selected(selected = true) {
  //     if (!selected) {
  //       this.Reset()
  //     } else {
  //       this.Load()
  //     }
  //   }
  // },
  methods: {
    async Load() {
      const { status, data } = await this.GetData()
      const list = status === 200 ? this.PrepareData(data) : []
      const result = []
      const prjsitr = await this.PrjItr(list, result)
      let PrjObj = await prjsitr.next()
      while (!PrjObj.done) {
        PrjObj = await prjsitr.next()
      }
      this.data.tbl.items = result
      console.log({ items: this.data.tbl.items })
      this.loaded = true
    },
    async PrjItr(List = [], FinalResult = []) {
      let itrs = 0
      return {
        next: async () => {
          if (itrs < List.length) {
            const costKeys = {
              expertCallsFees: 'Expert Calls',
              surveyFees: 'Survey',
              teamDinnerFees: 'Team Dinner',
              vouchersFees: 'Vouchers',
              otherFees: 'Other'
            }
            const Prj = List[itrs]
            if (typeof Prj === 'undefined') {
              return { done: true }
            }
            const costs = await Prj.exec(Prj.projectCode)
            for (let key in costKeys) {
              FinalResult.push(
                Object.assign(
                  {},
                  {
                    expenceType: costKeys[key],
                    expenceDate: costs.createdDate,
                    totalExpence: costs[key] || 0
                  },
                  Prj
                )
              )
            }
            itrs++
            return { done: false }
          } else {
            return { done: true }
          }
        }
      }
    },
    async GetData(
      startDate = this.GetDate('currentMonthStart'),
      endDate = this.GetDate('currentMonthEnd')
    ) {
      return await Services.GetStaffTimelog(startDate, endDate)
    },
    GetDate(tag) {
      const now = this.$moment()
      let result = null
      switch (tag) {
        case 'currentMonthStart':
          result = now.startOf('month')
          break
        case 'currentMonthEnd':
          result = now.endOf('month')
          break
      }
      return result.format('YYYY-MM-DD')
    },
    PrepareData(data = []) {
      console.log(data)
      const moment = this.$moment
      const list = []
      data.forEach((prj) => {
        const itm = {
          year: [
            ...new Set([
              moment(prj.startDate).format('YYYY'),
              moment(prj.endDate).format('YYYY')
            ])
          ].join(' - '),
          month: [
            ...new Set([
              moment(prj.startDate).format('MMMM'),
              moment(prj.endDate).format('MMMM')
            ])
          ].join(' - '),
          quarter:
            'Q' +
            [
              ...new Set([
                moment(prj.startDate).quarter(),
                moment(prj.endDate).quarter()
              ])
            ],
          projectStartDate: moment(prj.startDate).format('DD/MM/YYYY'),
          projectEndDate: moment(prj.endDate).format('DD/MM/YYYY'),
          activeProjectsDates: Math.ceil(
            moment(prj.endDate).diff(moment(prj.startDate), 'days', true)
          ),
          projectCode: prj.projectCode,
          projectName: null,
          partnerName: null,
          exec: this.GetProjectInfo
        }
        list.push(itm)
      })
      return list
    },
    async GetProjectInfo(projectCode) {
      const { data: costs } = await Services.GetProjectsInfo(projectCode)
      return costs
    },
    Reset() {
      this.loaded = false
      this.data = {}
    }
  }
}
</script>
