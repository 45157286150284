<template>
  <div><HangFire /></div>
</template>

<script>
import HangFire from '../components/HangFire.vue'

export default {
  name: 'HangFIre',
  components: {
    HangFire
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
