<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card content-class="mt-3" align="left">
            <b-tab active>
              <template #title>
                <b-icon
                  v-if="!loadingDateTab"
                  icon="calendar3"
                  title="By Date"
                ></b-icon
                ><b-spinner
                  v-if="loadingDateTab"
                  type="border"
                  small
                ></b-spinner>
                By Date
              </template>
              <user-reports-by-date />
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon
                  v-if="!loadingPersonTab"
                  icon="person"
                  title="By Person"
                ></b-icon
                ><b-spinner
                  v-if="loadingPersonTab"
                  type="border"
                  small
                ></b-spinner>
                By Person
              </template>
              <user-reports-by-person />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserReportsByDate from "./UserReportsByDate.vue";
import UserReportsByPerson from "./UserReportsByPerson.vue";

export default {
  name: "UserReports",
  components: {
    UserReportsByDate,
    UserReportsByPerson,
  },
  data() {
    return {
      loadingDateTab: false,
      loadingPersonTab: false,
    };
  }
};
</script>
