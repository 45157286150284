<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Project</label></div>
    <div class="col-sm-7">
      <select
        name=""
        id=""
        class="form-control txt"
        v-model="formData.project"
        :disabled="loadingType"
      >
        <option value="def" selected v-if="loadingType">Please wait...</option>
        <option value="def" selected v-if="!loadingType">Select one...</option>
        <option v-for="prj in projects" :key="prj.code" :value="prj.code"
          >{{ prj.code }}: {{ prj.name }}</option
        >
      </select>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
import Services from "../../services/index.vue";

export default {
  name: "ProjectSelector",
  props: ["formData", "dataCache"],
  data() {
    return {
      loadingType: false,
      projects: [],
    };
  },
  async mounted() {
    this.projects = this.dataCache.projects;
    if (!this.projects.length) {
      this.loadingType = true;
      const Projects = await Services.GetProjects();
      this.projects = Projects.data;
      this.dataCache.projects = this.projects;
      this.loadingType = false;
    }
  },
};
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
