import Vue from 'vue'
import Vuex from 'vuex'
import Moment from 'vue-moment'
import Toasted from 'vue-toasted'
import VueCookies from 'vue-cookies'
import Router from './router'
import App from './App.vue'
import Store from './store/index.js'
import JsonExcel from 'vue-json-excel'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import SkeletonCards from 'vue-ultimate-skeleton-cards'
import HighchartsVue from 'highcharts-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuex)
Vue.use(Moment)
Vue.use(Toasted, {
  position: 'top-center',
  fitToScreen: true,
  keepOnHover: true,
  type: 'info'
})
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(SkeletonCards)
Vue.use(Element, { locale })
Vue.use(HighchartsVue, { tagName: 'charts' })
Vue.component('downloadExcel', JsonExcel)

Vue.$cookies.config('8h')

Vue.config.productionTip = false

new Vue({
  router: Router,
  store: Store(Vuex),
  render: (h) => h(App)
}).$mount('#app')
