<template>
  <div>
    <b-button
      variant="outline-info"
      size="sm"
      v-if="!btnDisabled"
      @click="exec()"
      @mouseover="copyToken"
      >{{ btnTxt }}</b-button
    >
    <b-button variant="outline-secondary" size="sm" disabled v-if="btnDisabled"
      >Please wait...</b-button
    >
  </div>
</template>

<script>
import * as msal from '@azure/msal-browser'
import Services from '../services/index.vue'

const InitClient = async function(isAdmin = false, cookies) {
  const msalConfig = {
    auth: {
      clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
      authority: process.env.VUE_APP_AZURE_AUTHORITY,
      knownAuthorities: [],
      redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL,
      postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  }
  const msalClient = new msal.PublicClientApplication(msalConfig)
  try {
    const currentAccounts = msalClient.getAllAccounts()
    if (currentAccounts && currentAccounts.length === 1) {
      if (currentAccounts.length === 1) {
        let account = {
          username: currentAccounts[0].username,
          fullname: currentAccounts[0].name,
          isAdmin
        }
        cookies.set('__auth-data', account)
        return {
          account,
          client: msalClient
        }
      } else {
        console.warn('Multiple accounts detected.')
      }
    } else {
      return {
        account: null,
        client: msalClient
      }
    }
  } catch (Exception) {
    console.log({ Error: Exception.message })
    return {
      account: null,
      client: msalClient,
      reset: true
    }
  }
}

export default {
  name: 'Azure',
  data() {
    return {
      btnTxt: 'Sign-in',
      btnDisabled: false,
      hasUser: false,
      data: {},
      signinLock: false,
      status:
        this?.$store?.getters?.appStatus ?? process?.env?.VUE_APP_STATUS ?? 'up'
    }
  },
  async mounted() {
    this.Init()
    this.btnTxt = this.hasUser ? 'Sign-out' : 'Sign-in'
    // Handle 401!
    Services.Request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          if (!this.signinLock) {
            console.info('SIGN-IN AGAIN PLEASE!')
            this.SignIn()
          }
        } else {
          console.log('ERR:%d', error.response.status, error)
          return Promise.reject(error)
        }
      }
    )
  },
  watch: {
    hasUser(status) {
      this.btnTxt = status ? 'Sign-out' : 'Sign-in'
      this.$root.$emit('user', status)
      const authData = this.$cookies.get('__auth-data')
      if (authData) {
        this.$root.$emit('admin', authData.isAdmin)
      }
    }
  },
  methods: {
    async checkServiceHealth() {
      try {
        if (this.status !== 'up') {
          this.ResetCookies()
          this.$root.$emit('service-status', false)
          return false
        }
        const { status } = await Services.CheckServiceHealth()
        const isAvailble = status === 200
        if (!isAvailble) {
          this.ResetCookies()
        }
        this.$root.$emit('service-status', isAvailble)
        return isAvailble
      } catch ({ message }) {
        this.ResetCookies()
        const errorMessages = [
          'Network Error',
          'Request failed with status code 404'
        ]
        if (errorMessages.indexOf(message) !== -1) {
          this.$root.$emit('service-status', false)
        } else {
          console.error({ message })
        }
      }
    },
    async Init(isAdmin = false) {
      if (await this.checkServiceHealth()) {
        const authData = this.$cookies.get('__auth-data')
        if (authData && !isAdmin) {
          isAdmin = authData.isAdmin
        }
        this.data = await InitClient(isAdmin, this.$cookies)
        if (this.data.reset) {
          this.ResetCookies()
          return this.Init()
        }
        if (this.data.account) {
          this.hasUser = true
          this.signinLock = false
          // console.info("username: %s", this.data.account.username);
        } else {
          this.$root.$emit('fireLoginNotif')
        }
        this.btnDisabled = false
      }
    },
    exec() {
      if (!this.hasUser && !this.btnDisabled) {
        return this.SignIn()
      }
      return this.SignOut()
    },
    async SignIn() {
      try {
        this.signinLock = true
        if (await this.checkServiceHealth()) {
          const msalRequest = {
            scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(',')
          }
          this.btnDisabled = true
          const loginRequest = await this.data.client.loginPopup(msalRequest)
          const loginGroups = loginRequest?.idTokenClaims?.groups ?? []
          const isAdmin =
            loginGroups.indexOf(process.env.VUE_APP_AZURE_ADMIN_GROUP_TOKEN) !==
            -1
          this.$cookies.set('__api-token', loginRequest.accessToken)
          this.Init(isAdmin)
        }
      } catch ({ message }) {
        console.log({ Error: message })
        this.ResetCookies()
        this.Init()
      }
    },
    SignOut() {
      try {
        this.btnDisabled = true
        const { client, account } = this.data
        const logoutRequest = {
          account: client.getAccountByUsername(account.username)
        }
        if (client.logout(logoutRequest)) {
          this.ResetCookies()
        }
      } catch (Exception) {
        let { message } = Exception
        console.log({ Error: message })
        this.Init()
      }
    },
    copyToken() {
      const validUsernames = new Set(
        process.env.VUE_APP_ADMIN_EMAILS.split(',').map((email) =>
          email.toLowerCase()
        )
      )
      if (
        this.data?.account?.isAdmin &&
        validUsernames.has(this.data?.account?.username?.toLowerCase())
      ) {
        navigator.clipboard.writeText(this.$cookies.get('__api-token')).then(
          function() {
            console.info('> admin: api-token copied to your clipboard.')
          },
          function(err) {
            console.error('Could not copy text: ', err)
          }
        )
      }
    },
    ResetCookies() {
      this.$cookies.remove('__auth-data')
      this.$cookies.remove('__api-token')
    }
  }
}
</script>
