<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-7 text-right">
        <el-date-picker
          v-model="formData.dates"
          type="daterange"
          range-separator=""
          start-placeholder="From"
          end-placeholder="To"
          class="form-control lizard-form-datepicker"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-5 text-left">
        <b-button size="sm" variant="outline-primary" @click="getReports()"
          >Get Reports</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <Stats :stats="stats" />
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="listItems"
          :fields="listFields"
        >
          <template #table-caption
            >All Users Reports between {{ formData.dates[0] }} to
            {{ formData.dates[1] }}</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(user)="data">
            {{ data.item.name }}
          </template>
          <template #cell(hourlyRate)="data">
            £{{ data.item.hourlyRate || '0.00' }}
          </template>
          <template #cell(paytohours)="data">
            £{{ data.item.paytohours || '0.00' }}
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-9"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'
import Stats from './Stats.vue'

export default {
  name: 'UserReportsByDate',
  components: {
    Stats
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      formData: {
        dates: null
      },
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'hourlyRate',
          label: 'Hourly Rate',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'hours',
          label: 'Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'paytohours',
          label: 'Pay to Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        }
      ],
      listItems: [],
      listDates: {},
      stats: {
        paytohours: { text: 'Payable', value: 0 },
        hours: { text: 'Total Hours', value: 0 },
        days: { text: 'Calendar Days', value: 0 },
        adays: { text: 'Actual Days', value: 0 }
      },
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        Hours: 'hours',
        'Hourly Rate': 'hourlyRate',
        'Pay to Hours': 'paytohours'
      },
      exportData: []
    }
  },
  watch: {
    listItems() {
      this.CalcStats()
    }
  },
  methods: {
    async getReports() {
      let dates = this.formData.dates
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      const Result = await Services.GetTimeLogs(true, {
        from: this.$moment(dates[0]).format('YYYY-MM-DD'),
        to: this.$moment(dates[1]).format('YYYY-MM-DD')
      })
      if (Result.status !== 200) {
        return
      }
      const listItems = {}
      this.listDates = {}
      Result.data.forEach((itm) => {
        listItems[itm.user.id] = listItems[itm.user.id] || itm.user
        listItems[itm.user.id].hours = listItems[itm.user.id].hours || 0
        listItems[itm.user.id].hours += itm.hours
        listItems[itm.user.id].paytohours = 0
        this.listDates[itm.date] = this.listDates[itm.date] || 0
        this.listDates[itm.date] += itm.hours
      })
      // GET RATES
      const { status, data } = await Services.GetUserRates({
        startDate: dates[0],
        endDate: dates[1]
      })
      if (status === 200 && data.length) {
        data.forEach(({ user, dayRate, hourlyRate, endDate }) => {
          if (endDate) {
            listItems[user.id].dayRate += dayRate
            listItems[user.id].hourlyRate += hourlyRate
          }
        })
        for (let userId in listItems) {
          listItems[userId].dayRate /= data.length
          listItems[userId].hourlyRate /= data.length
          listItems[userId].dayRate = Math.round(listItems[userId].dayRate)
          listItems[userId].hourlyRate = Math.round(
            listItems[userId].hourlyRate
          )
          listItems[userId].paytohours =
            listItems[userId].hours * listItems[userId].hourlyRate
        }
      } else {
        // TODO: Check if user has a rate
        for (let userId in listItems) {
          const { hourlyRate, hours } = listItems[userId]
          if (hours && hourlyRate) {
            listItems[userId].paytohours = hours * hourlyRate
          }
        }
      }
      // END GET RATES
      this.listItems = Object.values(listItems)
    },
    CalcStats() {
      try {
        this.ResetStats()
        const list = this.listItems
        list.forEach((itm) => {
          this.stats.paytohours.value += itm.paytohours
        })
        this.stats.paytohours.value = '£' + this.stats.paytohours.value
        this.stats.days.value += Object.keys(this.listDates).length
        const hours = Object.values(this.listDates)
        for (let i = 0; i < hours.length; i++) {
          this.stats.hours.value += hours[i]
        }
        this.stats.adays.value = parseFloat(this.stats.hours.value / 8).toFixed(
          1
        )
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    ResetStats() {
      for (let key in this.stats) {
        this.stats[key].value = 0
      }
    },
    generateExcel() {
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`
      this.exportName = `all-users-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        return itm
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
</style>
