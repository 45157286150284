<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-8 text-left header-msg">
        <h3>
          <span>My Expenses</span>
        </h3>
      </div>
      <div class="col-sm-2 text-right">
        <b-button
          variant="outline-info"
          class="lizard-view-link"
          size="sm"
          v-if="listItems.length"
          @click="toggleViewType"
        >
          <span v-if="viewType === 'list'">Calendar</span>
          <span v-if="viewType === 'calendar'">List</span> view
        </b-button>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          v-if="viewType === 'list'"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(amount)="data">
            {{ calcAmount(data.item) }}
          </template>
          <template #cell(category)="data">
            {{ categoryLabel(data.item.category) }}
          </template>
          <template #cell(attachmentObjectKey)="data">
            <ul class="lizard-attachment-list">
              <li v-if="data.item.attachmentObjectKey">
                <b-link @click="downloadReceipt(data.item.attachmentObjectKey)"
                  ><b-icon
                    icon="file-earmark-arrow-down"
                    variant="info"
                  ></b-icon>
                  Download</b-link
                >
              </li>
              <li v-if="data.item.attachmentObjectKey">
                <b-link class="lizard-remove-link" @click="removeBill(data.item)"
                  >replace</b-link>
              </li>
              <li v-if="!data.item.attachmentObjectKey">
                <b-form-file
                  drop-placeholder="Drop file here..."
                  size="sm"
                  ref="file"
                  :itmId="data.item.id"
                  @change="uploadBill"
                  :disabled="uploading"
                ></b-form-file>
              </li>
            </ul>
          </template>
          <template #cell(action)="data">
            <b-link @click="doRemove(data.value.id)"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
          </template>
        </b-table>
        <b-calendar
          v-if="viewType === 'calendar'"
          v-model="calDate"
          @context="onContext"
          :date-info-fn="dateClass"
          block
          hide-header
          locale="en-US"
          variant="secondary"
        ></b-calendar>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-overlay no-wrap :show="deleteInProgress">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Are you sure?</strong></p>
          <div class="d-flex">
            <b-button variant="outline-danger" class="mr-3" @click="onCancel">
              Cancel
            </b-button>
            <b-button variant="outline-info" @click="onConfirm"
              >Confirm</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'ExpenseLogs',
  data() {
    return {
      viewType: 'list',
      deleteInProgress: false,
      deleteItemId: null,
      uploading: false,
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-col-w5 lizard-text-center',
          tdClass: 'lizard-col-w5 lizard-text-center'
        },
        {
          key: 'projectCode',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-col-w10',
          tdClass: 'lizard-col-w10'
        },
        {
          key: 'category',
          label: 'Category',
          sortable: true,
          thClass: 'lizard-col-w10 lizard-text-center',
          tdClass: 'lizard-col-w10 lizard-text-center'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'lizard-col-w10 lizard-text-center',
          tdClass: 'lizard-col-w10 lizard-text-center'
        },
        {
          key: 'amount',
          label: 'Amount',
          thClass: 'lizard-col-w10 lizard-text-center',
          tdClass: 'lizard-col-w10 lizard-text-center'
        },
        {
          key: 'description',
          label: 'Description',
          thClass: 'lizard-col-w10 lizard-text-center',
          tdClass: 'lizard-col-w10'
        },
        {
          key: 'approvalManager',
          label: 'Manager',
          thClass: 'lizard-col-w10 lizard-text-center',
          tdClass: 'lizard-col-w10'
        },
        {
          key: 'attachmentObjectKey',
          label: 'Receipt',
          thClass: 'lizard-col-w15 lizard-text-center',
          tdClass: 'lizard-col-w15'
        },
        {
          key: 'action',
          label: '',
          thClass: 'lizard-col-w5 lizard-text-center',
          tdClass: 'lizard-col-w5 lizard-text-center'
        }
      ],
      listItems: [],
      calendarItems: {},
      calDate: '',
      context: null,
      categories: {},
      gbpRates: { eur: 0, usd: 0 }
    }
  },
  async mounted() {
    this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
    await this.GetCategories()
    const now = new Date()
    this.calDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.listItems = await this.GetList()
    this.$root.$on('saved', async () => {
      this.listItems = await this.GetList()
    })
  },
  methods: {
    async GetList() {
      try {
        let { status, data } = await Services.GetExpenses()
        if (status === 200) {
          return this.structurizeDataSchema(data)
        } else {
          return []
        }
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    async GetCategories() {
      const { status, data } = await Services.GetExpenseCategories()
      if (status === 200) {
        data.forEach(({ id, category }) => {
          this.categories[id] = category
        })
      }
    },
    structurizeDataSchema(data = []) {
      data = data.map((itm) => {
        itm.date = itm.date.split('T')[0]
        this.calendarItems[itm.date] = itm.amount
        itm.action = {
          id: itm.id
        }
        return itm
      })
      return data
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount({ currency, amount }) {
      let toGBP = ''
      // // console.log('gbpRates:', currency, this.gbpRates[currency.toLowerCase()])
      // if (currency !== 'GBP') {
      //   toGBP =
      //     ' (≈ £' +
      //     parseFloat(amount * this.gbpRates[currency.toLowerCase()]).toFixed(
      //       2
      //     ) +
      //     ')'
      // }
      return (
        this.currencySymbol(currency) + parseFloat(amount).toFixed(2) + toGBP
      )
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      let { status, data } = await Services.DownloadExpenseBill(fileName)
      if (status === 200) {
        window.open(data)
      }
    },
    async removeBill(itm) {
      itm.attachmentObjectKey = null
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await Services.UploadExpenseBill(ev.target.files[0])
      if (status === 200) {
        const itmId = parseInt(ev.target.attributes['itmId'].value)
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.id) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachmentObjectKey = data.fileName
        await Services.UpdateExpense(itmId, itm)
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    async doRemove(id) {
      this.deleteInProgress = true
      this.deleteItemId = id
    },
    onCancel() {
      this.deleteInProgress = false
      this.deleteItemId = null
    },
    async onConfirm() {
      this.deleteInProgress = false
      if (!this.deleteItemId) {
        return
      }
      let { status } = await Services.RemoveExpense(this.deleteItemId)
      if (status === 200) {
        this.listItems = this.listItems.filter((itm) => {
          return itm.id !== this.deleteItemId
        })
      }
      this.deleteItemId = null
      this.deleteInProgress = false
    },
    onContext(ctx) {
      this.context = ctx
    },
    toggleViewType() {
      this.viewType = this.viewType === 'list' ? 'calendar' : 'list'
    },
    dateClass(ymd, date) {
      let className = ''
      const dt = this.$moment(date).format('YYYY-MM-DD')
      if (this.calendarItems[dt]) {
        className = 'half-activity-calday'
      }
      return this.$moment().diff(dt) > 0 ? className : ''
    }
  }
}
</script>

<style>
.half-activity-calday {
  background-color: #ccc !important;
  color: #333 !important;
}
.lizard-text-center {
  text-align: center !important;
}
.lizard-col-w5 {
  width: 5% !important;
}
.lizard-col-w10 {
  width: 10% !important;
}
.lizard-col-w15 {
  width: 15% !important;
}
.lizard-col-w20 {
  width: 20% !important;
}
.lizard-col-w25 {
  width: 25% !important;
}
.lizard-col-w30 {
  width: 30% !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-view-link {
  font-weight: bold;
}
.lizard-today-btn {
  margin: 10px;
  float: right;
}
.lizard-attachment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-attachment-list li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-remove-link {
  color: #c00;
  font-variant: small-caps;
  font-size: 10pt;
  font-weight: bold;
}
.lizard-remove-link:hover {
  color: #600;
  text-decoration: underline;
}
</style>
