<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm text-left">
        <select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-model="formData.user"
          @change="userChanged"
          :disabled="!users.length"
        >
          <option value="def" selected>Select a User...</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{
            user.name
          }}</option>
        </select>
      </div>
      <div class="col-sm-3 text-left" v-if="userprojects.length">
        <select
          name=""
          id=""
          size="sm"
          class="form-control txt"
          v-model="userproject"
        >
          <option value="def" selected>Filter by Project...</option>
          <option
            v-for="project in userprojects"
            :key="project.id"
            :value="project.id"
            >{{ project.name }}</option
          >
        </select>
      </div>
      <div class="col-sm-4 text-left">
        <el-date-picker
          v-model="formData.dates"
          type="daterange"
          range-separator=""
          start-placeholder="From"
          end-placeholder="To"
          class="form-control lizard-form-datepicker"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :disabled="!users.length"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-2 text-left">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getReports()"
          :disabled="!users.length"
          >Get Reports</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <Stats :stats="stats" :prjstats="projectsStats" />
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="listItems"
          :fields="listFields"
          :filter="filterCriteria"
          :filter-function="filterList"
        >
          <template #table-caption
            >{{ user.name }} Reports between {{ formData.dates[0] }} to
            {{ formData.dates[1] }}</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(projectCode)="data">
            {{ data.item.projectCode }}
          </template>
          <template #cell(paytohours)="data">
            £{{ data.item.paytohours || '0.00' }}
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-9"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'
import Stats from './Stats.vue'

export default {
  name: 'UserReportsByPerson',
  components: {
    Stats
  },
  data() {
    return {
      user: null,
      users: [],
      userprojects: [],
      userprojectsAll: [],
      userproject: 'def',
      pickerOptions: {
        firstDayOfWeek: 1
      },
      formData: {
        user: 'def',
        dates: null
      },
      filterCriteria: 'true',
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'projectCode',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'hours',
          label: 'Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'paytohours',
          label: 'Pay to Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        }
      ],
      listItems: [],
      stats: {
        paytohours: { text: 'Payable', value: 0 },
        hours: { text: 'Total Hours', value: 0 },
        days: { text: 'Calendar Days', value: 0 },
        adays: { text: 'Actual Days', value: 0 }
      },
      projectsStats: {
        _info: {},
        _data: []
      },
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Project: 'projectCode',
        Date: 'date',
        Hours: 'hours',
        'Pay to Hours': 'paytohours',
        Notes: 'notes'
      },
      exportData: []
    }
  },
  async mounted() {
    const Users = await Services.GetUsers()
    this.users = Users.data.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    })
  },
  watch: {
    userproject(n) {
      if (n !== 'def') {
        this.listItems = this.listItems.map((itm) => {
          itm.show = itm.projectCode === n
          return itm
        })
      } else {
        this.listItems = this.listItems.map((itm) => {
          itm.show = true
          return itm
        })
      }
      this.CalcStats()
    }
  },
  methods: {
    resetProjectsSelector() {
      this.formData.project = 'def'
      this.formData.dates = null
      this.listItems = []
    },
    filterList(row, filter) {
      return row.show === Boolean(filter)
    },
    async getReports() {
      this.projectsStats = {
        _info: {},
        _data: []
      }
      this.ResetStats()
      this.resetExportData()
      this.userprojects = []
      this.formData.userproject = 'def'
      this.user = this.users.filter((user) => {
        return user.id == this.formData.user
      })[0]
      let dates = this.formData.dates
      if (!this.user) {
        return
      }
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      dates[0] = this.$moment(dates[0]).format('YYYY-MM-DD')
      dates[1] = this.$moment(dates[1]).format('YYYY-MM-DD')
      this.projectsStats._info = {
        user: this.user,
        dates
      }
      const Result = await Services.GetUserTimelogs({
        id: this.user.id,
        from: dates[0],
        to: dates[1]
      })
      if (Result.status !== 200) {
        return
      }
      const averages = {
        dayRate: 0,
        hourlyRate: 0
      }
      const userRates = await Services.GetUserRates({
        userId: this.user.id,
        startDate: dates[0],
        endDate: dates[1]
      })
      if (userRates.status == 200) {
        userRates.data.forEach(({ dayRate, hourlyRate }) => {
          averages.dayRate += dayRate
          averages.hourlyRate += hourlyRate
        })
        averages.dayRate /= userRates.data.length
        averages.hourlyRate /= userRates.data.length
        averages.dayRate = Math.round(averages.dayRate)
        averages.hourlyRate = Math.round(averages.hourlyRate)
      }
      this.userProjectsAll = []
      this.listItems = Result.data.map((itm) => {
        itm.dayRate = averages.dayRate
        itm.hourlyRate = averages.hourlyRate
        itm.date = this.$moment(itm.date).format('YYYY-MM-DD')
        itm.paytohours = (itm.hourlyRate || 0) * (itm.hours || 0)
        itm.show = true
        itm.projectCode = itm.projectCode || this.getNullProjectCode(itm.type)
        if (this.userProjectsAll.indexOf(itm.projectCode) === -1) {
          this.userprojects.push({
            id: itm.projectCode,
            name: itm.projectCode
          })
          this.userProjectsAll.push(itm.projectCode)
        }
        return itm
      })
      this.CalcStats()
    },
    CalcStats() {
      this.ResetStats()
      const typesMap = {
        1: 'project',
        2: 'business development',
        3: 'admin',
        4: 'training'
      }
      const list = this.listItems.filter((itm) => itm.show)
      const days = {}
      list.forEach((itm) => {
        days[itm.date] = days[itm.date] || 0
        days[itm.date] += itm.hours
        if (!itm.projectCode) {
          itm.projectCode = itm.type === 3 ? 'ADMIN' : 'TRAINING'
        }
        this.projectsStats._data[itm.projectCode] = this.projectsStats._data[
          itm.projectCode
        ] || {
          daysList: [],
          hours: 0,
          days: 0,
          adays: 0,
          type: typesMap[itm.type]
        }
        this.projectsStats._data[itm.projectCode].hours += itm.hours
        if (
          !this.projectsStats._data[itm.projectCode].daysList.includes(itm.date)
        ) {
          this.projectsStats._data[itm.projectCode].daysList.push(itm.date)
        }
      })
      for (let prj in this.projectsStats._data) {
        this.projectsStats._data[prj].days = this.projectsStats._data[
          prj
        ].daysList.length
        this.projectsStats._data[prj].adays = parseFloat(
          this.projectsStats._data[prj].hours / 8
        ).toFixed(1)
      }
      this.stats.days.value += Object.keys(days).length
      const hours = Object.values(days)
      for (let i = 0; i < hours.length; i++) {
        this.stats.hours.value += hours[i]
      }
      this.stats.adays.value = parseFloat(this.stats.hours.value / 8).toFixed(1)
      this.stats.paytohours.value =
        '£' +
        parseFloat(this.stats.hours.value * this.user.hourlyRate).toFixed(2)
    },
    userChanged() {
      this.resetExportData()
      this.ResetStats()
      this.userprojects = []
    },
    getNullProjectCode(projectType) {
      return projectType === 3 ? 'ADMIN' : projectType === 4 ? 'TRAINING' : '--'
    },
    ResetStats() {
      for (let key in this.stats) {
        this.stats[key].value = 0
      }
    },
    generateExcel() {
      const user = this.user.name.toLowerCase().replace(' ', '-')
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`
      this.exportName = `${user}-hours-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        if (!itm.projectCode) {
          itm.projectCode = itm.type === 3 ? 'ADMIN' : 'TRAINING'
        }
        return itm
      })
    },
    resetExportData() {
      // this.projectsStats = {}
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
</style>
