<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-12 text-left">
        <ul class="lizard-reports-stats">
          <li v-for="stat in stats" :key="stat.text" :value="stat.value">
            <b-button variant="light" class="lizard-stats-btn" disabled>
              {{ stat.text }}:
              <b-badge variant="info">{{ stat.value }}</b-badge>
            </b-button>
          </li>
          <li>&nbsp;</li>
          <li>
            <download-excel
              :before-generate="generateExcel"
              :before-finish="resetExportData"
              :type="exportType"
              :name="exportName"
              :fields="exportFields"
              :data="exportData"
              :worksheet="exportWorksheet"
              v-if="exportExcel"
            >
              <b-button variant="outline-success" size="sm"
                ><b-icon
                  icon="file-earmark-excel-fill"
                  title="Projects Days Report"
                ></b-icon>
                Projects Days Report</b-button
              >
            </download-excel>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stats',
  props: {
    stats: Object,
    prjstats: Object
  },
  data() {
    return {
      exportExcel: false,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Project: 'projectCode',
        Type: 'type',
        Hours: 'hours',
        'Calendar Days': 'days',
        'Actual Days': 'adays'
      },
      exportData: []
    }
  },
  mounted() {
    if (this.prjstats && Object.keys(this.prjstats).length) {
      this.exportExcel = true
    }
  },
  methods: {
    generateExcel() {
      let username = this.prjstats._info.user.name
        .toLowerCase()
        .replace(new RegExp(' ', 'g'), '_')
        .trim()
      this.exportName = `${username}-project-stats-${this.prjstats._info.dates[0]}-${this.prjstats._info.dates[1]}.${this.exportType}`
      this.exportData = []
      let idx = 1
      for (let prj in this.prjstats._data) {
        this.exportData.push({
          no: idx,
          projectCode: prj,
          type: this.prjstats._data[prj].type,
          hours: this.prjstats._data[prj].hours,
          days: this.prjstats._data[prj].days,
          adays: this.prjstats._data[prj].adays
        })
        idx++
      }
    },
    resetExportData() {
      // this.projectsStats = {}
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style scoped>
div#export {
  max-width: 135px;
}
.btn-outline-success {
  font-size: 8pt;
  font-weight: bold;
}
.lizard-reports-stats {
  list-style: none;
  margin: 0;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-stats-btn {
  font-size: 11pt;
  font-weight: bold;
}
.lizard-stats-btn:hover {
  cursor: default !important;
  background-color: #333;
  color: #ccc;
}
.lizard-stats-btn .badge {
  font-size: 11pt !important;
}
</style>
