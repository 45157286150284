<template>
  <div class="row">
    <div class="col-lg">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-2"><label for="" class="h6">Amount</label></div>
        <div class="col-sm-3">
          <input
            type="number"
            name=""
            id=""
            class="form-control lizard-form-duration txt"
            :placeholder="formData.amount"
            v-model="formData.amount"
            max="9999.99"
            @change="calcRates"
          />
        </div>
        <div class="col-sm-5 gbp-rate">
          <CurrencySelector :formData="formData" />
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencySelector from './CurrencySelector.vue'

export default {
  name: 'AmountSelector',
  props: ['formData'],
  components: { CurrencySelector },
  data() {
    return {
      approxGBPRate: '',
      gbpRates: { eur: 0, usd: 0 }
    }
  },
  mounted() {
    this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
    this.calcRates(null, this.formData.amount)
    this.$root.$on('currency-changed', () => {
      this.calcRates(null, this.formData.amount)
    })
  },
  methods: {
    calcRates(ev, fval = null) {
      let val = parseFloat(ev?.target?.value ?? fval)
      if (isNaN(val)) {
        val = 0
      }
      let convertedVal = 0
      this.formData.amount = val
      if (this.formData.currency === 'gbp') {
        convertedVal = parseFloat(val)
        if (convertedVal > 9999.99) {
          convertedVal = 9999.99
        }
        this.approxGBPRate = '= £' + convertedVal.toFixed(2)
      } else {
        if (this.gbpRates[this.formData.currency] === 0) {
          this.approxGBPRate = 'Loading...'
          return
        }
        convertedVal = parseFloat(val / this.gbpRates[this.formData.currency])
        if (convertedVal > 9999.99) {
          convertedVal = 9999.99
        }
        this.approxGBPRate = '≈ £' + convertedVal.toFixed(2)
      }
    }
  }
}
</script>

<style scoped>
.amount-cell {
  padding-right: 5px;
}
.gbp-rate {
  padding: 0;
  margin: 0 !important;
}
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: left;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
