<template>
  <div id="app" class="container">
    <router-view />
    <div class="lizard-footer" v-if="showFooter">
      <p>
        <span>Made by <b-icon-heart-fill variant="info"></b-icon-heart-fill> at Candesic Technical Team</span>
        <ul>
          <li><a href="#">
            mahdi</a></li>
            <li><b-icon-dot></b-icon-dot></li>
          <li><a href="mailto:amirhosseini@candesic.com">aref</a></li>
          <li><b-icon-dot></b-icon-dot></li>
          <li><a href="mailto:ckortschak@candesic.com">chris</a></li>
        </ul>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showFooter: true
    }
  },
  mounted() {
    this.$root.$on('toggleFooter', () => {
      this.showFooter = !this.showFooter;
    })
  },
}
</script>

<style>
body {
  background: rgba(100, 100, 100, 0.55) url('assets/bg-2.jpg') no-repeat center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-blend-mode: darken;
  display:flex; 
  flex-direction:column; 
  height: 100vh;
}
.lizard-footer {
  background: rgba(0, 0, 0, 0.55);
  background-blend-mode: darken;
  border: 1px solid #666;
  border-radius: 3px;
  color: #CCC;
  margin-top: auto;
  padding: 5px 0 0 0;
  bottom: 0;
  max-height: 50px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.lizard-footer ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}
.lizard-footer ul li {
  margin: 0 2px;
  padding: 0;
  display: inline-block;
  font-variant: small-caps;
}
.lizard-footer ul li a {
  color: #999
}
.lizard-footer ul li a:hover {
  color: #EEE;
  text-decoration: none;
}
</style>
