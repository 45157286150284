<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-8 text-left header-msg">
        <h3>
          <span>My Timesheet</span>
        </h3>
      </div>
      <div class="col-sm-2 text-right">
        <b-button
          variant="outline-info"
          class="lizard-view-link"
          size="sm"
          v-if="cachedItems.length"
          @click="toggleViewType"
        >
          <span v-if="viewType === 'list'">Calendar</span>
          <span v-if="viewType === 'calendar'">List</span> view
        </b-button>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!cachedItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="cachedItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <div class="row">
          <div class="col-sm-4">
            <select
              name=""
              id=""
              v-if="viewType === 'list' && prjTyp !== 'trn' && prjTyp !== 'adm'"
              class="form-control txt"
              size="sm"
              v-model="projectFilter"
              :disabled="loadingProjectFilter"
            >
              <option value="def" selected v-if="loadingProjectFilter"
                >Please wait...</option
              >
              <option value="def" selected v-if="!loadingProjectFilter"
                >All Projects...</option
              >
              <option v-for="prj in projects" :key="prj.id" :value="prj.id">{{
                prj.label
              }}</option>
            </select>
          </div>
          <div class="col-sm-8">
            <b-form-radio-group
              v-if="viewType === 'list'"
              v-model="prjTyp"
              :options="prjTypOptions"
              name="radio-inline"
              buttons
              button-variant="outline-secondary"
              size="sm"
              class="lizard-form-radio-btn-2"
            ></b-form-radio-group>
          </div>
        </div>
        <hr />
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          :current-page="page"
          :per-page="perPage"
          v-if="viewType === 'list'"
          id="list-tbl"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + (page - 1) * perPage }}
          </template>
          <template #cell(projectCode)="data">
            {{ data.item.projectCode || '--' }}
          </template>
          <template #cell(action)="data">
            <b-link @click="doRemove(data.value.id)"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="rows"
          :per-page="perPage"
          v-if="viewType === 'list'"
          aria-controls="list-tbl"
          align="center"
          pills
          size="sm"
        ></b-pagination>
        <b-calendar
          v-if="viewType === 'calendar'"
          v-model="calDate"
          @context="onContext"
          :date-info-fn="dateClass"
          block
          hide-header
          locale="en-US"
          variant="secondary"
        ></b-calendar>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems && listItems.length && viewType === 'calendar'">
      <div class="col-lg text-center lizard-help-list">
        <ul>
          <li class="no-activity-calday">No Activity</li>
          <li class="half-activity-calday">Half Activity</li>
          <li class="full-activity-calday">Full Activity</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-overlay no-wrap :show="deleteInProgress">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Are you sure?</strong></p>
          <div class="d-flex">
            <b-button variant="outline-danger" class="mr-3" @click="onCancel">
              Cancel
            </b-button>
            <b-button variant="outline-info" @click="onConfirm"
              >Confirm</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'TimeLogs',
  data() {
    return {
      viewType: 'list',
      loadingProjectFilter: false,
      projectFilter: 'def',
      prjTypOptions: [
        { text: 'All', value: 'all', disabled: false },
        { text: 'Projects', value: 'prj', disabled: true },
        { text: 'Business Development', value: 'bd', disabled: true },
        { text: 'Training', value: 'trn', disabled: true },
        { text: 'Admin', value: 'adm', disabled: true }
      ],
      prjTyp: 'all',
      deleteInProgress: false,
      deleteItemId: null,
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'projectCode',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'notes',
          label: 'Notes',
          thClass: 'lizard-activity-list-notes',
          tdClass: 'lizard-activity-list-notes'
        },
        {
          key: 'hours',
          label: 'Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'lizard-activity-list-action',
          tdClass: 'lizard-activity-list-action'
        }
      ],
      cachedItems: [],
      listItems: [],
      page: 1,
      perPage: 10,
      projects: [],
      calendarItems: {},
      calDate: '',
      context: null
    }
  },
  computed: {
    rows() {
      return this.listItems.length
    }
  },
  async mounted() {
    await this.UpdateData()
    this.$root.$on('saved', async () => {
      await this.UpdateData()
    })
  },
  methods: {
    async UpdateData() {
      const now = new Date()
      this.calDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const data = await this.GetList()
      this.cachedItems = Object.assign([], data)
      this.listItems = data
      this.getProjectOptions()
      this.getAvailableOptions()
    },
    async GetList() {
      try {
        let { status, data } = await Services.GetTimeLogs()
        if (status === 200) {
          return this.structurizeDataSchema(data)
        } else {
          return []
        }
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    structurizeDataSchema(data = []) {
      const TypeEnums = {
        1: 'project',
        2: 'business development',
        3: 'admin',
        4: 'training'
      }
      const TypeEnumsID = {
        1: 'prj',
        2: 'bd',
        3: 'adm',
        4: 'trn'
      }
      data = data.map((itm) => {
        const projTypeObj = this.prjTypOptions[
          this.getDuplicateId(
            this.prjTypOptions,
            TypeEnumsID[itm.type],
            'value'
          )
        ]
        itm.keyId = projTypeObj.value
        itm.type = TypeEnums[itm.type]
        itm.date = itm.date.split('T')[0]
        this.calendarItems[itm.date] = this.calendarItems[itm.date] || 0
        this.calendarItems[itm.date] += itm.hours
        itm.action = {
          id: itm.id
        }
        return itm
      })
      data = data.filter((itm) => {
        return this.prjTyp !== 'all' ? itm.keyId === this.prjTyp : true
      })
      return data
    },
    getProjectOptions() {
      this.projects = []
      this.cachedItems.forEach((itm) => {
        if (
          !itm.projectCode ||
          (itm.keyId !== this.prjTyp && this.prjTyp !== 'all')
        ) {
          return
        }
        const duplicatedId = this.getDuplicateId(this.projects, itm.projectCode)
        if (duplicatedId === -1) {
          this.projects.push({
            id: itm.projectCode,
            label:
              itm.type === 'project'
                ? itm.projectCode
                : `[BD] ${itm.projectCode}`
          })
        }
      })
    },
    getAvailableOptions() {
      this.prjTypOptions = this.prjTypOptions.map((itm) => {
        if (itm.value === 'all' && this.cachedItems.length) {
          itm.disabled = false
        } else {
          itm.disabled =
            this.getDuplicateId(this.cachedItems, itm.value, 'keyId') === -1
        }
        return itm
      })
    },
    getDuplicateId(arr, val, key = 'id') {
      let idx = -1
      arr.forEach((itm, itmIdx) => {
        if (itm[key] === val) {
          idx = itmIdx
        }
      })
      return idx
    },
    async doRemove(id) {
      this.deleteInProgress = true
      this.deleteItemId = id
    },
    onCancel() {
      this.deleteInProgress = false
      this.deleteItemId = null
    },
    async onConfirm() {
      this.deleteInProgress = false
      if (!this.deleteItemId) {
        return
      }
      let { status } = await Services.RemoveTimelog(this.deleteItemId)
      if (status === 200) {
        await this.UpdateData()
      }
      this.deleteItemId = null
      this.deleteInProgress = false
    },
    onContext(ctx) {
      this.context = ctx
    },
    toggleViewType() {
      this.viewType = this.viewType === 'list' ? 'calendar' : 'list'
    },
    dateClass(ymd, date) {
      let className = 'no-activity-calday'
      const dt = this.$moment(date).format('YYYY-MM-DD')
      if (this.calendarItems[dt]) {
        className =
          this.calendarItems[dt] >= 8
            ? 'full-activity-calday'
            : this.calendarItems[dt] > 0 && this.calendarItems[dt] < 8
            ? 'half-activity-calday'
            : 'no-activity-calday'
      }
      return this.$moment().diff(dt) > 0 ? className : ''
    }
  },
  watch: {
    projectFilter() {
      this.listItems = this.cachedItems.filter(({ keyId, projectCode }) => {
        return this.projectFilter !== 'def'
          ? projectCode === this.projectFilter
          : this.prjTyp !== 'all'
          ? keyId === this.prjTyp
          : true
      })
    },
    prjTyp() {
      this.getProjectOptions()
      this.getAvailableOptions()
      this.listItems = this.cachedItems.filter(({ keyId }) => {
        return this.prjTyp !== 'all' ? keyId === this.prjTyp : true
      })
    }
  }
}
</script>

<style>
.no-activity-calday {
  background-color: rgb(236, 143, 143) !important;
  color: #fff !important;
}
.half-activity-calday {
  background-color: #ccc !important;
  color: #333 !important;
}
.full-activity-calday {
  background-color: rgb(157, 247, 157) !important;
  color: #333 !important;
}
</style>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-view-link {
  font-weight: bold;
}
.lizard-today-btn {
  margin: 10px;
  float: right;
}
</style>
