<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card content-class="mt-3" align="left">
            <b-tab
              active
              v-if="tabs.listView"
              id="listView"
              @click="tabChanged"
            >
              <template #title>
                <b-icon icon="list" title="List View"></b-icon>
                List
              </template>
              <user-list
                :listItems="listItems"
                :inactiveListItems="inactiveListItems"
              />
            </b-tab>
            <b-tab
              v-if="tabs.activityCalendar"
              id="activityCalendar"
              @click="tabChanged"
            >
              <template #title>
                <b-icon icon="calendar3" title="Activity Calendar"></b-icon>
                Activity Calendar
              </template>
              <activity-calendar :listItems="listItems" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import UserList from './users-components/List.vue'
import ActivityCalendar from './users-components/ActivityCalendar.vue'
import Services from '../../services/index.vue'

export default {
  name: 'Users',
  components: {
    UserList,
    ActivityCalendar
  },
  data() {
    return {
      tabs: {
        listView: true,
        activityCalendar: true
      },
      loading: true,
      listItems: [],
      inactiveListItems: []
    }
  },
  async mounted() {
    this.fetchData()
    this.$root.$on('hourly-rate-changed', this.changeHourlyRate)
    this.$root.$on('daily-rate-changed', this.changeDayRate)
    this.$root.$on('current-employee-changed', this.changeCurrentEmployee)
  },
  methods: {
    tabChanged(ev) {
      this.fetchData(ev.target.id.split('_')[0])
    },
    async fetchData(tab = null) {
      this.loading = true
      const Users = await Services.GetUsers()
      this.listItems = Users.data
      this.listItems.forEach((itm) => {
        const cond =
          this.$moment().diff(this.$moment(itm.lastActivityDate), 'days') > 1
        if (cond) {
          itm.lastActivityEntry = this.$moment(itm.lastActivityDate).format(
            'YYYY-MM-DD'
          )
          itm.inactivityPeriod = this.$moment(itm.lastActivityDate).fromNow()
          this.inactiveListItems.push(itm)
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
      if (tab === 'activityCalendar') {
        this.$root.$emit('list-updated')
      }
    },
    changeHourlyRate({ id, value }) {
      this.listItems = this.listItems.map((itm) => {
        if (itm.id === parseInt(id)) {
          itm.hourlyRate = parseFloat(value)
          itm.dayRate = itm.hourlyRate * 8
        }
        return itm
      })
    },
    changeDayRate({ id, value }) {
      this.listItems = this.listItems.map((itm) => {
        if (itm.id === parseInt(id)) {
          itm.dayRate = parseFloat(value)
          itm.hourlyRate = Math.round(itm.dayRate / 8)
        }
        return itm
      })
    },
    changeCurrentEmployee({ id, checked }) {
      this.listItems = this.listItems.map((itm) => {
        if (itm.id === parseInt(id)) {
          itm.isCurrentEmployee = Boolean(checked)
        }
        return itm
      })
    },
    async saveChanges() {
      try {
        if (this.loading) {
          return
        }
        this.loading = true
        await Services.UpdateUsers(this.listItems)
        setTimeout(() => {
          this.loading = false
          this.$toasted
            .success('Information updated successfully.')
            .goAway(2000)
        }, 500)
      } catch (Exception) {
        this.loading = false
        this.$toasted.error('Request Failed! Please try again later.')
      }
    }
  },
  watch: {
    listItems() {
      this.saveChanges()
    },
    inactiveListItems() {
      this.saveChanges()
    }
  }
}
</script>
