<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand>
        <img src="../assets/candesic.png" @click="goToCandesic" title="Candesic.com" width="120" />
        <pre v-if="!isPrd() && isUp()">
        <b-badge title="" variant="warning" class="env-badge" @click="toggleFooter">{{
          env
        }} (v{{appVersion}})</b-badge></pre>
        <pre v-if="isPrd() && isUp()">
        <b-badge title="" variant="success" class="env-badge" @click="toggleFooter">{{appName}} (v{{appVersion}})</b-badge>
      </pre>
      <pre v-if="!isUp()">
        <b-badge title="" variant="danger" class="env-badge" @click="toggleFooter">beta</b-badge>
      </pre>
      </b-navbar-brand>
      <Azure class="lizard-navbar-azure" v-if="isUp()" />
    </b-navbar>
  </div>
</template>

<script>
import Azure from "./Azure.vue";

export default {
  name: "Navbar",
  components: {
    Azure,
  },
  data() {
    return {
      status: this?.$store?.getters?.appStatus ??
        process?.env?.VUE_APP_STATUS ??
        "up",
      env:
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        "production",
    };
  },
  methods: {
    isUp() {
      return this.status === 'up';
    },
    isPrd() {
      return ["staging", "development"].indexOf(this.env) === -1;
    },
    toggleFooter() {
      this.$root.$emit('toggleFooter')
    },
    goToCandesic() {
      window.open('https://www.candesic.com')
    }
  },
  computed: {
    appName() {
      return this?.$store?.getters?.appName ?? "default";
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? "0.0.0";
    },
  },
};
</script>

<style scoped>
img {
  cursor: pointer;
}
pre {
  display: inline-flex;
  margin: 0 0 0 10px;
}
.env-badge {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 6px 2px 10px;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.lizard-navbar-azure {
  position: absolute;
  right: 2% !important;
}
</style>
