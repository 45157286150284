<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">
        <h3>
          <span>Cost Explorer</span>
        </h3>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <projects-dashboard />
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<script>
import ProjectsDashboard from './ProjectsDashboard.vue'

export default {
  name: 'ManagersDashboard',
  components: { ProjectsDashboard },
  data() {
    return {}
  }
}
</script>

<style scoped>
.card-body {
  padding: 0.25rem !important;
}
</style>
