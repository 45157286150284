<template>
  <div>
    <Navbar />
    <div v-if="showContent">
      <WelcomeUser v-if="showContent" />
      <b-card no-body>
        <b-tabs card class="content-bg">
          <b-tab active>
            <template #title>
              <b-icon icon="plus-square" title="New"></b-icon> New
            </template>
            <ActivityEntry />
          </b-tab>
          <b-tab>
            <template #title>
              <b-icon
                icon="file-earmark-spreadsheet"
                title="Timesheets"
              ></b-icon>
              My Timesheets
            </template>
            <TimeLogs />
          </b-tab>
          <b-tab v-if="expenseFeatureIsActive">
            <template #title>
              <b-icon icon="cash-stack" title="Expenses"></b-icon> My Expenses
            </template>
            <expense-logs />
          </b-tab>
          <b-tab>
            <template #title>
              <b-icon icon="controller" title="Holidays"></b-icon> My Holidays
            </template>
            <HolidayLogs />
          </b-tab>
          <b-tab v-if="showAdminContent" :title-link-class="linkClass('admin')">
            <template #title>
              <b-icon icon="gear" title="Admin"></b-icon> Admin
            </template>
            <Admin />
          </b-tab>
          <b-tab v-if="showReportContent" :title-link-class="linkClass('admin')">
            <template #title>
              <b-icon icon="clipboard-data" title="Reports"></b-icon> Reports
            </template>
            <Reports />
          </b-tab>
          <b-tab
            v-if="showCostExplTab"
            :title-link-class="linkClass('manager')"
          >
            <template #title>
              <strong>Cost Explorer</strong>
            </template>
            <ManagersDashboard />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div v-if="!showContent">
      <AdBanner />
    </div>
    <div v-if="!showContent">
      <About />
    </div>
  </div>
</template>

<style>
.card {
  background: rgba(255, 255, 255, 0.8) !important;
}
.nav-item .nav-link {
  background-color: #CCC !important;
  border-color: transparent !important;
  color: #000 !important;
  border-radius: 6px !important;
  margin: 0 3px !important;
}
.nav-item .nav-link.active {
  background-color: #333 !important;
  border-color: transparent !important;
  color: #FFF !important;
  border-radius: 6px !important;
}
</style>

<script>
import Services from '../services/index.vue'
import Navbar from '../components/Navbar.vue'
import AdBanner from '../components/AdBanner.vue'
import About from '../components/About.vue'
import WelcomeUser from '../components/WelcomeUser.vue'
import ActivityEntry from '../components/ActivityEntry.vue'
import TimeLogs from '../components/logs-components/TimeLogs.vue'
import HolidayLogs from '../components/logs-components/HolidayLogs.vue'
import ExpenseLogs from '../components/logs-components/ExpenseLogs.vue'
import Admin from '../components/admin/Admin.vue'
import Reports from '../components/report-components/Reports.vue'
import ManagersDashboard from '../components/beta/ManagersDashboard.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    AdBanner,
    About,
    WelcomeUser,
    ActivityEntry,
    TimeLogs,
    HolidayLogs,
    ExpenseLogs,
    Admin,
    Reports,
    ManagersDashboard
  },
  data() {
    return {
      showContent: false,
      showReportContent: false,
      showAdminContent: false,
      showAdminTabs: this?.$store?.getters?.showAdminTabs ??
        process?.env?.VUE_APP_SHOW_ADMIN_TABS ??
        true,
      showCostExplTab: this?.$store?.getters?.showCostExplTab ??
        process?.env?.VUE_APP_SHOW_COSTEXPL_TAB ??
        true,
      expenseFeatureIsActive: process.env.VUE_APP_FEATURE_EXPENSE == 'true',
      env:
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
    }
  },
  mounted() {
    this.$root.$on('user', (hasUser = false) => {
      this.showContent = hasUser
      this.$root.$emit('toggleFooter')
      // console.log({ hasUser });
    })
    this.$root.$on('admin', (isAdmin = false) => {
      this.showReportContent = isAdmin
      this.showAdminContent = isAdmin && this.showAdminTabs
      if (isAdmin) {
        const gbpRates = this.$cookies.get('__gbp-rates')
        if (!gbpRates) {
          // console.log('exchange is not available.')
          Services.GetEURBaseRates()
            .then((result) => {
              if (result.status === 200) {
                let rates = {
                  eur: 1 / result.data.conversion_rates.GBP,
                  usd: 0
                }
                rates.usd = parseFloat(
                  rates.eur * result.data.conversion_rates.USD
                ).toFixed(2)
                rates.eur = parseFloat(rates.eur).toFixed(2)
                console.log('using new rates', rates)
                this.$cookies.set('__gbp-rates', rates, '8H')
              } else {
                console.log(result.data.error)
              }
            })
            .catch(({ message }) => {
              console.log('ERROR', { message })
            })
        } else {
          console.log('using old rates', gbpRates)
        }
      }
    })
    this.$root.$on('service-status', (isAvailable) => {
      this.toastNotif = null
      setTimeout(() => {
        if (!isAvailable) {
          this.fireNetworkNotif(false)
        } else {
          this.fireNetworkNotif(true)
        }
      }, 500)
    })
    this.$root.$on('fireLoginNotif', () => {
      setTimeout(() => {
        this.fireLoginNotif(false)
      }, 2000)
    })
    const authData = this.$cookies.get('__auth-data')
    if (authData) {
      this.showReportContent = authData.isAdmin
      this.showAdminContent = authData.isAdmin && this.showAdminTabs
    }
  },
  methods: {
    fireNetworkNotif(success = true) {
      if (success) {
        this.$toasted.success('Timesheet service is available!').goAway(1000)
      } else {
        this.$toasted.error(
          'Timesheet service is not available right now. Please try again later.'
        )
      }
    },
    fireLoginNotif() {
      if (this.showContent) {
        return
      }
      return this.$toasted
        .info('Please sign-in using your Candesic account.')
        .goAway(1500)
    },
    linkClass(role) {
      if (role === 'admin') {
        return ['bg-secondary', 'text-light']
      }
      if (role === 'manager') {
        return ['bg-warning', 'text-light']
      }
    },
    isPrd() {
      return ['staging', 'development'].indexOf(this.env) === -1
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
  // computed: {
  //   username() {
  //     // We will see what `params` is shortly
  //     return this.$route.params.username
  //   }
  // },
}
</script>
