<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card content-class="mt-3" align="left">
            <b-tab active>
              <template #title>
                <b-icon 
                  icon="grid"
                  title="Summary"
                ></b-icon
                >
                Summary
              </template>
              <projects-summary />
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon
                  v-if="!loadingClientTab"
                  icon="briefcase"
                  title="By Client"
                ></b-icon
                ><b-spinner
                  v-if="loadingClientTab"
                  type="border"
                  small
                ></b-spinner>
                By Client
              </template>
              <project-reports-by-client />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsSummary from "./ProjectsSummary.vue";
import ProjectReportsByClient from "./ProjectReportsByClient.vue";

export default {
  name: "ExpenseReports",
  components: {
    ProjectsSummary,
    ProjectReportsByClient,
  },
  data() {
    return {
      loadingClientTab: false,
    };
  }
};
</script>
