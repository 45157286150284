<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">
        <h3>
          <span>Admin</span>
        </h3>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <b-card no-body>
          <b-tabs pills card vertical class="tabs-space-ver">
            <b-tab title="Users" active>
              <Users />
            </b-tab>
            <b-tab title="Projects" v-if="showReportsTab">
              <Projects />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>


<script>
import Users from './Users.vue';
import Projects from './Projects.vue';

export default {
  name: "Admin",
  components: {
    Users,
    Projects,
  },
  data() {
    return {
      showReportsTab: false,
    };
  },
};
</script>

<style>
.tabs-space-ver li {
  margin-bottom: 3px !important;
}
.tabs-space-ver li a.nav-link {
  background-color: transparent !important;
}
.tabs-space-ver li a.nav-link:hover {
  background-color: #666 !important;
  color:#EEE !important;
}
</style>