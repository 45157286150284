<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left header-msg">
        <h4><strong>Welcome {{fullname}}!</strong></h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeUser",
  data() {
    return {
      fullname: "Guest",
    };
  },
  mounted() {
    const authData = this.$cookies.get("__auth-data");
    if (authData) {
      this.fullname = authData.fullname.split(' ')[0];
    } else {
      this.fullname = 'Guest';
    }
  },
};
</script>

<style scoped>
.container-lg {
  /* background-color: #bc0707 !important; */
  background-color: #23b1f3 !important;
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  color: #F3F3F3;
  /* color: #0773BC; */
  font-weight: bold !important;
}
.header-msg h4 {
  margin: 0;
}
</style>
