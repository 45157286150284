<template>
  <div class="container-lg">
    <div class="row" v-if="showInactivityTbl && inactiveListItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="inactiveListItems"
          :fields="inactiveListFields"
        >
          <template #table-caption
            >Users whom last timesheet are older than 24 hours:</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(hourlyRate)="data">
            £
            <input
              type="number"
              name="hourlyRateInput"
              class="lizard-hourly-rate-input"
              :id="data.item.id"
              :value="data.item.hourlyRate || '0'"
              @change="changeHourlyRate"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption>Users List</template>
          <template #cell(index)="data">
            {{ data.index + 1 + (pager.currentPage - 1) * pager.perPage }}
          </template>
          <template #cell(hourlyRate)="data">
            £{{ data.item.hourlyRate || 0 }}
          </template>
          <template #cell(dayRate)="data">
            £{{ data.item.dayRate || 0 }}
          </template>
          <template #cell(isCurrentEmployee)="data">
            <input
              type="checkbox"
              name="currentEmployee"
              :id="data.item.id"
              :checked="data.item.isCurrentEmployee || false"
              @change="changeCurrentEmployee"
            />
          </template>
          <template #cell(actions)="data">
            <b-button
              class="manage-rates-btn"
              :id="data.item.id"
              variant="link"
              size="sm"
              v-if="data.item.isCurrentEmployee || false"
              @click="toggleRateManagementModal(data.item)"
              >Manage Rates</b-button
            >
          </template>
        </b-table>
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row">
      <div class="col-lg" style="color:#EEE">
        t:{{ listItems.length }}
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg" style="text-align: right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="rateManagementModal.show"
      centered
      :title="rateManagementModal.data.title"
      :header-bg-variant="rateManagementModal.headerBgVariant"
      :header-text-variant="rateManagementModal.headerTextVariant"
      :body-bg-variant="rateManagementModal.bodyBgVariant"
      :body-text-variant="rateManagementModal.bodyTextVariant"
      :footer-bg-variant="rateManagementModal.footerBgVariant"
      :footer-text-variant="rateManagementModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-row type="flex" justify="end">
              <el-button
                title="Add New"
                type="text"
                size="small"
                :disabled="rateManagementModal.data.loading"
                @click="toggleNewRateManagementModal"
                >Add New</el-button
              >
            </el-row>
            <el-row><p>&nbsp;</p></el-row>
            <el-row>
              <el-table
                v-loading="rateManagementModal.data.loading"
                element-loading-text="Please wait..."
                :data="rateManagementModal.data.listItems"
                style="width: 100%"
                height="250"
                header-cell-class-name="lizard-header-cell"
                row-class-name="lizard-row-cell"
                border
                ><el-table-column type="index" label="No"> </el-table-column
                ><el-table-column
                  prop="startDate"
                  label="Start date"
                ></el-table-column>
                <el-table-column
                  prop="endDate"
                  label="End date"
                ></el-table-column>
                <el-table-column
                  prop="hourlyRate"
                  label="Hourly rate"
                ></el-table-column>
                <el-table-column prop="dayRate" label="Day rate">
                </el-table-column
                ><el-table-column align="right">
                  <template slot="header">Actions</template>
                  <template slot-scope="scope">
                    <el-button
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      style="border:0px"
                      title="Edit"
                      @click="toggleEditRateManagementModal(scope.row)"
                    ></el-button>
                  </template> </el-table-column
              ></el-table>
            </el-row>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="toggleRateManagementModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="newRateModal.show"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      :title="newRateModal.data.title"
      :header-bg-variant="newRateModal.headerBgVariant"
      :header-text-variant="newRateModal.headerTextVariant"
      :body-bg-variant="newRateModal.bodyBgVariant"
      :body-text-variant="newRateModal.bodyTextVariant"
      :footer-bg-variant="newRateModal.footerBgVariant"
      :footer-text-variant="newRateModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-form
              ref="form"
              :model="newRateModal.data.form"
              label-width="120px"
            >
              <el-form-item label="Rate">
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Hourly Rate"
                    v-model="newRateModal.data.form.hourlyRate"
                    @change="calcRateAmount('hourlyRate')"
                  ></el-input>
                </el-col>
                <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Daily Rate"
                    v-model="newRateModal.data.form.dailyRate"
                    @change="calcRateAmount('dailyRate')"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="Rate Period">
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Start Date"
                    v-model="newRateModal.data.form.startDate"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-col>
                <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="End Date"
                    v-model="newRateModal.data.form.endDate"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="addNewRate"
          >
            Submit
          </b-button>
          <b-button
            variant="light"
            size="sm"
            class="float-right"
            @click="toggleNewRateManagementModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="editRateModal.show"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      :title="editRateModal.data.title"
      :header-bg-variant="editRateModal.headerBgVariant"
      :header-text-variant="editRateModal.headerTextVariant"
      :body-bg-variant="editRateModal.bodyBgVariant"
      :body-text-variant="editRateModal.bodyTextVariant"
      :footer-bg-variant="editRateModal.footerBgVariant"
      :footer-text-variant="editRateModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-form
              ref="form"
              :model="editRateModal.data.form"
              label-width="120px"
            >
              <el-form-item label="Rate">
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Hourly Rate"
                    v-model="editRateModal.data.form.hourlyRate"
                    @change="calcRateAmount('hourlyRate', editRateModal)"
                  ></el-input>
                </el-col>
                <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Daily Rate"
                    v-model="editRateModal.data.form.dailyRate"
                    @change="calcRateAmount('dailyRate', editRateModal)"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="Rate Period">
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Start Date"
                    v-model="editRateModal.data.form.startDate"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-col>
                <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="End Date"
                    v-model="editRateModal.data.form.endDate"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="editRate"
          >
            Update
          </b-button>
          <b-button
            variant="light"
            size="sm"
            class="float-right"
            @click="toggleEditRateManagementModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Services from '../../../services/index.vue'

export default {
  name: 'UsersList',
  props: ['listItems', 'inactiveListItems'],
  data() {
    return {
      showInactivityTbl: false,
      pager: {
        perPage: 15,
        currentPage: 1
      },
      inactiveListFields: [
        {
          key: 'index',
          label: 'No'
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'lastActivityEntry',
          label: 'Last Activity Entry',
          sortable: true
        },
        {
          key: 'inactivityPeriod',
          label: 'Inactivity Period',
          sortable: true
        }
      ],
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-column-index',
          tdClass: 'lizard-column-index'
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'hourlyRate',
          label: 'Hourly Rate',
          thClass: 'lizard-column-hrate',
          tdClass: 'lizard-column-hrate'
        },
        {
          key: 'dayRate',
          label: 'Day Rate',
          thClass: 'lizard-column-hrate',
          tdClass: 'lizard-column-hrate'
        },
        {
          key: 'lastActivityEntry',
          label: 'Last Activity',
          sortable: true
        },
        {
          key: 'inactivityPeriod',
          label: 'Inactivity Period',
          sortable: true
        },
        {
          key: 'isCurrentEmployee',
          label: 'Notifications',
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thClass: 'lizard-column-actions',
          tdClass: 'lizard-column-actions'
        }
      ],
      rateManagementModal: {
        show: false,
        headerBgVariant: 'primary',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          userId: null,
          listItems: []
        }
      },
      newRateModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          form: {
            dailyRate: null,
            hourlyRate: null,
            startDate: null,
            endDate: null
          }
        }
      },
      editRateModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          form: {
            rateId: null,
            dailyRate: null,
            hourlyRate: null,
            startDate: null,
            endDate: null
          }
        }
      },
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        Name: 'name',
        'Last Activity': 'lastActivity',
        'Days Due': 'daysDue'
      },
      exportData: []
    }
  },
  methods: {
    changeHourlyRate(ev) {
      this.$root.$emit('hourly-rate-changed', ev.target)
    },
    changeDayRate(ev) {
      this.$root.$emit('daily-rate-changed', ev.target)
    },
    changeCurrentEmployee(ev) {
      this.$root.$emit('current-employee-changed', ev.target)
    },
    async getRates(userId) {
      this.rateManagementModal.data.loading = true
      const { status, data } = await Services.GetUserRates({
        userId
      })
      this.rateManagementModal.data.loading = false
      if (status !== 200) {
        return this.toggleRateManagementModal()
      }
      this.rateManagementModal.data.userId = userId
      this.rateManagementModal.data.listItems = data
      this.rateManagementModal.data.listItems.map((itm) => {
        itm.startDate = this.$moment(itm.startDate).format('YYYY-MM-DD')
        itm.endDate = itm.endDate
          ? this.$moment(itm.endDate).format('YYYY-MM-DD')
          : 'Now'
        itm.hourlyRate = '£' + parseInt(itm.dayRate / 8)
        itm.dayRate = '£' + itm.dayRate
        return itm
      })
    },
    async toggleRateManagementModal(user = null) {
      const hasUserObj =
        Object.prototype.toString.call(user).match(/.+?Object\]$/g) !== null
      this.rateManagementModal.data.listItems = []
      if (!this.rateManagementModal.show && hasUserObj) {
        this.rateManagementModal.data.title = 'Loading...'
        await this.getRates(user.id)
        this.rateManagementModal.data.title = `${user.name}'s rates`
      } else {
        this.rateManagementModal.data.title = 'Default'
        if (!this.newRateModal.show) {
          this.rateManagementModal.data.userId = null
          this.rateManagementModal.data.listItems = []
        }
      }
      this.rateManagementModal.show = !this.rateManagementModal.show
    },
    async toggleNewRateManagementModal() {
      this.rateManagementModal.show = !this.rateManagementModal.show
      if (!this.newRateModal.show) {
        this.newRateModal.data.loading = true
        this.newRateModal.data.title = 'Loading...'
        this.newRateModal.data.loading = false
        this.newRateModal.data.title = 'New Rate'
      } else {
        this.newRateModal.data.title = 'Default'
      }
      this.newRateModal.show = !this.newRateModal.show
      if (!this.newRateModal.show) {
        for (let key in this.newRateModal.data.form) {
          this.newRateModal.data.form[key] = null
        }
      }
    },
    async toggleEditRateManagementModal(data = {}) {
      this.rateManagementModal.show = !this.rateManagementModal.show
      if (!this.editRateModal.show) {
        this.editRateModal.data.form.rateId = data.id
        this.editRateModal.data.form.dailyRate = parseInt(
          data.dayRate.substr(1)
        )
        this.editRateModal.data.form.hourlyRate = parseInt(
          data.hourlyRate.substr(1)
        )
        this.editRateModal.data.form.startDate = data.startDate
        this.editRateModal.data.form.endDate = data.endDate
        this.editRateModal.data.title = 'Edit Rate'
      } else {
        this.editRateModal.data.title = 'Default'
      }
      this.editRateModal.show = !this.editRateModal.show
      if (!this.editRateModal.show) {
        for (let key in this.editRateModal.data.form) {
          this.editRateModal.data.form[key] = null
        }
      }
    },
    calcRateAmount(key, Modal = this.newRateModal) {
      const val = Modal.data.form[key]
      const target = key === 'dailyRate' ? 'hourlyRate' : 'dailyRate'
      if (target === 'dailyRate') {
        Modal.data.form[target] = parseInt(val * 8)
      } else {
        Modal.data.form[target] = parseInt(val / 8)
      }
    },
    async addNewRate() {
      this.calcRateAmount('dailyRate')
      if (this.newRateModal.data.form.startDate === null) {
        return
      }
      const objToSend = {
        DayRate: this.newRateModal.data.form.dailyRate,
        HourlyRate: this.newRateModal.data.form.hourlyRate,
        StartDate: this.$moment(this.newRateModal.data.form.startDate).format(
          'YYYY-MM-DD'
        ),
        EndDate:
          this.newRateModal.data.form.endDate === null
            ? null
            : this.$moment(this.newRateModal.data.form.endDate).format(
                'YYYY-MM-DD'
              )
      }
      const { status, data } = await Services.AddNewUserRate(
        this.rateManagementModal.data.userId,
        objToSend
      )
      if (status === 200) {
        this.toggleNewRateManagementModal()
        await this.getRates(data.user.id)
      }
    },
    async editRate() {
      this.calcRateAmount('dailyRate', this.editRateModal)
      if (this.editRateModal.data.form.startDate === null) {
        return
      }
      const objToSend = {
        DayRate: this.editRateModal.data.form.dailyRate,
        HourlyRate: this.editRateModal.data.form.hourlyRate,
        StartDate: this.$moment(this.editRateModal.data.form.startDate).format(
          'YYYY-MM-DD'
        ),
        EndDate:
          this.editRateModal.data.form.endDate === null
            ? null
            : this.$moment(this.editRateModal.data.form.endDate).format(
                'YYYY-MM-DD'
              )
      }
      const { status, data } = await Services.UpdateNewUserRate(
        this.rateManagementModal.data.userId,
        this.editRateModal.data.form.rateId,
        objToSend
      )
      if (status === 200) {
        this.toggleEditRateManagementModal()
        await this.getRates(data.user.id)
      } else {
        console.log('F!', status)
      }
    },
    generateExcel() {
      const today = this.$moment().format('YYYY-MM-DD')
      this.exportName = `timesheet-activity-report-${today}.${this.exportType}`
      this.exportData = this.listItems
        .filter((item) => item.isCurrentEmployee)
        .map(({ name, lastActivityDate, lastActivityEntry }) => {
          return {
            name,
            lastActivity: lastActivityEntry,
            daysDue: this.$moment().diff(this.$moment(lastActivityDate), 'days')
          }
        })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-actions {
  text-align: center !important;
}
.lizard-column-actions .manage-rates-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
.lizard-column-actions .manage-rates-btn:hover {
  color: #09c;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.lizard-header-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell:hover {
  background-color: #eee;
}
</style>
