<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-1 text-right"></div>
      <div class="col-sm-7 text-right">
        <el-date-picker
          v-model="formData.dates"
          type="daterange"
          range-separator=""
          start-placeholder="From"
          end-placeholder="To"
          class="form-control lizard-form-datepicker"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :disabled="loading"
        >
        </el-date-picker>
      </div>
      <div class="col-sm-3 text-left">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getReports()"
          :disabled="loading"
          >Get Summary</b-button
        >
      </div>
      <div class="col-sm-1 text-right"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6 v-if="loading">
          <b-spinner type="border" small></b-spinner>&nbsp;
          <strong>Please wait...</strong>
        </h6>
        <h6 v-if="!loading">
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length && !loading">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length && !loading">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <b-table
          dark
          responsive
          striped
          bordered
          outlined
          fixed
          hover
          class="lizard-activity-list"
          caption-top
          :small="small"
          :head-variant="headVariant"
          :foot-clone="footClone"
          :items="listItems"
          :fields="listFields"
        >
          <template #cell(code)="data">
            {{ data.item.code }}
          </template>
          <template #cell(costs)="data">
            {{
              calcAmount({
                currency: 'GBP',
                amount: data.item.costs
              })
            }}
          </template>
          <template #custom-foot="data">
            <b-tr>
              <b-th variant="warning" class="lizard-total-text">Total:</b-th>
              <b-th variant="warning" class="lizard-total-text text-center">{{
                calcAmount({
                  currency: 'GBP',
                  amount: calcTotal(data.items, 'costs')
                })
              }}</b-th>
            </b-tr>
          </template>
        </b-table>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'ExpenseReportsSummary',
  data() {
    return {
      loading: true,
      projects: [],
      pickerOptions: {
        firstDayOfWeek: 1
      },
      showListItemsFilters: false,
      expenseProjPaidOptions: [
        { text: 'All', value: 'all' },
        { text: 'Paid', value: 'paid' },
        { text: 'Not Paid', value: 'npaid' }
      ],
      formData: {
        dates: null,
        expenseProjPaid: 'all'
      },
      small: true,
      headVariant: 'info',
      footClone: false,
      loadingFilter: false,
      userFilter: 'def',
      listFields: [
        // {
        //   key: 'index',
        //   label: 'No',
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        {
          key: 'code',
          label: 'Project',
          sortable: true
        },
        {
          key: 'costs',
          label: 'Total costs (£)',
          thClass: 'lizard-text-center',
          tdClass: 'lizard-text-center'
        }
      ],
      cachedData: {
        listItems: []
      },
      listItems: [],
      listDates: {},
      userList: [],
      selectedUser: 'All',
      userexpense: 'def',
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Timesheet Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        Amount: 'amount',
        'GBP Amount (£)': 'gbpamount',
        Manager: 'approvalManager',
        Category: 'category',
        Project: 'projectCode',
        Description: 'description',
        'Expense Date': 'date',
        'Paid Date': 'paidDate'
      },
      exportData: [],
      categories: {},
      gbpRates: { eur: 0, usd: 0 },
      userRates: {}
    }
  },
  async mounted() {
    this.loading = true
    this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
    const { data: projects } = await Services.GetProjects()
    this.projects = projects.map((prj) => prj.code)
    await this.getUserRates()
    this.loading = false
  },
  methods: {
    async getReports(index = 0, list = []) {
      this.loading = true
      let dates = this.formData.dates
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      const { status, data } = await this.getProjectReports(
        this.projects[index],
        dates
      )
      if (status !== 200) {
        if (index < this.projects.length - 1) {
          return await this.getReports(index + 1, list)
        } else {
          return await this.prepareList(list)
        }
      } else {
        data.forEach((itm) => {
          list.push({
            code: itm.projectCode,
            user: itm.user,
            hours: itm.hours,
          })
        })
        if (index < this.projects.length - 1) {
          return await this.getReports(index + 1, list)
        } else {
          return await this.prepareList(list)
        }
      }
    },
    async prepareList(list) {
      this.loading = false
      list.forEach(itm => {
        const key = this.listItems.findIndex((item) => item.code === itm.code)
        if (key === -1) {
          this.listItems.push({
            code: itm.code,
            costs: itm.hours * this.userRates[itm.user.id]
          })
        } else {
          this.listItems[key].costs += itm.hours * this.userRates[itm.user.id]
        }
      })
    },
    async getProjectReports(code, dates) {
      if (!dates) {
        dates = ['2019-01-01', this.$moment().format('YYYY-MM-DD')]
      } else if (dates.length === 1) {
        dates[1] = this.$moment().format('YYYY-MM-DD')
      }
      return await Services.GetClientProjectsReports({
        code,
        from: this.$moment(dates[0]).format('YYYY-MM-DD'),
        to: this.$moment(dates[1]).format('YYYY-MM-DD')
      })
    },
    async getUserRates() {
      const { status, data } = await Services.GetUsers()
      if (status !== 200) {
        return
      }
      data.forEach((itm) => {
        this.userRates[itm.id] = itm.hourlyRate || 0
      })
      // console.log({ data: this.userRates })
    },
    async GetCategories() {
      const { status, data } = await Services.GetExpenseCategories()
      if (status === 200) {
        data.forEach(({ id, category }) => {
          this.categories[id] = category
        })
      }
    },
    getDuplicateId(arr, val, key = 'code') {
      let idx = -1
      arr.forEach((itm, itmIdx) => {
        if (itm[key] === val) {
          idx = itmIdx
        }
      })
      return idx
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency?.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount(
      { currency, amount },
      onlyRate = false,
      currencyConvert = false
    ) {
      let toGBP = ''
      if (currency !== 'GBP' && currencyConvert) {
        if (!onlyRate) {
          toGBP = ' (≈ '
        }
        let gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        if (!isFinite(gbpAmount)) {
          this.gbpRates = this.$cookies.get('__gbp-rates')
          gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        }
        toGBP += `£${parseFloat(gbpAmount).toFixed(2)}`
        if (!onlyRate) {
          toGBP += ')'
        }
      }
      return onlyRate && toGBP.length
        ? toGBP
        : `${this.currencySymbol(currency)}${parseFloat(amount).toFixed(
            2
          )}${toGBP}`
    },
    calcTotal(itms, key) {
      let total = 0
      itms.forEach((itm) => {
        total += parseFloat(itm[key])
      })
      return total
    },
    fomatDate(dt) {
      if (!dt) {
        return 'N/A'
      }
      return this.$moment(dt).format('YYYY-MM-DD')
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      let { status, data } = await Services.DownloadExpenseBill(fileName)
      if (status === 200) {
        window.open(data)
      }
    },
    async removeBill(itm) {
      itm.attachmentObjectKey = null
    },
    async changePayStatus(itm, changeDate = false) {
      const paidStatus = itm.isPaid || false
      if (!changeDate) {
        itm.isPaid = !paidStatus
      }
      if (itm.isPaid) {
        itm.paidDate = itm.paidDate || this.$moment().format('YYYY-MM-DD')
        itm.paidDate = this.$moment(itm.paidDate)
          .add(1, 'day')
          .toISOString()
      } else {
        itm.paidDate = null
      }
      await Services.UpdateExpenseAdmin(itm.id, itm)
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await Services.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        const itmId = parseInt(ev.target.attributes['itmId'].value)
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.id) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachmentObjectKey = data.fileName
        await Services.UpdateExpense(itmId, itm)
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    filterByUser(ev) {
      const userId = parseInt(ev.target.value)
      if (isNaN(userId)) {
        this.listItems = this.cachedData.listItems
        this.selectedUser = 'All'
      } else {
        this.listItems = this.listItems.filter((itm) => {
          return itm.user.id === userId
        })
        this.selectedUser = this.listItems[0].user.name.trim()
      }
    },
    filterByPaidStatus() {
      const type = this.formData.expenseProjPaid
      if (type === 'all') {
        this.listItems = this.cachedData.listItems
      } else {
        this.listItems = this.cachedData.listItems
        this.listItems = this.listItems.filter((itm) => {
          if (type === 'paid') {
            return itm.isPaid
          } else {
            return !itm.isPaid
          }
        })
      }
    },
    generateExcel() {
      const user = this.selectedUser?.toLowerCase().replace(/ /g, '-')
      const dt = `${this.formData.dates[0]}-${this.formData.dates[1]}`
      this.exportName = `expenses-report-${user}-${dt}.${this.exportType}`
      this.exportData = []
      this.listItems.forEach((itm, idx) => {
        this.exportData.push({
          no: idx + 1,
          name: itm.user.name,
          email: itm.user.email,
          approvalManager: itm.approvalManager,
          amount: this.currencySymbol(itm.currency) + itm.amount,
          category: this.categoryLabel(itm.category),
          gbpamount: parseFloat(String(this.calcAmount(itm, true)).substr(1)),
          projectCode: itm.projectCode,
          description: itm.description,
          date: itm.date.split('T')[0],
          paidDate: itm.paidDate ? itm.paidDate.split('T')[0] : 'NOT PAID'
        })
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  },
  watch: {
    async userFilter() {
      await this.getReports()
    }
  }
}
</script>

<style>
.exportCaption {
  font-weight: 600;
  color: #666;
  font-size: 10pt;
}
.lizard-form-radio-btn-2 {
  float: right;
  display: inline;
}
.lizard-col-w30 {
  width: 300px !important;
}
.lizard-form-date {
  width: 60px !important;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
.lizard-attachment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-attachment-list li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-remove-link {
  color: #c00;
  font-variant: small-caps;
  font-size: 10pt;
  font-weight: bold;
}
.lizard-remove-link:hover {
  color: #600;
  text-decoration: underline;
}
.lizard-total-text {
  color: black !important;
}
</style>
