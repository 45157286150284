<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <p><br />&nbsp;<managers-projects /></p>
      </div>
    </div>
  </div>
</template>

<script>
import ManagersProjects from './ManagersProjects.vue'

export default {
  name: 'ProjectsDashboard',
  components: { ManagersProjects },
  data() {
    return {}
  }
}
</script>
