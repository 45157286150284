<template>
  <div class="container-lg">
    <b-row align-v="end">
      <div class="col-sm-4">
        <ul class="lizard-calendar-controls text-left">
          <li title="Previous month">
            <b-icon
              icon="arrow-left-square"
              class="lizard-range-btn"
              scale="1.2"
              variant="primary"
              @click="prevMonth()"
            ></b-icon>
          </li>
          <li>
            <div
              class="text-center lizard-selected-range"
              title="Go to current date"
              @click="setCurrentDate"
            >
              {{ getMonthName() }} {{ getFullYear() }}
            </div>
          </li>
          <li title="Next month">
            <b-icon
              icon="arrow-right-square"
              class="lizard-range-btn"
              scale="1.2"
              variant="primary"
              @click="nextMonth()"
            ></b-icon>
          </li>
        </ul>
      </div>
      <div class="col-sm-5 text-center">
        <b-form-group class="lizard-calendar-view" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="selectedCalendarView"
            :options="calendarViewOptions"
            :aria-describedby="ariaDescribedby"
            name="radios-btn-default"
            button-variant="outline-info"
            buttons
            size="sm"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="col-sm-3">
        <ul class="lizard-color-guides-list text-right">
          <li><b-badge pill variant="success">Full hours recorded</b-badge></li>
          <li>
            <b-badge pill variant="warning">Partial hours recorded</b-badge>
          </li>
          <li><b-badge pill variant="danger">No hours recorded</b-badge></li>
        </ul>
      </div>
    </b-row>
    <hr />
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12" v-if="selectedCalendarView == 'monthly'">
        <div class="lizard-week lizard-week-header">
          <div
            class="lizard-day lizard-day-header"
            v-for="day in this.lizardCalendar.daysDictionary"
            :key="day"
          >
            {{ day }}
          </div>
        </div>
        <div
          class="lizard-week"
          v-for="week in lizardCalendar.totalWeeks"
          :key="week"
        >
          <div
            class="lizard-day"
            v-for="day in 7"
            :key="day"
            :class="{
              'lizard-today': isToday(getDay(week, day)),
              'lizard-holiday': isHoliday(getDay(week, day))
            }"
          >
            <div class="row">
              <div class="col-sm-12">
                {{ getDay(week, day) }}
              </div>
            </div>
            <div class="row" v-if="isHoliday(getDay(week, day)) == false">
              <div class="col-sm-12">
                <div
                  class="lizard-cards-holder"
                  v-if="readyToImport && getDateCards(getDay(week, day)).length"
                >
                  <div
                    class="lizard-card"
                    v-for="card in getDateCards(getDay(week, day)).slice(
                      0,
                      cardsPerDay
                    )"
                    :key="getDay(week, day) + '.' + card.id"
                    :class="card.flag"
                    :title="card.name + ' hours: ' + card.hours"
                  >
                    {{ getNameInitials(card) }}
                  </div>
                  <div
                    class="lizard-card more"
                    v-if="
                      getDateCards(getDay(week, day)).length >
                        (alwaysShowMoreBtn ? 0 : cardsPerDay)
                    "
                    @click="toggleDetailsModal(getDay(week, day))"
                    title="Click to see the full list"
                  >
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12" v-if="selectedCalendarView == 'weekly'">
        <div class="row">
          <div class="col-sm-1 lizard-week-controller" @click="prevWeek()">
            <div class="text-center">Previous Week</div>
          </div>
          <div class="col-sm lizard-week-days">
            <div class="lizard-week lizard-week-header">
              <div
                class="lizard-day lizard-day-header"
                v-for="wday in this.lizardCalendar.daysDictionary"
                :key="wday"
              >
                {{ wday }}
              </div>
            </div>
            <div class="lizard-week weekly-view">
              <div
                class="lizard-day"
                v-for="wday in 7"
                :key="wday"
                :class="{
                  'lizard-today': isToday(getDay(selectedCalendarWeek, wday)),
                  'lizard-holiday': isHoliday(
                    getDay(selectedCalendarWeek, wday)
                  )
                }"
              >
                <div class="row">
                  <div class="col-sm-12">
                    {{ getDay(selectedCalendarWeek, wday) }}
                  </div>
                </div>
                <div
                  class="row"
                  v-if="isHoliday(getDay(selectedCalendarWeek, wday)) == false"
                >
                  <div class="col-sm-12">
                    <div
                      class="lizard-cards-holder weekly-view"
                      v-if="
                        readyToImport &&
                          getDateCards(getDay(selectedCalendarWeek, wday))
                            .length
                      "
                    >
                      <div
                        class="lizard-card"
                        v-for="wcard in getDateCards(
                          getDay(selectedCalendarWeek, wday)
                        ).slice(0, cardsPerDay)"
                        :key="keyGenerator(wcard)"
                        :class="wcard.flag"
                        :title="wcard.name + ' hours: ' + wcard.hours"
                      >
                        {{ getNameInitials(wcard) }}
                      </div>
                      <div
                        class="lizard-card more"
                        v-if="
                          getDateCards(getDay(selectedCalendarWeek, wday))
                            .length > (alwaysShowMoreBtn ? 0 : cardsPerDay)
                        "
                        @click="
                          toggleDetailsModal(getDay(selectedCalendarWeek, wday))
                        "
                        title="Click to see the full list"
                      >
                        ...
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-1 lizard-week-controller" @click="nextWeek()">
            <div class="text-center">Next Week</div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-modal
      v-model="detailsModal.show"
      centered
      :title="detailsModal.data.title"
      :header-bg-variant="detailsModal.headerBgVariant"
      :header-text-variant="detailsModal.headerTextVariant"
      :body-bg-variant="detailsModal.bodyBgVariant"
      :body-text-variant="detailsModal.bodyTextVariant"
      :footer-bg-variant="detailsModal.footerBgVariant"
      :footer-text-variant="detailsModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col v-if="detailsModal.data.listItems.length">
            <b-table
              responsive
              striped
              bordered
              outlined
              fixed
              small
              hover
              class="lizard-activity-list"
              variant="secondary"
              caption-top
              id="activityTable"
              :items="detailsModal.data.listItems"
              :fields="detailsModal.data.listFields"
              :per-page="detailsModal.data.perPage"
              :current-page="detailsModal.data.currentPage"
            >
              <template #table-caption>List</template>
              <template #cell(index)="data">
                {{
                  data.index +
                    1 +
                    (detailsModal.data.currentPage - 1) *
                      detailsModal.data.perPage
                }}
              </template>
              <template #cell(name)="data">
                <h6>
                  <b-badge variant="danger" v-if="data.item.hours === 0">{{
                    data.item.name
                  }}</b-badge>
                  <b-badge
                    variant="warning"
                    v-else-if="data.item.hours < warningFlagThreshold"
                    >{{ data.item.name }}</b-badge
                  >
                  <b-badge
                    variant="success"
                    v-else-if="!showOnlyInactivities"
                    >{{ data.item.name }}</b-badge
                  >
                </h6>
              </template>
              <template #cell(hours)="data">
                <h6>
                  <b-badge variant="danger" v-if="data.item.hours === 0">{{
                    data.item.hours
                  }}</b-badge>
                  <b-badge
                    variant="warning"
                    v-else-if="data.item.hours < warningFlagThreshold"
                    >{{ data.item.hours }}</b-badge
                  >
                  <b-badge
                    variant="success"
                    v-else-if="!showOnlyInactivities"
                    >{{ data.item.hours }}</b-badge
                  >
                </h6>
              </template>
              <template #cell(projects)="data" v-if="showProjects">
                <b-badge
                  variant="secondary"
                  style="margin-left: 1px"
                  v-for="prj in data.item.projects[
                    detailsModal.data.selectedDate
                  ]"
                  :key="prj"
                  >{{ prj }}</b-badge
                >
              </template>
            </b-table>
            <b-pagination
              v-model="detailsModal.data.currentPage"
              :total-rows="totalModalTblRows"
              :per-page="detailsModal.data.perPage"
              aria-controls="activityTable"
              size="sm"
              pills
              align="center"
            ></b-pagination>
          </b-col>
          <b-col v-else>
            <strong>There isn't any records to show here!</strong>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="toggleDetailsModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Services from '../../../services/index.vue'

export default {
  name: 'ActivityCalendar',
  props: ['listItems'],
  data() {
    return {
      lizardCalendar: {
        selectedMonth: 1,
        selectedYear: '2022',
        startDay: null,
        firstDayOfMonth: null,
        lastDayOfMonth: null,
        totalDaysInMonth: null,
        totalWeeks: 0,
        daysDictionary: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
        firstDayOfWeek: 1
      },
      detailsModal: {
        show: false,
        headerBgVariant: 'primary',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          selectedDate: null,
          title: '',
          listFields: [
            {
              key: 'index',
              label: 'No',
              thClass: 'lizard-column-index',
              tdClass: 'lizard-column-index'
            },
            {
              key: 'name',
              label: 'Name',
              sortable: true
            },
            {
              key: 'hours',
              label: 'Hours',
              sortable: true,
              thClass: 'lizard-column-hours',
              tdClass: 'lizard-column-hours'
            }
          ],
          listItems: [],
          perPage: 10,
          currentPage: 1
        }
      },
      activityFlags: ['ok', 'warning', 'danger'],
      monthDays: {},
      readyToImport: false,
      selectedCalendarView: 'monthly',
      calendarViewOptions: [
        { text: 'Monthly view', value: 'monthly' },
        { text: 'Weekly view', value: 'weekly' }
      ],
      cardsPerDay: 5,
      selectedCalendarWeek: 1, // used for calendar weekly view
      alwaysShowMoreBtn: true,
      warningFlagThreshold: 8, // Hours
      showOnlyInactivities: false, // Set to False to show all activities
      showProjects: true
    }
  },
  computed: {
    totalModalTblRows() {
      return this.detailsModal.data.listItems.length
    }
  },
  mounted() {
    this.setCurrentDate()
    this.lizardCalendar.daysDictionary = this.lizardCalendar.daysDictionary
      .slice(this.lizardCalendar.firstDayOfWeek)
      .concat(
        this.lizardCalendar.daysDictionary.slice(
          0,
          this.lizardCalendar.firstDayOfWeek
        )
      )
    // console.log(JSON.stringify(this.lizardCalendar, null, '\t'))
    if (this.showProjects) {
      this.detailsModal.data.listFields.push({
        key: 'projects',
        label: 'Projects',
        thClass: 'lizard-column-projects',
        tdClass: 'lizard-column-projects'
      })
    }
    this.$root.$on('list-updated', this.getCalendarData)
  },
  methods: {
    setCurrentDate() {
      const now = new Date()
      const startOfMonth = new Date()
      startOfMonth.setDate(1)
      const weekNumberAtStart = this.$moment(startOfMonth).week()
      let weekNumberNow = this.$moment(now).week() - weekNumberAtStart
      weekNumberNow = weekNumberNow < 1 ? 1 : weekNumberNow
      this.selectedCalendarWeek = weekNumberNow
      this.lizardCalendar.selectedMonth = Number(now.getMonth())
      this.lizardCalendar.selectedYear = String(now.getFullYear())
      this.prepareCalendar()
    },
    prepareCalendar() {
      const dateFormat = 'YYYY-MM-DD'
      const date = new Date()
      date.setFullYear(this.lizardCalendar.selectedYear)
      date.setMonth(this.lizardCalendar.selectedMonth)
      date.setDate(1)
      this.lizardCalendar.startDay = Number(this.$moment(date).format('d')) || 7
      this.lizardCalendar.firstDayOfMonth = this.$moment(date)
        .startOf('month')
        .format(dateFormat)
      this.lizardCalendar.lastDayOfMonth = this.$moment(date)
        .endOf('month')
        .format(dateFormat)
      this.lizardCalendar.totalDaysInMonth = this.$moment(date).daysInMonth()
      // get remain days in first week
      const initialRemainDays =
        this.lizardCalendar.daysDictionary.length - this.lizardCalendar.startDay
      this.lizardCalendar.totalWeeks =
        initialRemainDays > 0
          ? Math.floor(
              (this.lizardCalendar.totalDaysInMonth + (7 - initialRemainDays)) /
                7
            )
          : Math.floor(this.lizardCalendar.totalDaysInMonth - initialRemainDays)
      // calculate remains weeks
      let remainsWeeks =
        (this.lizardCalendar.totalDaysInMonth + (7 - initialRemainDays)) / 7
      remainsWeeks = Math.round(
        ((remainsWeeks - parseInt(remainsWeeks)) * (100 / 7)) / remainsWeeks
      )
      this.lizardCalendar.totalWeeks += remainsWeeks > 1 ? 1 : remainsWeeks
      this.lizardCalendar.totalWeeks =
        this.lizardCalendar.totalWeeks > 6 ? 6 : this.lizardCalendar.totalWeeks
      this.monthDays = {}
      for (let i = 1; i <= this.lizardCalendar.totalDaysInMonth; i++) {
        date.setDate(i)
        this.monthDays[this.$moment(date).format('YYYY-MM-DD')] = {}
      }
      this.getCalendarData()
    },
    getDay(week, day) {
      const firstWeekEndDate =
        7 - (this.lizardCalendar.startDay - this.lizardCalendar.firstDayOfWeek)
      let date = 0
      if (week === 1) {
        day =
          day < this.lizardCalendar.startDay
            ? 0
            : day - this.lizardCalendar.startDay + 1
        date = day + (week - 1) * 7
      } else {
        date = day + ((week - 1) * 7 - (7 - firstWeekEndDate))
      }
      date = date > this.lizardCalendar.totalDaysInMonth ? '' : date
      return date || ''
    },
    getMonthName() {
      const date = new Date()
      date.setMonth(this.lizardCalendar.selectedMonth)
      return this.$moment(date).format('MMMM')
    },
    getFullYear() {
      return this.lizardCalendar.selectedYear
    },
    isToday(dt) {
      const date = new Date()
      date.setFullYear(this.lizardCalendar.selectedYear)
      date.setMonth(this.lizardCalendar.selectedMonth)
      date.setDate(dt)
      return !this.$moment().diff(this.$moment(date), 'day')
    },
    isHoliday(day) {
      if (!Number(day)) {
        return true
      }
      const date = new Date()
      date.setFullYear(this.lizardCalendar.selectedYear)
      date.setMonth(this.lizardCalendar.selectedMonth)
      date.setDate(day)
      return ['Sa', 'Su'].includes(this.$moment(date).format('dd'))
    },
    prevMonth(selectedWeek = 1) {
      this.lizardCalendar.selectedMonth--
      if (this.lizardCalendar.selectedMonth < 0) {
        this.lizardCalendar.selectedMonth = 11
        this.lizardCalendar.selectedYear--
      }
      this.selectedCalendarWeek = selectedWeek
      this.prepareCalendar()
    },
    nextMonth(selectedWeek = 1) {
      this.lizardCalendar.selectedMonth++
      if (this.lizardCalendar.selectedMonth > 11) {
        this.lizardCalendar.selectedMonth = 0
        this.lizardCalendar.selectedYear++
      }
      this.selectedCalendarWeek = selectedWeek
      this.prepareCalendar()
    },
    async toggleDetailsModal(dt) {
      this.detailsModal.data.listItems = []
      this.detailsModal.data.currentPage = 1
      if (!this.detailsModal.show) {
        const date = new Date()
        date.setFullYear(this.lizardCalendar.selectedYear)
        date.setMonth(this.lizardCalendar.selectedMonth)
        date.setDate(dt)
        this.detailsModal.data.title = this.$moment(date).format(
          'dddd, MMMM Do, YYYY'
        )
        this.detailsModal.data.selectedDate = this.$moment(date).format(
          'YYYY-MM-DD'
        )
        this.detailsModal.data.listItems = this.getDateCards(dt)
      } else {
        this.detailsModal.data.selectedDate = null
      }
      this.detailsModal.show = !this.detailsModal.show
    },
    async getCalendarData() {
      this.readyToImport = false
      const selectedDateRange = new Date()
      selectedDateRange.setFullYear(this.lizardCalendar.selectedYear)
      selectedDateRange.setMonth(this.lizardCalendar.selectedMonth)
      selectedDateRange.setDate(1)
      const monthItems = await this.getMonthItems()
      let listItems = {}
      this.listItems.forEach(
        ({ isCurrentEmployee, id, name, email, lastActivityEntry }) => {
          if (!isCurrentEmployee) {
            return
          }
          listItems[id] = listItems[id] || {
            id,
            flag: null,
            name,
            email,
            hours: 0,
            hoursByDate: {},
            projects: {}
          }
          let activityFlag =
            typeof lastActivityEntry === 'undefined'
              ? 2
              : this.$moment(lastActivityEntry).diff(
                  this.$moment(selectedDateRange),
                  'month',
                  true
                )
          activityFlag = activityFlag < 0 ? 2 : 0
          listItems[id].flag = this.activityFlags[activityFlag]
          if (listItems[id].flag === 'ok') {
            listItems[id].hoursByDate = monthItems[id]?.hours ?? {}
            listItems[id].projects = monthItems[id]?.projects ?? {}
          }
          this.pushToCalendar(listItems[id])
        }
      )
      this.readyToImport = true
    },
    pushToCalendar(obj = {}) {
      for (let dt in this.monthDays) {
        let flag = obj.flag
        flag =
          flag === 'danger'
            ? flag
            : typeof obj.hoursByDate[dt] === 'undefined'
            ? 'danger'
            : flag
        flag =
          flag === 'danger'
            ? flag
            : obj.hoursByDate[dt] < this.warningFlagThreshold
            ? 'warning'
            : flag
        const hours = flag !== 'danger' ? obj.hoursByDate[dt] : 0
        this.monthDays[dt][obj.id] = Object.assign({}, obj, {
          flag,
          hours,
          hoursByDate: {}
        })
      }
      for (let dt in this.monthDays) {
        this.monthDays[dt] = Object.values(this.monthDays[dt]).filter((itm) => {
          let cond = true
          if (this.showOnlyInactivities) {
            cond = itm.flag !== 'ok'
          }
          return cond && this.$moment(dt).diff(this.$moment(), 'day') < 0
        })
        this.monthDays[dt].sort((a, b) => {
          const aIndex = this.activityFlags.indexOf(a.flag)
          const bIndex = this.activityFlags.indexOf(b.flag)
          if (aIndex > bIndex) return -1
          if (aIndex < bIndex) return 1
          return 0
        })
      }
    },
    async getMonthItems() {
      const selectedDateRange = new Date()
      selectedDateRange.setFullYear(this.lizardCalendar.selectedYear)
      selectedDateRange.setMonth(this.lizardCalendar.selectedMonth)
      selectedDateRange.setDate(1)
      const from = this.$moment(selectedDateRange).format('YYYY-MM-DD')
      selectedDateRange.setDate(this.lizardCalendar.totalDaysInMonth)
      const to = this.$moment(selectedDateRange).format('YYYY-MM-DD')
      let listItems = {}
      const { status, data } = await Services.GetTimeLogs(true, {
        from,
        to
      })
      if (status !== 200 || !data.length) {
        return listItems
      }
      data.forEach(({ user, projectCode, date, hours }) => {
        if (!user.isCurrentEmployee) {
          return
        }
        listItems[user.id] = listItems[user.id] || {
          name: user.name,
          email: user.email,
          hours: {},
          totalHours: 0,
          projects: {}
        }
        const dt = this.$moment(date).format('YYYY-MM-DD')
        listItems[user.id].hours[dt] = listItems[user.id].hours[dt] || 0
        listItems[user.id].hours[dt] += hours
        listItems[user.id].totalHours += hours
        listItems[user.id].projects[dt] = listItems[user.id].projects[dt] || []
        if (!listItems[user.id].projects[dt].includes(projectCode)) {
          listItems[user.id].projects[dt].push(projectCode)
        }
      })
      return listItems
    },
    getDateCards(dt) {
      dt = dt < 1 ? 1 : dt
      let date = new Date()
      date.setFullYear(this.lizardCalendar.selectedYear)
      date.setMonth(this.lizardCalendar.selectedMonth)
      date.setDate(dt)
      date = this.$moment(date).format('YYYY-MM-DD')
      return this.monthDays[date] || []
    },
    getNameInitials({ name }) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
      let initials = [...name.matchAll(rgx)] || []
      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase()
      return initials
    },
    prevWeek() {
      if (this.selectedCalendarView === 'monthly') {
        return
      }
      if (this.selectedCalendarWeek > 1) {
        this.selectedCalendarWeek--
      } else {
        this.prevMonth(this.lizardCalendar.totalWeeks)
      }
    },
    nextWeek() {
      if (this.selectedCalendarView === 'monthly') {
        return
      }
      if (this.selectedCalendarWeek < this.lizardCalendar.totalWeeks) {
        this.selectedCalendarWeek++
      } else {
        this.nextMonth()
      }
    },
    keyGenerator(cardObj) {
      return `${cardObj.id}.${this.selectedCalendarWeek}.${Date.now()}`
    }
  },
  watch: {
    selectedCalendarView(viewType) {
      this.cardsPerDay = viewType === 'weekly' ? 19 : 5
    }
  }
}
</script>

<style scoped>
.lizard-color-guides-list {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: default;
}
.lizard-calendar-view {
  margin: 0;
  padding: 0;
}
.lizard-calendar-view #btn-radios-1 {
  width: 60% !important;
}
.lizard-calendar-controls {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-calendar-controls li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-calendar-controls li:nth-child(2) div {
  padding: 0 20px;
}
.lizard-range-btn {
  cursor: pointer;
}
.lizard-selected-range {
  font-size: 11pt;
  font-weight: bold;
  color: #007bff;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
}
.lizard-week-days {
  margin: 0 auto;
  padding: 0;
}
.lizard-week-controller {
  margin: 0;
  max-width: 30px;
  background-color: #eee;
  color: #007bff;
  font-size: 10pt;
  padding: 0;
  font-weight: bold;
  border: 1px solid #007bff;
}
.lizard-week-controller div {
  width: 100%;
  height: 100%;
  max-height: 398px;
  line-height: 28px;
  text-align: center;
  writing-mode: vertical-lr;
}
.lizard-week-controller:first-child {
  border-radius: 5px 0 0 5px;
  border-right-color: #fff;
}
.lizard-week-controller:last-child {
  border-radius: 0 5px 5px 0;
  border-left-color: #fff;
}
.lizard-week-controller:first-child div {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.lizard-week-controller:last-child div {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.lizard-week-controller:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}
.lizard-week {
  width: 100%;
  min-width: 100%;
  height: 100px;
  min-height: 100px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.lizard-week.weekly-view {
  height: 400px;
  min-height: 400px;
}
.lizard-week-header {
  height: 30px !important;
  min-height: 30px !important;
}
.lizard-day {
  width: calc(100% / 7);
  min-width: calc(100% / 7);
  height: 100px;
  min-height: 100px;
  margin: 0;
  padding: 10px 0 0 10px;
  overflow: hidden;
  border: 1px solid #fff;
  display: inline-block;
  background-color: #f6f6f6;
  font-size: 10pt;
  font-weight: bold;
  color: #999;
}
.weekly-view .lizard-day {
  height: 400px;
  min-height: 400px;
}
.lizard-day-header {
  padding: 0 !important;
  height: 30px !important;
  max-height: 30px !important;
  line-height: 30px;
  text-align: center;
  font-variant: small-caps;
  font-size: 14pt;
  font-weight: bold;
  background-color: #007bff;
  color: #eee;
}
.lizard-today {
  background-color: #ffc;
}
.lizard-holiday {
  background-color: #eee;
}
.lizard-cards-holder {
  width: 90%;
  margin: 0 auto;
  height: 60px;
  overflow: hidden;
}
.lizard-cards-holder.weekly-view {
  height: 340px;
}
.lizard-cards-holder div.lizard-card {
  width: 26px;
  height: 26px;
  line-height: 24px;
  font-size: 10pt;
  margin: 2px;
  padding: 1px;
  border-radius: 180px;
  display: inline-block;
  background-color: #28a745;
  color: #eee;
  text-align: center;
  text-transform: uppercase;
  cursor: default;
}
.lizard-cards-holder div.lizard-card.danger {
  background-color: #f33;
  color: #fff;
}
.lizard-cards-holder div.lizard-card.warning {
  background-color: #fc0;
  color: #666;
}
.lizard-cards-holder div.lizard-card.more {
  background-color: #ccc;
  color: #666;
}
.lizard-cards-holder div.lizard-card.more:hover {
  cursor: pointer;
}
</style>

<style>
.cv-header-day {
  font-size: 10pt !important;
  background-color: #007bff !important;
  color: #fff !important;
  font-weight: bold;
}
.cv-week {
  max-width: calc(113px * 7) !important;
}
.cv-weekdays {
  overflow-x: hidden !important;
}
.cv-day {
  font-size: 10pt !important;
  color: #999 !important;
  font-weight: bold;
  overflow-x: hidden !important;
}
.cv-item {
  font-size: 10pt !important;
  background-color: #999 !important;
  border: 2px solid #fff !important;
  border-radius: 10px !important;
  color: #fff !important;
  text-align: left !important;
  font-weight: bold;
}
.cv-item:hover {
  background-color: #007bff !important;
  cursor: pointer;
}
.today {
  background-color: #fafafa !important;
}
.outsideOfMonth {
  color: #eee !important;
  background-color: #eee !important;
}
.outsideOfMonth:hover {
  color: #eee !important;
  background-color: #eee !important;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-column-hours {
  width: 80px !important;
  text-align: center;
}
.lizard-column-projects {
  width: 170px !important;
  overflow: hidden;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
