<template>
  <div class="container-lg">
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-12 text-center">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          caption-top
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption>Projects List</template>
          <template #cell(index)="data">
            {{ data.index + 1 + (pager.currentPage - 1) * pager.perPage }}
          </template>
          <template #cell(budget)="data">
            <!-- £
            <input
              type="number"
              name="budget"
              class="lizard-hourly-rate-input"
              :id="data.item.id"
              :value="data.item.budget || '0'"
              @change="updateProject"
            /> -->
            <span v-if="data.item.budget">
              £{{ parseFloat(data.item.budget).toFixed(2) }}
            </span>
            <span v-else class="lizard-column-fees-disabled">
              £0.00
            </span>
          </template>
          <template #cell(totalFees)="data">
            <span v-if="data.item.totalFees">
              £{{ parseFloat(data.item.totalFees).toFixed(2) }}
            </span>
            <span v-else class="lizard-column-fees-disabled">
              £0.00
            </span>
          </template>
          <template #cell(createdDate)="data">
            <el-date-picker
              v-model="getItemDates"
              :itemId="data.item.id"
              type="daterange"
              value-format="yyyy-MM-DD"
              range-separator=""
              start-placeholder="Start date"
              end-placeholder="End date"
              style="width: 100%; font-size: 6pt"
              @change="updateProject"
            >
            </el-date-picker>
          </template>
          <!-- <template #cell(costDetails)="row">
            <b-button
              class="cost-details-btn"
              variant="link"
              size="sm"
              @click="row.toggleDetails"
              >{{ row.detailsShowing ? 'Hide' : 'Show' }} Cost Details</b-button
            >
          </template> -->
          <template #row-details="row">
            <b-table
              responsive
              striped
              bordered
              outlined
              fixed
              small
              hover
              class="lizard-activity-list"
              variant="info"
              :items="getRowItems(row.item)"
              :fields="rowFields"
            >
              <template #cell(budget)="row">
                £<input
                  type="number"
                  name="budget"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.budget"
                  @change="updateProject"
                />
              </template>
              <template #cell(total)="row">
                £<input
                  type="number"
                  name="total"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.total"
                  @change="updateProject"
                />
              </template>
              <!-- <template #cell(expertCallsFees)="row">
                £<input
                  type="number"
                  name="expertCallsFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.expertCallsFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(surveyFees)="row">
                £<input
                  type="number"
                  name="surveyFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.surveyFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(teamDinnerFees)="row">
                £<input
                  type="number"
                  name="teamDinnerFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.teamDinnerFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(vouchersFees)="row">
                £<input
                  type="number"
                  name="vouchersFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.vouchersFees"
                  @change="updateProject"
                />
              </template>
              <template #cell(otherFees)="row">
                £<input
                  type="number"
                  name="otherFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.otherFees"
                  @change="updateProject"
                />
              </template> -->
              <template #cell(totalFees)="row">
                £<input
                  type="number"
                  name="totalFees"
                  class="lizard-hourly-rate-input"
                  :id="row.item.id"
                  :value="row.item.totalFees"
                  @change="updateProject"
                />
              </template>
            </b-table>
          </template>
        </b-table>
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-7"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
      <div class="col-lg-2 text-left">
        <b-button variant="info" size="sm" @click="saveChanges"
          >Save Changes</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'Projects',
  data() {
    return {
      pager: {
        currentPage: 1,
        perPage: 15
      },
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-column-index',
          tdClass: 'lizard-column-index'
        },
        {
          key: 'projectCode',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-column-name',
          tdClass: 'lizard-column-name'
        },
        {
          key: 'clientName',
          label: 'Client',
          sortable: true,
          thClass: 'lizard-column-name',
          tdClass: 'lizard-column-name'
        },
        {
          key: 'partner',
          label: 'Partner',
          sortable: true,
          thClass: 'lizard-column-name',
          tdClass: 'lizard-column-name'
        },
        {
          key: 'createdDate',
          label: 'End Date'
        },
        {
          key: 'budget',
          label: 'Budget'
        },
        {
          key: 'total',
          label: 'Total Fees'
        }
        // {
        //   key: 'costDetails',
        //   label: ''
        // }
      ],
      listItems: [],
      rowFields: [
        {
          key: 'budget',
          label: 'Budget'
        },
        // {
        //   key: 'expertCallsFees',
        //   label: 'Expert Calls'
        // },
        // {
        //   key: 'surveyFees',
        //   label: 'Survey'
        // },
        // {
        //   key: 'teamDinnerFees',
        //   label: 'Team Dinner'
        // },
        // {
        //   key: 'vouchersFees',
        //   label: 'Vouchers'
        // },
        // {
        //   key: 'otherFees',
        //   label: 'Other'
        // },
        {
          key: 'totalFees',
          label: 'Total Fees'
        }
      ],
      exportExcel: false,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Projects Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        'Hourly Rate': 'hourlyRate'
      },
      exportData: []
    }
  },
  async mounted() {
    const Projects = await Services.GetCandesicProjects()
    this.listItems = Projects.data
  },
  methods: {
    parseDate(dt = null) {
      return !dt ? '' : this.$moment(dt).format('YYYY-MM-DD')
    },
    getDatePickerRange({ createdDate, endDate }) {
      return [this.parseDate(createdDate), this.parseDate(endDate)]
    },
    getRowItems(data = {}) {
      this.rowFields.forEach(({ key }) => {
        data[key] = isNaN(data[key]) ? 0 : data[key]
        data[key] = parseFloat(data[key])
      })
      // console.log(data)
      return [data]
    },
    getItemDates() {
      return ['2022-02-01', '2022-02-28']
    },
    async updateProject(ev) {
      console.log('id:', ev)
      // console.log('id:', ev.target.id)
      // console.log('name:', ev.target.name)
      // return
      // this.listItems = this.listItems.map((itm) => {
      //   if (itm.id === parseInt(ev.target.id)) {
      //     switch (ev.target.name) {
      //       case 'createdDate':
      //       case 'endDate':
      //         itm[ev.target.name] = this.$moment(ev.target.value).toISOString()
      //         break
      //       default:
      //         itm[ev.target.name] = parseFloat(ev.target.value)
      //     }
      //   }
      //   return itm
      // })
      // await this.saveChanges();
    },
    async saveChanges() {
      await Services.UpdateProjects(this.listItems)
    },
    generateExcel() {
      const dt = this.$moment().format('YYYY-MM-DD')
      this.exportName = `projects-list-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        return itm
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    }
  }
}
</script>

<style>
.cost-details-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
.lizard-column-fees {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.lizard-column-fees-disabled {
  color: #999;
}
</style>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  text-align: center !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.lizard-date-input {
  text-align: center;
  width: 90px;
  max-width: 90px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
