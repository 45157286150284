import Vue from "vue";
import VueRouter from 'vue-router'

import Home from './routes/Home.vue'
import HangFire from './routes/HangFire.vue'
import NotFound from './routes/NotFound.vue'

Vue.use(VueRouter);

const beforeEnter = function (to, from, next) {
  const { uri } = to.query;
  if (uri != null && uri != '/') {
      next(false);
      Router.push(uri);
  } else {
      next();
  }
}

const routes = [
  { path: '/', name: 'home', component: Home, beforeEnter },
  { path: '/hangfire', name: 'hangFire', component: HangFire, beforeEnter },
  { path: '*', name: 'NotFound', component: NotFound, beforeEnter },
];

const Router = new VueRouter({
  mode: 'history',
  routes,
})

export default Router;
