<template>
  <div>
    <div v-if="!loaded">
      <b-badge variant="warning">Please wait...</b-badge>
    </div>
    <div v-else>
      <b-table :fields="data.tbl.fields" :items="data.tbl.items">
        <template #cell(staffDetails)="row">
          <b-button
            class="cost-details-btn"
            variant="link"
            size="sm"
            @click="row.toggleDetails"
            >{{ row.detailsShowing ? 'Hide' : 'Show' }} Staff Details</b-button
          >
        </template>
        <template #row-details="row">
          <b-table
            responsive
            striped
            bordered
            outlined
            fixed
            small
            hover
            class="lizard-activity-list"
            variant="info"
            :items="getRowItems(row.item)"
            :fields="data.detailsTbl.fields"
          >
            <template #cell(budget)="row">
              £<input
                type="number"
                name="budget"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.budget"
                @change="updateProject"
              />
            </template>
            <template #cell(expertCallsFees)="row">
              £<input
                type="number"
                name="expertCallsFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.expertCallsFees"
                @change="updateProject"
              />
            </template>
            <template #cell(surveyFees)="row">
              £<input
                type="number"
                name="surveyFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.surveyFees"
                @change="updateProject"
              />
            </template>
            <template #cell(teamDinnerFees)="row">
              £<input
                type="number"
                name="teamDinnerFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.teamDinnerFees"
                @change="updateProject"
              />
            </template>
            <template #cell(vouchersFees)="row">
              £<input
                type="number"
                name="vouchersFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.vouchersFees"
                @change="updateProject"
              />
            </template>
            <template #cell(otherFees)="row">
              £<input
                type="number"
                name="otherFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.otherFees"
                @change="updateProject"
              />
            </template>
            <template #cell(totalFees)="row">
              £<input
                type="number"
                name="totalFees"
                class="lizard-hourly-rate-input"
                :id="row.item.id"
                :value="row.item.totalFees"
                @change="updateProject"
              />
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Services from '../../../services/index.vue'

export default {
  name: 'StaffCosts',
  props: ['selected'],
  data() {
    return {
      loaded: false,
      data: {
        tbl: {
          fields: [
            {
              key: 'year',
              label: 'Year',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'quarter',
              label: 'Quarter',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'month',
              label: 'Month',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectStartDate',
              label: 'Project Start Date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectEndDate',
              label: 'Project End Date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'activeProjectDates',
              label: 'No. of Active Project Dates',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'projectCode',
              label: 'Project Code',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'staffDetails',
              label: '',
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            }
          ],
          items: []
        },
        detailsTbl: {
          fields: [
            {
              key: 'consultantName',
              label: 'Consultant name',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'billableDays',
              label: 'Total billable days',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'billableHours',
              label: 'Total billable hours',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'actualHours',
              label: 'Total actual hours',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'consultantStartDate',
              label: 'Consultant start date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'consultantEndDate',
              label: 'Consultant end date',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'costPerDay',
              label: 'Cost per day',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'costPerHour',
              label: 'Cost per hour',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            },
            {
              key: 'totalCost',
              label: 'Total cost',
              sortable: true,
              thClass: 'lizard-column-fin',
              tdClass: 'lizard-column-fin'
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.Load()
  },
  // watch: {
  //   selected(selected = true) {
  //     if (!selected) {
  //       this.Reset()
  //     } else {
  //       this.Load()
  //     }
  //   }
  // },
  methods: {
    async Load() {
      const { status, data } = await this.GetData()
      const { data: staffRates } = await Services.GetUsers()
      const list = status === 200 ? this.PrepareData(data, staffRates) : []
      this.data.tbl.items = list
      console.log({ items: this.data.tbl.items })
      this.loaded = true
    },
    async GetData(
      startDate = this.GetDate('currentMonthStart'),
      endDate = this.GetDate('currentMonthEnd')
    ) {
      return await Services.GetStaffTimelog(startDate, endDate)
    },
    GetDate(tag) {
      const now = this.$moment()
      let result = null
      switch (tag) {
        case 'currentMonthStart':
          result = now.startOf('month')
          break
        case 'currentMonthEnd':
          result = now.endOf('month')
          break
      }
      return result.format('YYYY-MM-DD')
    },
    getRowItems(data = {}) {
      return [data]
    },
    PrepareData(data = [], rates) {
      const moment = this.$moment
      const list = []
      const staffRates = {}
      rates.forEach((rate) => {
        staffRates[rate.id] = {
          dayRate: rate.dayRate,
          hourlyRate: rate.hourlyRate
        }
      })
      data.forEach((prj) => {
        const itm = {
          year: [
            ...new Set([
              moment(prj.startDate).format('YYYY'),
              moment(prj.endDate).format('YYYY')
            ])
          ].join(' - '),
          month: [
            ...new Set([
              moment(prj.startDate).format('MMMM'),
              moment(prj.endDate).format('MMMM')
            ])
          ].join(' - '),
          quarter:
            'Q' +
            [
              ...new Set([
                moment(prj.startDate).quarter(),
                moment(prj.endDate).quarter()
              ])
            ],
          projectStartDate: moment(prj.startDate).format('DD/MM/YYYY'),
          projectEndDate: moment(prj.endDate).format('DD/MM/YYYY'),
          activeProjectDates: Math.ceil(
            moment(prj.endDate).diff(moment(prj.startDate), 'days', true)
          ),
          projectCode: prj.projectCode,
          projectName: null,
          partnerName: null
        }
        prj.staffTimelogSummaries.forEach((staff) => {
          const staffObj = {
            consultantName: staff.name,
            consultantStartDate: moment(staff.startDate).format('DD/MM/YYYY'),
            consultantEndDate: moment(staff.endDate).format('DD/MM/YYYY'),
            billableDays: Math.ceil(staff.hours / 8),
            billableHours: staff.hours,
            actualHours: Math.ceil(
              moment(staff.endDate).diff(
                moment(staff.startDate),
                'days',
                true
              ) * 8
            ),
            costPerDay: staffRates[staff.userId].dayRate,
            costPerHour: staffRates[staff.userId].hourlyRate,
            totalCost: 0
          }
          staffObj.totalCost = staffObj.costPerHour * staffObj.billableHours
          list.push(Object.assign({}, staffObj, itm))
        })
      })
      return list
    },
    Reset() {
      this.loaded = false
      this.data = {}
    }
  }
}
</script>

<style>
.lizard-column-fin {
  font-size: 7pt;
  font-weight: bold;
}
</style>
