<template>
  <div>
    <div v-if="!loaded">
      <b-badge variant="warning">Please wait...</b-badge>
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <b-col><charts :options="data.chartData1"/></b-col>
        </b-row>
        <b-row>
          <b-col><charts :options="data.chartData2"/></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Services from '../../../services/index.vue'

export default {
  name: 'dashboard',
  props: ['selected'],
  data() {
    return {
      loaded: false,
      data: {
        tbl: {
          fields1: [
            {
              key: 'projectCode',
              label: 'Project',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            },
            {
              key: 'budget',
              label: 'Budget',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            },
            {
              key: 'totalFees',
              label: 'Total cost',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            }
          ],
          fields2: [
            {
              key: 'projectCode',
              label: 'Project',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            },
            {
              key: 'totalActualHours',
              label: 'Sum of Total actual hours',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            },
            {
              key: 'totalBillableHours',
              label: 'Sum of Total billable hours',
              sortable: true,
              thClass: 'lizard-column-fin1',
              tdClass: 'lizard-column-fin1'
            }
          ],
          items: []
        },
        chartData1: {
          chart: {
            type: 'column'
          },
          title: {
            text: 'By Project'
          },
          subtitle: {
            text: '-'
          },
          xAxis: {
            categories: [],
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            }
          },
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Budget',
            data: []
          }, {
            name: 'Total cost',
            data: []
          }]
        },
        chartData2: {
          chart: {
            type: 'column'
          },
          title: {
            text: 'By Project'
          },
          subtitle: {
            text: '-'
          },
          xAxis: {
            categories: [],
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: ''
            }
          },
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0
            }
          },
          series: [{
            name: 'Sum of Total actual hours',
            data: []
          }, {
            name: 'Sum of Total billable hours',
            data: []
          }]
        }
      }
    }
  },
  mounted() {
    this.Load()
  },
  // watch: {
  //   selected(selected = true) {
  //     if (!selected) {
  //       this.Reset()
  //     } else {
  //       this.Load()
  //     }
  //   }
  // },
  methods: {
    async Load() {
      const { status, data } = await this.GetData()
      const list = status === 200 ? this.PrepareData(data) : []
      const result = []
      const prjsitr = await this.PrjItr(list, result)
      let PrjObj = await prjsitr.next()
      while (!PrjObj.done) {
        PrjObj = await prjsitr.next()
      }
      result.forEach(prj => {
        this.data.chartData1.xAxis.categories.push(prj.projectCode)
        this.data.chartData2.xAxis.categories.push(prj.projectCode)
        this.data.chartData1.series[0].data.push(prj.budget)
        this.data.chartData1.series[1].data.push(prj.totalFees)
        this.data.chartData2.series[0].data.push(prj.totalActualHours)
        this.data.chartData2.series[1].data.push(prj.totalBillableHours)
      })
      this.data.tbl.items = result
      console.log({ items: this.data.tbl.items })
      this.loaded = true
    },
    async PrjItr(List = [], FinalResult = []) {
      let itrs = 0
      return {
        next: async () => {
          if (itrs < List.length) {
            const Prj = List[itrs]
            if (typeof Prj === 'undefined') {
              return { done: true }
            }
            const {
              projectCode,
              budget,
              totalFees,
              team
            } = await this.GetProjectInfo(Prj.projectCode)
            const toPush = {
              projectCode,
              budget,
              totalFees,
              team,
              totalActualHours: 0,
              totalBillableHours: 0
            }
            team.forEach(({ actualHoursSpent, billableHoursSpent }) => {
              toPush.totalActualHours += actualHoursSpent
              toPush.totalBillableHours += billableHoursSpent
            })
            FinalResult.push(toPush)
            itrs++
            return { done: false }
          } else {
            return { done: true }
          }
        }
      }
    },
    async GetProjectInfo(projectCode) {
      const { data: costs } = await Services.GetProjectsInfo(projectCode)
      return costs
    },
    async GetData() {
      return await Services.GetCandesicProjects()
    },
    GetDate(tag) {
      const now = this.$moment()
      let result = null
      switch (tag) {
        case 'currentMonthStart':
          result = now.startOf('month')
          break
        case 'currentMonthEnd':
          result = now.endOf('month')
          break
      }
      return result.format('YYYY-MM-DD')
    },
    PrepareData(data = []) {
      return data.filter((project) => project.budget !== null)
    },
    Reset() {
      this.loaded = false
      // this.data = {}
    }
  }
}
</script>

<style>
.lizard-column-fin1 {
  font-size: 9pt;
  font-weight: bold;
}
</style>
