<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card content-class="mt-3" align="left">
            <b-tab active @click="selectTab('dashboard')">
              <template #title>
                <b-icon icon="bar-chart-steps" title="Sample"></b-icon>
                Dashboard
              </template>
              <dashboard :selected="isDashboardSelected" />
            </b-tab>
            <b-tab @click="selectTab('chartsData')">
              <template #title>
                <b-icon icon="diagram3" title="Sample"></b-icon>
                Chart Data
              </template>
              <charts-data :selected="isChartsDataSelected" />
            </b-tab>
            <b-tab @click="selectTab('staffCosts')">
              <template #title>
                <b-icon icon="person-lines-fill" title="Sample"></b-icon>
                Staff cost
              </template>
              <staff-costs :selected="isStaffCostsSelected" />
            </b-tab>
            <b-tab @click="selectTab('projectCosts')">
              <template #title>
                <b-icon icon="x-diamond" title="Sample"></b-icon>
                Other cost
              </template>
              <project-costs :selected="isProjectCostsSelected" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from './finance-components/dashboard.vue'
import ChartsData from './finance-components/charts-data.vue'
import StaffCosts from './finance-components/staff-costs.vue'
import ProjectCosts from './finance-components/project-costs.vue'

export default {
  name: 'FinanceReports',
  components: {
    Dashboard,
    StaffCosts,
    ProjectCosts,
    ChartsData
  },
  data() {
    return {
      selectedComponent: null
    }
  },
  computed: {
    isDashboardSelected() {
      return this.selectedComponent === 'dashboard'
    },
    isChartsDataSelected() {
      return this.selectedComponent === 'chartsData'
    },
    isStaffCostsSelected() {
      return this.selectedComponent === 'staffCosts'
    },
    isProjectCostsSelected() {
      return this.selectedComponent === 'projectCosts'
    }
  },
  mounted() {
    this.selectTab('dashboard')
  },
  methods: {
    selectTab(tab = null) {
      this.selectedComponent = tab
    }
  }
}
</script>
