<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Type</label></div>
    <div class="col-sm-7">
      <select
        name=""
        id=""
        class="form-control txt"
        v-model="formData.type"
        @change="selectType"
        :disabled="modifyFlag"
      >
        <option value="def">Select one...</option>
        <option v-for="type in formTypes" :key="type.code" :value="type.code">{{
          type.label
        }}</option>
      </select>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
export default {
  name: 'TypeSelector',
  props: ['generateForm', 'modifyFlag', 'formData', 'formLayouts'],
  data() {
    return {
      expenseFeatureIsActive: process.env.VUE_APP_FEATURE_EXPENSE == 'true',
      formTypes: [
        {
          code: 'prj',
          label: 'Project'
        },
        {
          code: 'bd',
          label: 'Business Development (BD)'
        },
        {
          code: 'hld',
          label: 'Holiday'
        },
        {
          code: 'exp',
          label: 'Expense'
        },
        {
          code: 'adm',
          label: 'Admin'
        },
        {
          code: 'trn',
          label: 'Training'
        }
      ]
    }
  },
  watch: {
    async generateForm(status) {
      if (status) {
        return await this.selectType(null, this.formData.type)
      }
      return
    }
  },
  mounted() {
    if (!this.expenseFeatureIsActive) {
      this.formTypes = this.formTypes.filter((type) => {
        return type.code !== 'exp'
      })
    }
  },
  methods: {
    async selectType(ev, val = null) {
      try {
        let key = ev?.target?.value ?? val
        if (!key) {
          return
        }
        let layoutsToShow = []
        switch (key) {
          case 'prj':
            layoutsToShow = ['Project', 'Date', 'Hours', 'DateTimeLogHistory']
            break
          case 'bd':
            layoutsToShow = ['BDProject', 'Date', 'Hours', 'DateTimeLogHistory']
            break
          case 'hld':
            layoutsToShow = ['Date', 'DateRange']
            break
          case 'exp':
            layoutsToShow = [
              'ExpenseProjType',
              'Date',
              'Categories',
              'Amount',
              'Receipt',
              'Manager'
            ]
            break
          default:
            layoutsToShow = ['Date', 'Hours']
        }
        layoutsToShow = layoutsToShow.concat(['Type', 'Notes'])
        layoutsToShow = layoutsToShow.map((layout) => `Frm${layout}`)
        for (let layout in this.formLayouts) {
          this.formLayouts[layout] = layoutsToShow.indexOf(layout) !== -1
        }
      } catch ({ message }) {
        this.resetForm(true)
        console.log({ exception: message })
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
