<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          hover
          class="lizard-activity-list"
          caption-top
          :small="small"
          :head-variant="headVariant"
          :foot-clone="footClone"
          :items="projectUsersCosts"
          :fields="listFields"
        >
          <template #cell(user)="data">
            {{ data.item.user }}
          </template>
          <template #cell(hours)="data">
            {{ data.item.hours }}
          </template>
          <template #cell(amount)="data">
            {{
              calcAmount({
                currency: 'GBP',
                amount: data.item.amount * data.item.hours
              })
            }}
          </template>
          <template #custom-foot="data">
            <b-tr>
              <b-th variant="warning" class="lizard-total-text">Total:</b-th>
              <b-th variant="warning" class="lizard-total-text text-center">{{
                calcTotal(data.items, 'hours')
              }}</b-th>
              <b-th variant="warning" class="lizard-total-text text-center">{{
                calcAmount({
                  currency: 'GBP',
                  amount: calcTotal(data.items, 'amount')
                })
              }}</b-th>
            </b-tr>
          </template>
        </b-table>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </div>
</template>

<script>
import Services from '../../services/index.vue'

export default {
  name: 'ProjectsReportsSummary',
  props: {
    parentItems: Array
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      showListItemsFilters: false,
      expenseProjPaidOptions: [
        { text: 'All', value: 'all' },
        { text: 'Paid', value: 'paid' },
        { text: 'Not Paid', value: 'npaid' }
      ],
      formData: {
        dates: null,
        expenseProjPaid: 'all'
      },
      small: true,
      headVariant: 'info',
      footClone: false,
      loadingFilter: false,
      userFilter: 'def',
      listFields: [
        // {
        //   key: 'index',
        //   label: 'No',
        //   thClass: 'lizard-col-w5 lizard-text-center',
        //   tdClass: 'lizard-col-w5 lizard-text-center'
        // },
        {
          key: 'user',
          label: 'User',
          sortable: true
        },
        {
          key: 'hours',
          label: 'Hours',
          thClass: 'lizard-text-center',
          tdClass: 'lizard-text-center',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Costs',
          thClass: 'lizard-text-center',
          tdClass: 'lizard-text-center',
          sortable: true
        }
      ],
      cachedData: {
        listItems: []
      },
      listItems: [],
      listDates: {},
      userList: [],
      usersExpenses: {},
      projectUsersCosts: [],
      userRates: {},
      selectedUser: 'All',
      userexpense: 'def',
      categories: {},
      gbpRates: { eur: 0, usd: 0 }
    }
  },
  async mounted() {
    this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
    await this.getUserRates()
    await this.getReports(this.parentItems)
  },
  methods: {
    async getReports(items) {
      items.forEach((itm) => {
        const key = this.projectUsersCosts.findIndex(
          (usr) => usr.user === itm.user.name
        )
        if (key === -1) {
          this.projectUsersCosts.push({
            user: itm.user.name,
            hours: itm.hours,
            amount: this.userRates[itm.user.id] || 0
          })
        } else {
          this.projectUsersCosts[key].hours += itm.hours
        }
      })
    },
    async getUserRates() {
      const { status, data } = await Services.GetUsers()
      if (status !== 200) {
        return
      }
      data.forEach((itm) => {
        this.userRates[itm.id] = itm.hourlyRate || 0
      })
      // console.log({ data: this.userRates })
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency?.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    calcAmount(
      { currency, amount },
      onlyRate = false,
      currencyConvert = false
    ) {
      let toGBP = ''
      if (currency !== 'GBP' && currencyConvert) {
        if (!onlyRate) {
          toGBP = ' (≈ '
        }
        let gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        if (!isFinite(gbpAmount)) {
          this.gbpRates = this.$cookies.get('__gbp-rates')
          gbpAmount = amount / this.gbpRates[currency.toLowerCase()]
        }
        toGBP += `£${parseFloat(gbpAmount).toFixed(2)}`
        if (!onlyRate) {
          toGBP += ')'
        }
      }
      return onlyRate && toGBP.length
        ? toGBP
        : `${this.currencySymbol(currency)}${parseFloat(amount).toFixed(
            2
          )}${toGBP}`
    },
    calcTotal(itms, key) {
      let total = 0
      itms.forEach((itm) => {
        total += parseFloat(itm[key])
      })
      return total
    },
    fomatDate(dt) {
      if (!dt) {
        return 'N/A'
      }
      return this.$moment(dt).format('YYYY-MM-DD')
    }
  }
}
</script>

<style>
.exportCaption {
  font-weight: 600;
  color: #666;
  font-size: 10pt;
}
.lizard-form-radio-btn-2 {
  float: right;
  display: inline;
}
.lizard-col-w30 {
  width: 300px !important;
}
.lizard-form-date {
  width: 60px !important;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 23% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.mandaysHeader {
  color: #666;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin: 20px !important;
}
.lizard-attachment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-attachment-list li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-remove-link {
  color: #c00;
  font-variant: small-caps;
  font-size: 10pt;
  font-weight: bold;
}
.lizard-remove-link:hover {
  color: #600;
  text-decoration: underline;
}
.lizard-total-text {
  color: black !important;
}
</style>
