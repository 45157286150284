<template>
  <div class="container-lg">
    <iframe :src="apiUrl" frameborder="0" width="100%" height="640px"></iframe>
  </div>
</template>

<script>
export default {
  name: 'HandFire',
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_BASE_URL + '/hangfire?access_token=' + this.accessToken
    },
    appName() {
      return this?.$store?.getters?.appName ?? 'default'
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? '0.0.0'
    },
    appEnv() {
      return (
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
      )
    },
    accessToken() {
      return this.$cookies.get("__api-token")
    }
  }
}
</script>

<style>
@font-face {
  font-family: Calibri;
  src: url('../assets/calibri-regular.ttf');
}
</style>

<style scoped>
.container-lg {
  background-color: rgba(189, 231, 250, 0.35) !important;
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
  min-height: 40vw !important;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  color: #f3f3f3;
  top: 12vw !important;
}
.header-msg img {
  height: 100px !important;
  margin: 0 -10px 18px 0;
}
.header-msg h1 {
  text-transform: capitalize;
  font-size: 100px !important;
  line-height: 4.5rem !important;
  letter-spacing: -0.1rem !important;
  margin: 0;
}
.strokeme {
  /* color: #62a7d4; */
  color: #aae0fa;
  text-shadow: -1px -1px 0 #4dcbf4, 1px -1px 0 #4dcbf4, -1px 1px 0 #4dcbf4,
    1px 1px 0 #4dcbf4;
}
</style>
