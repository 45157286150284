<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">
        <h6>
          <span>Please use the form below to record your activities.</span>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="formLayouts.FrmType">
      <div class="col-lg">
        <type-selector
          :generateForm="generateForm"
          :modifyFlag="modifyFlag"
          :formData="formData"
          :formLayouts="formLayouts"
        />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmExpenseProjType">
      <div class="col-lg">
        <expense-proj-type-selector
          :formData="formData"
          :formLayouts="formLayouts"
        />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmProject">
      <div class="col-lg">
        <project-selector :formData="formData" :dataCache="dataCache" />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmBDProject">
      <div class="col-lg">
        <BDProjectSelector :formData="formData" :dataCache="dataCache" />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmDate">
      <div class="col-lg">
        <date-selector
          :formData="formData"
          :dateRangeSelected="formLayouts.FrmDateRange"
          :TimeLogHistory="formLayouts.FrmDateTimeLogHistory"
        />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmCategories">
      <div class="col-lg">
        <categories-selector :formData="formData" :dataCache="dataCache" />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmAmount">
      <div class="col-lg">
        <amount-selector :formData="formData" />
      </div>
    </div>
    <div class="row" v-if="formLayouts.FrmHours">
      <div class="col-lg"><hours-selector :formData="formData" /></div>
    </div>
    <div class="row" v-if="formLayouts.FrmReceipt">
      <div class="col-lg"><attachment-selector :formData="formData" /></div>
    </div>
    <div class="row" v-if="formLayouts.FrmManager">
      <div class="col-lg"><manager-selector :formData="formData" /></div>
    </div>
    <div class="row" v-if="formLayouts.FrmNotes">
      <div class="col-lg"><notes-selector :formData="formData" /></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-2"></div>
      <div class="col-sm-7 text-right">
        <b-button
          size="sm"
          title="Add and clear the form."
          variant="info"
          v-if="!modifyFlag"
          @click="addEntry"
          >Add</b-button
        >&nbsp;
        <b-button
          size="sm"
          title="Add and keep the form values."
          variant="info"
          v-if="!modifyFlag"
          data-keep="true"
          @click="addEntry"
          >Keep & Add</b-button
        >
        <b-button
          size="sm"
          variant="secondary"
          v-if="modifyFlag"
          @click="resetForm"
          style="margin-right: 5px"
          >Cancel</b-button
        >
        <b-button
          size="sm"
          variant="info"
          v-if="modifyFlag"
          @click="modifyEntry"
          >Update</b-button
        >
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<style>
.no-activity-day div span {
  background-color: rgb(236, 143, 143) !important;
  color: #fff !important;
}
.half-activity-day div span {
  background-color: #ccc !important;
  color: #333 !important;
}
.full-activity-day div span {
  background-color: rgb(157, 247, 157) !important;
  color: #333 !important;
}
.holiday-activity-day div span {
  background-color: #eee !important;
}
</style>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>

<script>
import Services from '../services/index.vue'
import TypeSelector from './form-components/TypeSelector.vue'
import ProjectSelector from './form-components/ProjectSelector.vue'
import BDProjectSelector from './form-components/BDProjectSelector.vue'
import DateSelector from './form-components/DateSelector.vue'
import HoursSelector from './form-components/HoursSelector.vue'
import NotesSelector from './form-components/NotesSelector.vue'
import ExpenseProjTypeSelector from './form-components/ExpenseProjTypeSelector.vue'
import CategoriesSelector from './form-components/CategoriesSelector.vue'
import AmountSelector from './form-components/AmountSelector.vue'
import AttachmentSelector from './form-components/AttachmentSelector.vue'
import ManagerSelector from './form-components/ManagerSelector.vue'

export default {
  name: 'ActivityForm',
  props: ['items'],
  components: {
    TypeSelector,
    ExpenseProjTypeSelector,
    CategoriesSelector,
    ProjectSelector,
    BDProjectSelector,
    DateSelector,
    HoursSelector,
    NotesSelector,
    AmountSelector,
    AttachmentSelector,
    ManagerSelector
  },
  data() {
    return {
      generateForm: false,
      modifyFlag: false,
      modifyList: null,
      modifyListIndex: -1,
      dateRangeSelected: false,
      dataCache: {
        projects: [],
        bdprojects: [],
        categories: []
      },
      formLayouts: {
        FrmType: true,
        FrmExpenseProjType: false,
        FrmProject: false,
        FrmBDProject: false,
        FrmDate: false,
        FrmDateRange: false,
        FrmDateTimeLogHistory: false,
        FrmCategories: false,
        FrmAmount: false,
        FrmReceipt: false,
        FrmManager: false,
        FrmHours: false,
        FrmNotes: true
      },
      formData: {
        type: 'def',
        project: 'def',
        dates: null,
        expenseProjType: 'prj',
        category: 'def',
        currency: 'gbp',
        amount: 10,
        holiday: 'full',
        duration: '1',
        approvalmanager: null,
        notes: null,
        file: null
      }
    }
  },
  async mounted() {
    this.modifyFlag = false
    this.$root.$on('entryModifyRequest', this.prepareModifyEntry)
  },
  methods: {
    async getTimelogsHours(date) {
      const { status, data } = await Services.GetCurrentUserTimeLogs(true, {
        from: date,
        to: date
      })
      if (status !== 200) {
        return []
      }
      let totalHours = 0
      data.forEach((itm) => {
        totalHours += itm.hours
      })
      return totalHours
    },
    selectType(ev, val = null) {
      try {
        let key = ev?.target?.value ?? val
        if (!key) {
          return
        }
        this.generateForm = true
      } catch (Exception) {
        this.resetForm(true)
        console.log({ Exception: Exception.message })
      }
    },
    resetForm(force = false) {
      this.modifyFlag = false
      this.modifyList = null
      this.modifyListIndex = -1
      this.formData = {
        type: 'def',
        project: 'def',
        dates: null,
        expenseProjType: 'prj',
        category: 'def',
        currency: 'gbp',
        amount: 10,
        holiday: 'full',
        duration: '1',
        approvalmanager: null,
        notes: null,
        file: null
      }
      for (let key in this.formLayouts) {
        this.formLayouts[key] = false
      }
      this.formLayouts.FrmType = true
      this.formLayouts.FrmNotes = true
      this.generateForm = false
      if (force) {
        for (let key in this.dataCache) {
          this.dataCache[key] = []
        }
      }
    },
    async addEntry(ev) {
      const { keep } = ev.target.dataset
      let isValid = true
      let errormsg = []
      let newEntry = {}
      const typesMap = {
        def: null,
        prj: 'project',
        bd: 'business development',
        hld: 'holiday',
        exp: 'expense',
        adm: 'admin',
        trn: 'training'
      }
      for (let key in this.formData) {
        let val = this.formData[key]
        let valType = Object.prototype.toString.call(val)
        valType = valType.substr(valType.indexOf(' ') + 1, 3).toLowerCase()
        switch (key) {
          case 'type':
            newEntry[key] = typesMap[val]
            break
          case 'project':
            newEntry[key] = val === 'def' ? null : val
            break
          case 'dates':
            if (valType !== 'arr') {
              val = [val]
            }
            newEntry[key] = val
            break
          case 'duration':
            if (parseFloat(val) > 18) {
              isValid = false
              errormsg.push(
                'Maximum allowed hours to be added per day are 18 hours.'
              )
            }
            newEntry[key] = parseFloat(val)
            break
          default:
            newEntry[key] = val
        }
      }
      if (newEntry.type === 'expense') {
        isValid =
          isValid && newEntry.file && typeof newEntry.file !== 'undefined'
        if (!isValid) {
          errormsg.push(`you have to attach the expense bill`)
        }
        newEntry['ProjectCode'] = newEntry?.project ?? null
        newEntry['Description'] = newEntry?.notes ?? null
        newEntry['AttachmentObjectKey'] = newEntry?.file ?? null
      }
      isValid =
        isValid &&
        Object.values(typesMap)
          .slice(1)
          .includes(newEntry.type)
      if (!isValid) errormsg.push(`type "${newEntry.type}" is not valid`)
      if (
        ['project', 'business development', 'expense'].includes(newEntry.type)
      ) {
        isValid = isValid && newEntry.project !== null
        if (!isValid) errormsg.push(`selected project is not valid`)
      }
      newEntry.dates = newEntry.dates.filter((dt) => dt !== null)
      isValid = isValid && newEntry.dates.length
      if (!isValid) errormsg.push(`selected date is not valid`)
      if (isValid && newEntry.type === 'holiday') {
        isValid = ['full', 'half'].indexOf(newEntry.holiday) !== -1
        if (!isValid) errormsg.push(`selected holiday type is not valid`)
        const diff =
          this.$moment(newEntry.dates[1]).diff(
            this.$moment(newEntry.dates[0]),
            'days'
          ) + 1
        newEntry.duration = this.addWeekdays(newEntry.dates[1], diff)
        if (newEntry.duration < 0) {
          newEntry.duration = 1
        }
      }
      isValid = isValid && newEntry.duration > 0
      if (!isValid) errormsg.push(`selected dates are not valid`)
      if (isValid && newEntry.type === 'expense') {
        isValid = isValid && newEntry.category !== 'def'
        if (!isValid) errormsg.push(`selected category are not valid`)
      }
      if (['project', 'business development'].includes(newEntry.type)) {
        const hours = await this.getTimelogsHours(newEntry.dates[0])
        isValid = isValid && hours + newEntry.duration <= 18
        if (!isValid) {
          errormsg.push(
            'Maximum allowed hours to be added per day are 18 hours.'
          )
        }
      }
      if (!isValid) {
        return this.sendErrNotif('Error: ' + errormsg[0])
      }
      newEntry = this.ObjFilter(newEntry, (val) => val !== null)
      if (this.isDuplicated(newEntry)) {
        return this.sendErrNotif('Record already exists.')
      }
      if (newEntry.type === 'holiday') {
        this.items.holidayItems.push(newEntry)
      } else if (newEntry.type === 'expense') {
        this.items.expenseItems.push(newEntry)
      } else {
        this.items.listItems.push(newEntry)
      }
      this.$root.$emit('newEntry')
      if (typeof keep === 'undefined') {
        this.resetForm()
      } else {
        if (newEntry.type === 'expense') {
          this.formLayouts.FrmReceipt = false
          this.formData.file = null
          setTimeout(() => {
            this.formLayouts.FrmReceipt = true
          }, 500);
        } else if (['admin', 'training'].indexOf(newEntry.type) !== -1) {
          this.formData.project = 'def'
        }
      }
    },
    modifyEntry() {
      try {
        let isValid = true
        let newEntry = this.items[this.modifyList][this.modifyListIndex]
        const typesMap = {
          def: null,
          prj: 'project',
          bd: 'business development',
          hld: 'holiday',
          exp: 'expenses',
          adm: 'admin',
          trn: 'training'
        }
        for (let key in this.formData) {
          let val = this.formData[key]
          let valType = Object.prototype.toString.call(val)
          valType = valType.substr(valType.indexOf(' ') + 1, 3).toLowerCase()
          switch (key) {
            case 'project':
              newEntry[key] = val === 'def' ? null : val
              break
            case 'dates':
              if (valType !== 'arr') {
                val = [val]
              }
              newEntry[key] = val
              break
            case 'duration':
              newEntry[key] = parseFloat(val)
              break
            default:
              newEntry[key] = this.items[this.modifyList][this.modifyListIndex][
                key
              ]
          }
        }
        isValid =
          isValid &&
          Object.values(typesMap)
            .slice(1)
            .includes(newEntry.type)
        if (['project', 'business development'].indexOf(newEntry.type) !== -1) {
          isValid = isValid && newEntry.project !== null
        }
        isValid = isValid && newEntry.dates !== 'Invalid date'
        if (isValid && newEntry.type === 'holiday') {
          isValid = ['full', 'half'].indexOf(newEntry.holiday) !== -1
          const diff =
            this.$moment(newEntry.dates[1]).diff(
              this.$moment(newEntry.dates[0]),
              'days'
            ) + 1
          newEntry.duration = this.addWeekdays(newEntry.dates[1], diff)
        }
        isValid = isValid && newEntry.duration > 0

        if (!isValid) {
          return this.sendErrNotif()
        }
        newEntry = this.ObjFilter(newEntry, (val) => val !== null)
        this.items[this.modifyList][this.modifyListIndex] = newEntry
        this.$root.$emit('newEntry')
        this.resetForm()
      } catch (Exception) {
        this.resetForm(true)
      }
    },
    isDuplicated(newEntry) {
      const { type, project, duration, dates, holiday } = newEntry
      const listToCheck =
        type === 'holiday' ? this.items.holidayItems : this.items.listItems
      const uniqueValues = new Set(
        listToCheck.map(function({ type, project, duration, dates, holiday }) {
          return JSON.stringify({
            type,
            project,
            duration,
            dates: dates.join(','),
            holiday
          })
        })
      )
      return uniqueValues.has(
        JSON.stringify({
          type,
          project,
          duration,
          dates: dates.join(','),
          holiday
        })
      )
    },
    async prepareModifyEntry(list, id) {
      const record = this.items[list][id]
      const typesHasProject = ['prj', 'bd', 'exp']
      const typesMap = {
        project: 'prj',
        'business development': 'bd',
        holiday: 'hld',
        expense: 'exp',
        admin: 'adm',
        training: 'trn'
      }
      this.formData = {
        type: typesMap[record.type],
        holiday: record.holiday,
        duration: record.duration,
        notes: record.notes
      }
      this.selectType(null, this.formData.type)
      this.holidaySelected = this.formData.type === 'hld'
      this.showProjects = this.formData.type === 'prj'
      this.showBdProjects = this.formData.type === 'bd'
      this.formData.dates = this.holidaySelected
        ? record.dates
        : record.dates[0]
      this.formData.project = typesHasProject.includes(this.formData.type)
        ? record.project
        : 'def'
      this.modifyFlag = true
      this.modifyList = list
      this.modifyListIndex = id
    },
    sendErrNotif(msg = 'Please check entries and try again.') {
      this.$toasted.error(msg).goAway(3500)
    },
    addWeekdays(date, days) {
      date = this.$moment(date)
      let total = days
      while (days > 0) {
        date = date.add(1, 'days')
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1
        } else {
          total--
        }
      }
      return total
    },
    ObjFilter(obj = {}, predicate) {
      return Object.assign(
        ...Object.keys(obj)
          .filter((key) => predicate(obj[key]))
          .map((key) => ({ [key]: obj[key] }))
      )
    }
  }
}
</script>
