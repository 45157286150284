<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-8 text-left header-msg">
        <h3>
          <span>My Holidays</span>
        </h3>
      </div>
      <div class="col-sm-2 text-right">
        <b-button
          variant="outline-info"
          size="sm"
          class="lizard-view-link"
          v-if="listItems && listItems.length"
          @click="toggleViewType"
        >
          <span v-if="viewType === 'list'">Calendar</span>
          <span v-if="viewType === 'calendar'">List</span> view
        </b-button>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems && listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          v-if="viewType === 'list'"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-link @click="doRemove(data.value.id)"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
          </template>
        </b-table>
        <b-calendar
          v-if="viewType === 'calendar'"
          v-model="calDate"
          @context="onContext"
          :date-info-fn="dateClass"
          block
          hide-header
          locale="en-US"
          variant="secondary"
        ></b-calendar>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems && listItems.length && viewType === 'calendar'">
      <div class="col-lg text-center lizard-help-list">
        <ul>
          <li class="half-activity-calday">Half Holiday</li>
          <li class="full-activity-calday">Full Holiday</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-overlay no-wrap :show="deleteInProgress">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Are you sure?</strong></p>
          <div class="d-flex">
            <b-button variant="outline-danger" class="mr-3" @click="onCancel">
              Cancel
            </b-button>
            <b-button variant="outline-info" @click="onConfirm"
              >Confirm</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Services from "../../services/index.vue";

export default {
  name: "HolidayLogs",
  data() {
    return {
      viewType: "list",
      deleteInProgress: false,
      deleteItemId: null,
      listFields: [
        {
          key: "index",
          label: "No",
          thClass: "lizard-activity-list-index",
          tdClass: "lizard-activity-list-index",
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
          thClass: "lizard-activity-list-type",
          tdClass: "lizard-activity-list-type",
        },
        {
          key: "from",
          label: "From Date",
          sortable: true,
          thClass: "lizard-activity-list-date",
          tdClass: "lizard-activity-list-date",
        },
        {
          key: "to",
          label: "To Date",
          sortable: true,
          thClass: "lizard-activity-list-date",
          tdClass: "lizard-activity-list-date",
        },
        {
          key: "notes",
          label: "Notes",
          thClass: "lizard-activity-list-notes",
          tdClass: "lizard-activity-list-notes",
        },
        {
          key: "action",
          label: "Actions",
          thClass: "lizard-activity-list-action",
          tdClass: "lizard-activity-list-action",
        },
      ],
      listItems: [],
      calendarItems: {},
      calDate: "",
      context: null,
    };
  },
  async mounted() {
    const now = new Date();
    this.calDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.listItems = await this.GetList();
    this.$root.$on("saved", async () => {
      this.listItems = await this.GetList();
    });
  },
  methods: {
    async GetList() {
      let { status, data } = await Services.GetHolidays();
      if (status === 200) {
        return this.structurizeDataSchema(data);
      } else {
        return [];
      }
    },
    structurizeDataSchema(data = []) {
      data = data.map((itm) => {
        itm.type = itm.isFullDay ? "Full Day" : "Half Day";
        itm.from = itm.fromDate.split("T")[0];
        itm.to = itm.toDate.split("T")[0];
        itm.action = {
          id: itm.id,
        };
        let diff = this.$moment(itm.toDate).diff(this.$moment(itm.fromDate), 'days') + 1;
        let hlDate = itm.fromDate;
        while (diff > 0) {
          let dt = this.$moment(hlDate);
          this.calendarItems[dt.format('YYYY-MM-DD')] = itm.isFullDay;
          hlDate = dt.add(1, "days");
          diff--;
        }
        return itm;
      });
      return data;
    },
    async doRemove(id) {
      this.deleteInProgress = true;
      this.deleteItemId = id;
    },
    onCancel() {
      this.deleteInProgress = false;
      this.deleteItemId = null;
    },
    async onConfirm() {
      this.deleteInProgress = false;
      if (!this.deleteItemId) {
        return;
      }
      let { status } = await Services.RemoveHoliday(this.deleteItemId);
      if (status === 200) {
        this.listItems = this.listItems.filter((itm) => {
          return itm.id !== this.deleteItemId;
        });
      }
      this.deleteItemId = null;
      this.deleteInProgress = false;
    },
    toggleViewType() {
      this.viewType = this.viewType === "list" ? "calendar" : "list";
    },
    onContext(ctx) {
      this.context = ctx;
    },
    dateClass(ymd, date) {
      let className = "";
      const dt = this.$moment(date).format("YYYY-MM-DD");
      if (typeof this.calendarItems[dt] !== 'undefined') {
        className = this.calendarItems[dt] ? 'full-activity-calday' : 'half-activity-calday';
      }
      return className;
    },
  },
};
</script>

<style>
.lizard-help-list ul {
  list-style: none;
  display: inline-block;
  margin: 10px 0;
  padding: 0;
}
.lizard-help-list ul li {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
}
.no-activity-calday {
  background-color: rgb(236, 143, 143) !important;
  color: #fff !important;
}
.half-activity-calday {
  background-color: #ccc !important;
  color: #333 !important;
}
.full-activity-calday {
  background-color: rgb(157, 247, 157) !important;
  color: #333 !important;
}
</style>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 30% !important;
}
.lizard-activity-list-date {
  width: 12% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 35% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-view-link {
  font-weight: bold;
}
.lizard-today-btn {
  margin: 10px;
  float: right;
}
</style>
