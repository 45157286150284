<template>
  <div class="container-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <b-card no-body>
          <b-tabs pills card content-class="mt-3" align="left">
            <b-tab active>
              <template #title>
                <b-icon
                  v-if="!loadingDateTab"
                  icon="grid"
                  title="Summary"
                ></b-icon
                ><b-spinner
                  v-if="loadingDateTab"
                  type="border"
                  small
                ></b-spinner>
                Summary
              </template>
              <expense-reports-summary />
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon
                  v-if="!loadingDateTab"
                  icon="calendar3"
                  title="By Date"
                ></b-icon
                ><b-spinner
                  v-if="loadingDateTab"
                  type="border"
                  small
                ></b-spinner>
                By Date
              </template>
              <expense-reports-by-date />
            </b-tab>
            <b-tab>
              <template #title>
                <b-icon
                  v-if="!loadingProjectTab"
                  icon="briefcase"
                  title="By Project"
                ></b-icon
                ><b-spinner
                  v-if="loadingProjectTab"
                  type="border"
                  small
                ></b-spinner>
                By Project
              </template>
              <expense-reports-by-project />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ExpenseReportsByDate from "./ExpenseReportsByDate.vue";
import ExpenseReportsByProject from "./ExpenseReportsByProject.vue";
import ExpenseReportsSummary from "./ExpenseReportsSummary.vue";

export default {
  name: "ExpenseReports",
  components: {
    ExpenseReportsByDate,
    ExpenseReportsByProject,
    ExpenseReportsSummary,
  },
  data() {
    return {
      loadingDateTab: false,
      loadingProjectTab: false,
    };
  }
};
</script>
