<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Expense</label></div>
    <div class="col-sm-7">
      <b-form-radio-group
        v-model="formData.expenseProjType"
        :options="expenseProjTypeOptions"
        @change="showProjectSelectors"
        name="radio-inline"
        buttons
        button-variant="outline-info"
        class="lizard-form-date"
      ></b-form-radio-group>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
export default {
  name: 'expenseProjTypeSelector',
  props: ['formData', 'formLayouts'],
  data() {
    return {
      expenseProjTypeOptions: [
        { text: 'For a Project', value: 'prj' },
        { text: 'For a BD', value: 'bd' }
      ]
    }
  },
  mounted() {
    this.showProjectSelectors()
  },
  methods: {
    showProjectSelectors() {
      this.formLayouts.FrmProject = this.formData.expenseProjType === 'prj'
      this.formLayouts.FrmBDProject = this.formData.expenseProjType === 'bd'
    }
  }
}
</script>

<style>
label.btn-outline-info {
  padding: 0 !important;
  line-height: 30px !important;
}
</style>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
