<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-12 text-left header-msg">
        <h6>
          <span v-if="!inprocess">Queued List</span>
          <span v-if="inprocess">Saving...</span>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row"
      v-if="!listItems.length && !holidayItems.length && !expenseItems.length"
    >
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >Please use entry form to add new items here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div
      class="row"
      v-if="!listItems.length && !holidayItems.length && !expenseItems.length"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary">List</b-badge>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="info"
          :items="listItems"
          :fields="listFields"
        >
          <template #table-caption
            >Please remember you have to submit these records!</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="data.value.status === 'queued' && showActions.list.modify"
              @click="modify(data.value.id)"
              ><b-icon icon="pencil-fill" variant="info" title="Edit"></b-icon
            ></b-link>
            <b-link
              v-if="data.value.status === 'queued' && showActions.list.remove"
              @click="remove(data.value.id)"
            >
              <b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon></b-link
            ><b-icon
              v-if="data.value.status === 'pending'"
              icon="hourglass-split"
              variant="info"
              title="Pending"
            ></b-icon
            ><b-icon
              v-if="data.value.status === 'done'"
              icon="check-circle-fill"
              variant="success"
              title="Saved"
            ></b-icon></template
        ></b-table>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary">Expenses</b-badge>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="info"
          :items="expenseItems"
          :fields="expenseFields"
        >
          <template #table-caption
            >Please remember you have to submit these records!</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(amount)="data">
            {{ currencySymbol(data.item.currency)
            }}{{ parseFloat(data.item.amount).toFixed(2) }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.expense.modify
              "
              @click="modify(data.value.id, 'expense')"
              ><b-icon icon="pencil-fill" variant="info" title="Edit"></b-icon
            ></b-link>
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.expense.remove
              "
              @click="remove(data.value.id, 'expense')"
            >
              <b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon></b-link
            ><b-icon
              v-if="data.value.status === 'pending'"
              icon="hourglass-split"
              variant="info"
              title="Pending"
            ></b-icon
            ><b-icon
              v-if="data.value.status === 'done'"
              icon="check-circle-fill"
              variant="success"
              title="Saved"
            ></b-icon></template
        ></b-table>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary">Holidays</b-badge>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="holidayItems"
          :fields="holidayFields"
        >
          <template #table-caption
            >Please remember you have to submit these records!</template
          >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.holiday.modify
              "
              @click="modify(data.value.id, 'holiday')"
              ><b-icon icon="pencil-fill" variant="info" title="Edit"></b-icon
            ></b-link>
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.holiday.remove
              "
              @click="remove(data.value.id, 'holiday')"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
            <b-icon
              v-if="data.value.status === 'pending'"
              icon="hourglass-split"
              variant="info"
              title="Pending"
            ></b-icon>
            <b-icon
              v-if="data.value.status === 'done'"
              icon="check-circle-fill"
              variant="success"
              title="Saved"
            ></b-icon>
          </template>
        </b-table>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row"
      v-if="listItems.length || holidayItems.length || expenseItems.length"
    >
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-right">
        <b-button
          size="sm"
          v-if="!inprocess"
          variant="info"
          @click="submitEntries"
          >Submit</b-button
        >
        <b-button
          size="sm"
          disabled
          v-if="inprocess"
          variant="outline-secondary"
          >Please wait...</b-button
        >
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div
      class="row"
      v-if="listItems.length || holidayItems.length || expenseItems.length"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<style scoped>
.lizard-activity-list-action a {
  margin: 0 3px;
}
</style>

<style>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 20% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-amount {
  width: 15% !important;
  text-align: center !important;
}
.lizard-activity-list-action {
  max-width: 15% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
</style>

<script>
import Services from '../services/index.vue'

export default {
  name: 'ActivityList',
  props: ['items'],
  data() {
    return {
      inprocess: false,
      showList: false,
      showActions: {
        list: { modify: true, remove: true },
        holiday: { modify: true, remove: true },
        expense: { modify: false, remove: true }
      },
      listItems: [],
      expenseItems: [],
      holidayItems: [],
      listFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'project',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'duration',
          label: 'Hours',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'lizard-activity-list-action',
          tdClass: 'lizard-activity-list-action'
        }
      ],
      holidayFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'holiday',
          label: 'Type',
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'fromDate',
          label: 'From',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'toDate',
          label: 'To',
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'duration',
          label: 'Days',
          thClass: 'lizard-activity-list-duration',
          tdClass: 'lizard-activity-list-duration'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'lizard-activity-list-action',
          tdClass: 'lizard-activity-list-action'
        }
      ],
      expenseFields: [
        {
          key: 'index',
          label: 'No',
          thClass: 'lizard-activity-list-index',
          tdClass: 'lizard-activity-list-index'
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'project',
          label: 'Project',
          sortable: true,
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        },
        {
          key: 'amount',
          label: 'Amount',
          thClass: 'lizard-activity-list-amount',
          tdClass: 'lizard-activity-list-amount'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'lizard-activity-list-action',
          tdClass: 'lizard-activity-list-action'
        }
      ]
    }
  },
  mounted() {
    this.listEntries()
    this.$root.$on('newEntry', this.listEntries)
  },
  methods: {
    listEntries() {
      if (this.inprocess) {
        return
      }
      let { listItems, expenseItems, holidayItems } = this.items
      listItems = listItems.map((itm, idx) => {
        if (['admin', 'training'].includes(itm.type)) {
          itm.project = null
        }
        itm.date = itm.dates[0]
        itm.action = {
          status: 'queued',
          id: idx
        }
        return itm
      })
      expenseItems = expenseItems.map((itm, idx) => {
        itm.date = itm.dates[0]
        itm.action = {
          status: 'queued',
          id: idx
        }
        return itm
      })
      holidayItems = holidayItems.map((itm, idx) => {
        itm.fromDate = itm.dates[0]
        itm.toDate = itm.dates[1]
        itm.action = {
          status: 'queued',
          id: idx
        }
        return itm
      })
      this.listItems = listItems
      this.holidayItems = holidayItems
      this.expenseItems = expenseItems
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    clearEntries() {
      for (let key in this.items) {
        this.items[key] = []
      }
      this.listItems = []
      this.holidayItems = []
      this.expenseItems = []
    },
    modify(id = 0, list = 'list') {
      if (!this.showActions[list].modify) {
        alert('Under Development!')
        return
      }
      list += 'Items'
      this.$root.$emit('entryModifyRequest', list, id)
    },
    remove(id = 0, list = 'list') {
      if (!this.showActions[list].remove) {
        alert('Under Development!')
        return
      }
      list += 'Items'
      if (this[list].length === 1) {
        this[list] = []
        this.items[list] = []
      } else {
        this[list].splice(id, 1)
        this.items[list].splice(id, 1)
      }
    },
    submitEntries() {
      const totalEntries =
        this.listItems.length +
        this.holidayItems.length +
        this.expenseItems.length
      if (!totalEntries) {
        return
      }
      this.inprocess = true
      let queue = new Array()
      queue = queue.concat(this.listItems)
      queue = queue.concat(this.holidayItems)
      queue = queue.concat(this.expenseItems)
      queue = queue.filter((itm) => {
        return itm.action.status === 'queued'
      })
      queue.map((itm) => {
        itm.action.status = 'pending'
        return itm
      })
      setTimeout(() => {
        this.sendEntry(queue)
      }, 1000)
    },
    async sendEntry(queue, idx = 0) {
      try {
        let localVar = queue[idx]
        let saved = null
        if (queue[idx].type === 'holiday') {
          saved = await Services.AddHoliday(queue[idx])
        } else if (queue[idx].type === 'expense') {
          saved = await Services.AddExpense(queue[idx])
        } else if (['admin', 'training'].includes(queue[idx].type)) {
          queue[idx].project = null
          saved = await Services.AddTimelog(queue[idx])
          } else {
          saved = await Services.AddTimelog(queue[idx])
        }
        localVar.action.status = 'queued'
        if (saved.status === 200) {
          localVar.action.status = 'done'
        }
        if (idx < queue.length - 1) {
          idx++
          setTimeout(() => {
            this.sendEntry(queue, idx)
          }, 1000)
        } else {
          this.inprocess = false
          this.resetList()
        }
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    resetList() {
      this.$root.$emit('saved')
      this.clearEntries()
    }
  }
}
</script>
