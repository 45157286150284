<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">
        <h3>
          <span>Reports</span>
        </h3>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <b-card no-body>
          <b-tabs pills card vertical class="tabs-space-ver">
            <b-tab title="Project Reports" active>
              <ProjectReports />
            </b-tab>
            <b-tab title="User Reports">
              <UserReports />
            </b-tab>
            <b-tab title="Expense Reports">
              <ExpenseReports />
            </b-tab>
            <b-tab title="Finance Reports" v-if="showFinanceReportsTab">
              <FinanceReports />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<script>
import UserReports from './UserReports.vue'
import ProjectReports from './ProjectReports.vue'
import ExpenseReports from './ExpenseReports.vue'
import FinanceReports from './FinanceReports.vue'

export default {
  name: 'Reports',
  components: {
    UserReports,
    ProjectReports,
    ExpenseReports,
    FinanceReports
  },
  data() {
    return {
      showFinanceReportsTab: false
    }
  }
}
</script>

<style scoped>
.card-body {
  padding: 0.25rem !important;
}

</style>

<style>
.tabs-space-ver li {
  margin-bottom: 3px !important;
}
.tabs-space-ver li a.nav-link {
  background-color: transparent !important;
}
.tabs-space-ver li a.nav-link:hover {
  background-color: #666 !important;
  color:#EEE !important;
}
</style>
